import readXlsxFile from 'read-excel-file'

export const parseIncomeAndCostFile = async (file?: File) => {
  if (file) {
    const rows = await readXlsxFile(file)
    return rows
  }

  return []
}
