import { useOutsideClick } from '@hooks/useOutsideClick'
import { FC, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import 'twin.macro'
import tw from 'twin.macro'
import { ITableColumn, ITableData } from './Table'

interface ITableRowProps {
  row: ITableData
  columns: ITableColumn[]
  onRowClick?: (id: string | number, row: ITableData) => void
  visibleRows?: (string | number)[]
  topRowKey?: string | number
  depth?: number
  rowaction?: (row: ITableData) => JSX.Element
  composite_id?: string
  haveChildren?: boolean
  stickyColumns?: string[]
  expandedChildren?: (data: any) => JSX.Element
}

export const TableRow: FC<ITableRowProps> = props => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    row,
    onRowClick,
    columns,
    visibleRows = [],
    topRowKey,
    expandedChildren,
    rowaction,
    depth = 0,
    composite_id = '',
    stickyColumns = [],
    haveChildren,
  } = props

  const children = row?.subItems?.length ? row.subItems : row?.children?.length ? row.children : []
  const rowRef = useRef<HTMLTableRowElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const rowPosition = rowRef?.current?.getBoundingClientRect()

  const top = rowPosition?.top || 0
  const left = rowPosition?.left || 0

  useOutsideClick(rowRef, () => setIsExpanded(false))

  return (
    <>
      <tr
        ref={rowRef}
        key={composite_id + row.key}
        className={'group/row'}
        tw='max-h-11 border-b border-b-background transition-all duration-300 hover:bg-gray-100'
        css={[
          onRowClick && tw`cursor-pointer`,
          isExpanded && tw`border-b-transparent bg-[#FAFAFA]`,
          row.isSub && !visibleRows?.includes(topRowKey ?? composite_id + row.key) && tw`collapse max-h-0`,
          row.decoration,
        ]}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          if (onRowClick) {
            onRowClick(composite_id + row.key, row)
          }
          if (expandedChildren) {
            setIsExpanded(!isExpanded)
          }
        }}
      >
        {columns.map((column, index) => (
          <td
            key={column.key + row?.key?.toString() + index}
            tw='py-3 px-2 transition-all duration-500'
            css={[
              index == 0 && depth > 0 && { paddingLeft: depth * 20 + 'px' },
              stickyColumns.includes(column.key as string) &&
                tw`sticky left-0 z-10 bg-gradient-to-r from-white to-transparent via-[rgba(256,256,256,90)]`,
            ]}
          >
            <div
              tw='flex items-center justify-end text-end'
              css={[
                index == 0 && tw`justify-start text-start`,
                children.length && !row.isSub && index == 0 && tw`font-semibold`,
                column.rowDecoration,
              ]}
              onClick={e => {
                if (column.title === '') {
                  e.stopPropagation()
                  e.preventDefault()
                }
              }}
            >
              {(haveChildren || expandedChildren) && index == 0 && (
                <i
                  className='fa fa-chevron-right'
                  tw='invisible mr-2 cursor-pointer text-icons text-xs transition-all duration-300'
                  css={[
                    (visibleRows.includes(composite_id + row.key) || isExpanded) && tw`rotate-90`,
                    (children.length || !!expandedChildren) && tw`visible`,
                  ]}
                />
              )}
              {column.render ? column.render(row) : row[column.dataIndex] ?? '-'}
            </div>
          </td>
        ))}
        {rowaction && (
          <td tw='sticky right-0 text-center opacity-0 transition-all w-[150px] group-hover:opacity-100'>
            {rowaction(row)}
          </td>
        )}
      </tr>
      {expandedChildren &&
        !!rowRef?.current?.offsetWidth &&
        createPortal(
          <div
            ref={contentRef}
            className='border-accent shadow-dropdown-menu absolute z-50 max-w-full overflow-scroll rounded transition-opacity focus:outline-none'
            style={{
              transform: `translateY(${(rowRef?.current?.clientHeight || 0) + 4 ?? 40}px)`,
              top: top - 5,
              left,
              width: `${rowPosition?.width}px`,
            }}
            css={[isExpanded ? tw`z-[10000]! opacity-100` : tw`pointer-events-none opacity-0 z-[-1]`]}
          >
            {isExpanded && !!rowRef?.current?.offsetWidth && expandedChildren(row)}
          </div>,
          document.body,
        )}
      {!!children.length &&
        visibleRows?.includes(topRowKey ?? composite_id + row.key) &&
        children.map(subRow => (
          <TableRow
            key={subRow.key}
            {...props}
            row={{ ...subRow, isSub: true }}
            topRowKey={composite_id + row.key}
            composite_id={composite_id + row.key}
            depth={depth + 1}
          />
        ))}
    </>
  )
}
