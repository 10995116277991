import { FC } from 'react'
import { Label, Legend, Pie, PieChart, Tooltip } from 'recharts'
import 'twin.macro'
import './DoughnutChart.css'

interface IBarChartProps {
  data?: { name: string; value: number; fill: string }[]
  title?: string
  loading?: boolean
  text?: string | number
}

const renderColorfulLegendText = (value: string, entry: any) => {
  return <span className='mb-4 text-sm font-normal leading-tight text-slate-500'>{value}</span>
}

export const DoughnutChart: FC<IBarChartProps> = ({ data, text }) => {
  return (
    <PieChart width={400} height={175} data={data}>
      <Legend
        iconType='circle'
        layout='vertical'
        verticalAlign='top'
        align={'right'}
        iconSize={18}
        formatter={renderColorfulLegendText}
      />
      <Pie
        data={data}
        cy={75}
        cx={100}
        innerRadius={60}
        outerRadius={80}
        paddingAngle={0}
        dataKey='value'
        nameKey='client'
        blendStroke
      >
        <Label value={text} position='centerBottom' fontSize='24px' />
        <Label value='ukupno' position='centerTop' className={'chart-label'} y={140} y1={100} y2={200} cy={200} />
      </Pie>
      <Tooltip
        content={({ payload }) => (
          <div className={'border-primary rounded border bg-white p-2'}>{(payload || []).map(p => p.value)}</div>
        )}
        cursor={{ fill: 'transparent' }}
      />
    </PieChart>
  )
}
