import { Department, DepartmentList, DepartmentSummary, DepartmentYearCompare } from '@interfaces/department'
import { get } from './axiosInstance'

export const departmentsSummary = () => get<DepartmentSummary>('/stats/department/summary')

export const departmentsList = () => get<DepartmentList>('/stats/department/list')

export const departmentsYearCompare = () => get<DepartmentYearCompare>(`/stats/department/year-compare`)

export const departments = () => get<Array<Department>>('/department')
