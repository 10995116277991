import assets from '@assets/assets'
import { Button, Modal } from '@components/ui'
import { useWorkingHoursManagementFunctions } from '@hooks/api/working-hours-management/useWorkingHoursManagementFunctions'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { TextArea } from '../../../components/Inputs/Textarea/Textarea'

interface INotificationModalProps {
  isOpen: boolean
  onClose: () => void
  hoursId: number
  refetch: () => void
}

export const ResendHoursModal = ({ hoursId, isOpen, onClose, refetch }: INotificationModalProps) => {
  const [note, setNote] = useState('')
  const { denyWorkingHours } = useWorkingHoursManagementFunctions()
  const queryClient = useQueryClient()

  const handleResend = useCallback(
    async e => {
      e.stopPropagation()
      e.preventDefault()
      if (hoursId) {
        await denyWorkingHours.mutateAsync(
          { whStatusId: hoursId, data: { note } },
          {
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] }),
          },
        )
        onClose()
        refetch()
      }
    },
    [denyWorkingHours, hoursId, note, onClose, queryClient, refetch],
  )

  return (
    <Modal isOpen={isOpen} close={onClose} closeOnOverlayClick={false}>
      <div
        className={'text-modal-title relative min-w-[673px] max-w-2xl rounded-md bg-white px-7 py-8'}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <div className={'absolute right-7 top-3 cursor-pointer'} onClick={onClose}>
          <img src={assets.XIcon} alt={'close'} />
        </div>
        <div className={'text-modal-title mb-6'}>Pošalji na izmjenu i napiši napomenu</div>
        <TextArea
          placeholder={'Opiši...'}
          containerTwStyle={'!w-full'}
          value={note}
          onChange={e => setNote(e.target.value)}
          rows={4}
        />
        <div className={"font-['IBM Plex Sans'] mb-10 text-base font-normal leading-snug text-zinc-500"}>
          Potvrđivanjem radnje napomena će biti poslana.
        </div>
        <div className={twMerge('flex items-center justify-between', 'justify-between')}>
          <Button buttonType={'secondary'} onClick={onClose}>
            Odustani
          </Button>

          <Button onClick={handleResend} isLoading={denyWorkingHours.isPending}>
            Pošalji
          </Button>
        </div>
      </div>
    </Modal>
  )
}
