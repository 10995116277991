import { isNumber } from './isNumber'

export const formatCurrency = (value?: number, conversionRate = 1) => {
  return value != null && value != undefined && value != 0
    ? Intl.NumberFormat('hr-HR', {
        // currency: 'EUR',
        // style: 'currency',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value * conversionRate)
    : '-'
}

export const parseCurrency = (value?: string | number) => {
  if (!value) return 0
  if (typeof value === 'number') return value

  if (value !== '-') {
    const tempVal = value.replace(/[^\d,]/g, '').replace(',', '.')
    const outputString = value.replace(/[0-9.,]/g, '')
    if (isNumber(tempVal)) return parseFloat(tempVal) * (outputString.length > 0 ? -1 : 1)
    else return NaN
  }

  return 0
}
