import { FC, MouseEvent, PropsWithChildren, useState } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

interface ITagProps {
  title: string
  closeable?: boolean
  onClose?: () => void
  checked?: boolean
  onChange?: (val: string) => void
}

export const Tag: FC<PropsWithChildren<ITagProps>> = ({
  title,
  children,
  closeable,
  onClose,
  checked,
  onChange,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (onClose) {
      onClose()
    }
    setIsOpen(false)
  }

  const handleCheck = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (onChange) {
      onChange(title)
    }
  }

  if (!isOpen) return null

  return (
    <div
      {...props}
      onClick={handleCheck}
      key={title}
      tw='mr-1 flex items-center justify-center whitespace-nowrap rounded border py-1 px-2'
      css={[checked && tw`border-blue-500 bg-blue-500 text-white`, onChange && tw`cursor-pointer`]}
    >
      {children}
      {closeable && <i onClick={handleClose} className='fa fa-close' tw='ml-1 cursor-pointer text-xs opacity-30' />}
    </div>
  )
}
