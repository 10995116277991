import { navigateRoutes } from '@routes/navigate'
import { useMe } from '@stores/me.store'
import { useNavigate } from 'react-router-dom'

export const useNavigation = () => {
  const navigate = useNavigate()
  const { me } = useMe()
  return {
    client: (id: number | string) => (me?.isAdmin ? navigate(navigateRoutes.client(id)) : undefined),
    employee: (id: number | string) => navigate(navigateRoutes.employee(id)),
    workingHours: (id: number | string) => navigate(navigateRoutes.workingHours(id)),
  }
}
