import { getMe, login } from '@api/auth'
import { TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { useAuthFunctions } from '@hooks/api/useAuthFunctions'
import { publicRoutes } from '@routes/routes'
import { useMe } from '@stores/me.store'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'twin.macro'
import { PasswordInput } from '../../Inputs/PasswordInput/PasswordInput'
import { Form } from '../Form/Form'

export const LoginForm: FC = () => {
  const [hasPassword, setHasPassword] = useState<boolean | undefined>(undefined)
  const [hasDifferentPasswordsError, setHasDifferentPasswordsError] = useState(false)
  const [userNotFoundError, setUserNotFoundError] = useState(false)
  const { t } = useTranslation()
  const { checkIfHasPassword } = useAuthFunctions()

  const methods = useForm<{ email: string; password: string; password2?: string }>({
    resolver: (data, context) => {
      if (!data?.email) {
        return {
          values: {},
          errors: { email: { type: 'required', message: 'Obavezno!' } },
        }
      } else if (context.hasPassword === undefined) {
        return {
          values: { email: data.email },
          errors: {},
        }
      } else if (!data?.password) {
        return {
          values: {},
          errors: { password: { type: 'required', message: 'Obavezno!' } },
        }
      } else {
        return { values: data, errors: {} }
      }
    },
    defaultValues: {
      email: '',
      password: '',
      password2: '',
    },
    context: {
      hasPassword,
    },
  })
  const { watch } = methods

  const onSubmit = async values => {
    if (hasPassword === undefined) {
      const resp = await checkIfHasPassword.mutateAsync({ identity: identifier.trim() })
      if (resp?.data !== undefined) {
        setHasPassword(resp.data)
        setUserNotFoundError(false)
      } else if (resp.response.status === 404) {
        setUserNotFoundError(true)
      }
    } else {
      if (hasPassword === false && values.password !== values.password2) {
        setHasDifferentPasswordsError(true)
      } else {
        setHasDifferentPasswordsError(false)
        const data = await login({
          username: (values.email || '').trim().toLowerCase(),
          password: (values.password || '').trim(),
        })
        if (data && data.status < 400) {
          const me = await getMe()
          if (me.status == 200) {
            useMe.getState().setMe(me.data as any)
          }
        }
      }
    }
  }

  const identifier = watch('email')

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <TextInput id='email' label={t('login.email')} />
      {hasPassword !== undefined && <PasswordInput id='password' label={t('login.password')} />}
      {hasPassword === false && <PasswordInput id='password2' label={t('login.password2')} />}
      {hasDifferentPasswordsError && (
        <div className={'text-red-400'}>{t('login.newDoesntMatchConfirmNewPassword')}</div>
      )}
      {userNotFoundError && <div className={'text-red-400'}>{t('login.user_not_found')}</div>}
      <Button tw='mt-2 w-full uppercase' type={'submit'}>
        {hasPassword === undefined ? t('login.next') : t('login.login')}
      </Button>
      {hasPassword && (
        <section tw='mt-5 flex justify-center'>
          <Link tw='text-primary' to={publicRoutes.FORGOT_PASSWORD}>
            {t('login.forgot_password')}
          </Link>
        </section>
      )}
    </Form>
  )
}
