import { axiosApiInstance } from '@api/axiosInstance'
import { EmployeeDepartment } from '@interfaces/employee'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export const useWorkingHoursEmployeeDepartments = () => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<EmployeeDepartment[]>, null, EmployeeDepartment[]>({
    queryKey: ['wh-employee-departments'],
    queryFn: workingHours.workingHoursControllerGetEmployeeDepartments,
    select: data => data.data,
  })
}
