import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const useGetNumberOfEmployeesInDepartment = ({ departmentId, year }: { departmentId: number; year: number }) => {
  const { stats } = axiosApiInstance
  return useQuery({
    queryKey: ['department', departmentId, 'employee-number', year],
    queryFn: () => stats.employeeControllerGetNumberOfEmployeesInDepartment({ id: departmentId, year }),
    select: data => data.data,
    enabled: !!departmentId && !!year,
  })
}
