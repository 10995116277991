import assets from '@assets/assets'
import { useWindowSize } from '@hooks/useWindowSize'
import { privateRoutes } from '@routes/routes'
import { useMe } from '@stores/me.store'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'
import { NavigationRow } from './NavigationRow'

export const Navigation: FC = () => {
  const [activePage, setActivePage] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState(false)
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const location = useLocation()
  const { me, logout } = useMe()

  useEffect(() => {
    const currentPage = location.pathname.split('/')[1]
    if (activePage !== currentPage) {
      setActivePage(currentPage)
    }
  }, [activePage, location.pathname])

  useEffect(() => {
    if (width && width > 640 && isExpanded) {
      setIsExpanded(false)
    }
  }, [isExpanded, width])

  const toggleExpanded = () => {
    setIsExpanded(isExp => !isExp)
  }

  const closeExpanded = () => {
    setIsExpanded(false)
  }

  const navigationItems = useMemo(
    () =>
      [
        {
          title: t('navigation.company_list'),
          to: privateRoutes.COMPANY_LIST_PATH,
          name: 'company-list',
          icon: assets.NavigationIcons.CompanyIcon,
          activeIcon: assets.NavigationActiveIcons.CompanyActiveIcon,
          isVisible: me?.isSuperAdmin,
        },
        {
          title: t('navigation.dashboard'),
          to: privateRoutes.DASHBOARD_PATH,
          name: 'dashboard',
          icon: assets.NavigationIcons.DashboardIcon,
          activeIcon: assets.NavigationActiveIcons.DashboardActiveIcon,
          isVisible: me?.isAdmin && me?.companySettings?.hasPL,
        },
        {
          title: t('navigation.clients'),
          to: privateRoutes.CLIENTS_PATH,
          name: 'clients',
          icon: assets.NavigationIcons.ClientsIcon,
          activeIcon: assets.NavigationActiveIcons.ClientsActiveIcon,
          isVisible: me?.isAdmin || me?.isManager,
        },
        {
          title: t('navigation.departments'),
          to: privateRoutes.DEPARTMENTS_PATH,
          name: 'departments',
          icon: assets.NavigationIcons.DepartmentsIcon,
          activeIcon: assets.NavigationActiveIcons.DepartmentsActiveIcon,
          isVisible: me?.isAdmin,
        },
        {
          title: t('navigation.employees'),
          to: privateRoutes.EMPLOYEES_PATH,
          name: 'employees',
          icon: assets.NavigationIcons.EmployeesIcon,
          activeIcon: assets.NavigationActiveIcons.EmployeesActiveIcon,
          isVisible: me?.isAdmin || me?.isManager,
        },
        {
          title: t('navigation.working_hours'),
          to: privateRoutes.WORKING_HOURS,
          name: 'working-hours',
          icon: assets.NavigationIcons.WorkingHoursIcon,
          activeIcon: assets.NavigationActiveIcons.WorkingHoursActiveIcon,
          isVisible: !me?.isAdmin && !me?.isSuperAdmin,
        },
        {
          title: t('navigation.working_hours_management'),
          to: privateRoutes.WORKING_HOURS_MANAGEMENT,
          name: 'working-hours-management',
          icon: assets.NavigationIcons.WorkingHoursManagementIcon,
          activeIcon: assets.NavigationActiveIcons.WorkingHoursManagementActiveIcon,
          isVisible: me?.isManager,
        },
        {
          title: t('navigation.company'),
          to: privateRoutes.COMPANY_PATH,
          name: 'company',
          icon: assets.NavigationIcons.CompanyIcon,
          activeIcon: assets.NavigationActiveIcons.CompanyActiveIcon,
          isVisible: me?.isAdmin && me?.companySettings?.hasPL,
        },
        {
          title: t('navigation.income_and_costs'),
          to: privateRoutes.INCOME_AND_COSTS_PATH,
          name: 'income-costs',
          isVisible: me?.isAdmin && me?.companySettings?.hasPL,
          icon: assets.NavigationIcons.WorkingHoursManagementIcon,
          activeIcon: assets.NavigationActiveIcons.WorkingHoursManagementActiveIcon,
        },
        {
          title: t('navigation.p_and_l'),
          to: privateRoutes.P_AND_L_PATH,
          name: 'p-and-l',
          icon: assets.NavigationIcons.PLIcon,
          isVisible: me?.isAdmin && me?.companySettings?.hasPL,
          activeIcon: assets.NavigationActiveIcons.PLActiveIcon,
        },
        {
          title: t('navigation.faq'),
          to: privateRoutes.FAQ_PATH,
          name: 'faq',
          icon: assets.NavigationIcons.FaqIcon,
          activeIcon: assets.NavigationActiveIcons.FaqActiveIcon,
          isVisible: me?.isAdmin && me?.companySettings?.hasPL,
        },
        {
          title: t('navigation.settings'),
          to: privateRoutes.ACCOUNT_PATH,
          name: 'account',
          icon: assets.NavigationIcons.SettingsIcon,
          activeIcon: assets.NavigationActiveIcons.SettingsActiveIcon,
          isVisible: true,
        },
      ]
        .filter(navItem => navItem.isVisible)
        .map(navItem => (
          <NavigationRow
            key={navItem.name}
            name={navItem.name}
            icon={navItem.icon}
            activeIcon={navItem.activeIcon}
            title={navItem.title}
            isExpanded={isExpanded}
            activePage={activePage}
            closeExpanded={closeExpanded}
            to={navItem.to}
          />
        )),
    [activePage, isExpanded, me?.companySettings?.hasPL, me?.isAdmin, me?.isManager, me?.isSuperAdmin, t],
  )

  return (
    <div>
      <input id='nav' type='checkbox' tw='absolute -top-96 -left-96' checked={isExpanded} onChange={toggleExpanded} />
      <label tw='fixed left-7 top-5 inline-block cursor-pointer' htmlFor='nav' css={[isExpanded && tw`bg-red-400`]}>
        <i tw='text-icons' className='fa fa-bars' />
      </label>
      <nav
        className='group/nav peer'
        tw='invisible fixed z-20 flex h-screen w-20 flex-col justify-between bg-white p-5 transition-all hover:w-72 sm:visible'
        css={[isExpanded && tw`visible w-72`]}
      >
        <i tw='mb-5.5 pl-3 text-icons' className='fa fa-bars group-hover/nav:hidden' css={[isExpanded && tw`hidden`]} />
        <title
          tw='relative hidden w-full overflow-hidden text-clip whitespace-nowrap text-2.5xl'
          className='group-checked/nav:inline group-hover/nav:inline'
          css={[isExpanded && tw`inline`]}
        >
          <span tw='text-primary'>{'Business '}</span>Monitor
          <label htmlFor='nav' tw='absolute right-0 top-2 cursor-pointer text-xl text-icons sm:invisible'>
            <i className='fa fa-times' />
          </label>
        </title>

        <section tw='flex flex-col overflow-hidden text-clip whitespace-nowrap text-icons'>{navigationItems}</section>

        <section tw='flex h-12 items-center justify-between'>
          <div className={'flex items-center gap-4'}>
            <div className='flex h-10 w-10 items-center justify-center rounded-full bg-neutral-200'>
              <div className='text-center text-base font-normal leading-[15.04px] text-neutral-500'>
                {me?.name.split(' ').reduce((previousValue, currentValue) => previousValue + currentValue[0], '')}
              </div>
            </div>
            <div
              className='group-hover/nav:flex group-hover/nav:flex-col'
              tw='hidden overflow-hidden text-clip whitespace-nowrap font-semibold text-icons'
            >
              <div className='text-sm font-normal text-gray-500'>{me?.name}</div>
              <div tw='w-fit' className='text-sm font-normal text-gray-400'>
                {me?.isSuperAdmin
                  ? 'Platform Admin'
                  : me?.isAdmin
                  ? 'Admin'
                  : me?.isManager
                  ? 'Voditelj'
                  : 'Zaposlenik'}
              </div>
            </div>
          </div>

          <div onClick={logout}>
            <img
              src={assets.PowerOff}
              alt='logout'
              className='group-hover/nav:flex group-hover/nav:flex-col'
              tw='hidden cursor-pointer overflow-hidden text-clip whitespace-nowrap text-3xl hover:text-primary-light'
            />
          </div>
        </section>
      </nav>
      <label
        htmlFor={'nav'}
        tw='invisible fixed z-10 h-screen w-screen bg-secondary opacity-20 peer-hover:visible'
        css={[isExpanded && tw`visible`]}
      >
        <i />
      </label>
    </div>
  )
}
