import { useCurrency } from '@stores/currency.store'
import { formatCurrency } from '@utils/formatCurrency'
// import { useEffect, useState } from 'react'

export const useFormatCurrency = () => {
  // const [convRate, setConvRate] = useState(1)
  const { conversionRate } = useCurrency()

  // useEffect(() => {
  //   if (convRate < conversionRate) {
  //     setTimeout(() => {
  //       setConvRate(c => (c + 0.6 > conversionRate ? conversionRate : c + 0.6))
  //     }, 10)
  //   } else if (convRate > conversionRate) {
  //     setTimeout(() => {
  //       setConvRate(c => (c - 0.6 < conversionRate ? conversionRate : c - 0.6))
  //     }, 10)
  //   }
  // }, [conversionRate, convRate])

  return (val?: number) => formatCurrency(val, conversionRate)
}
