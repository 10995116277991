import { FC, PropsWithChildren, ReactNode } from 'react'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'
import { Skeleton } from '../Skeleton/Skeleton'

interface ICardProps {
  title?: ReactNode
  subtitle?: ReactNode
  loading?: boolean
  titleStyle?: TwStyle
}

export const Card: FC<PropsWithChildren<ICardProps>> = ({
  title,
  subtitle,
  children,
  loading,
  titleStyle,
  ...props
}) => {
  return (
    <Skeleton showSkeleton={loading}>
      <div {...props} tw='flex flex-col overflow-hidden rounded border border-gray-200 bg-white text-sm'>
        {title && (
          <div
            tw='mx-2 flex justify-between py-3'
            css={[children && tw`border-secondary border-b border-opacity-10`, !children && tw`items-center`]}
          >
            <span tw='text-secondary' css={[children && tw`font-semibold`, titleStyle]}>
              {title}
            </span>
            <span css={[!children && tw`text-lg text-icons-dark`]}>{subtitle}</span>
          </div>
        )}
        {children && <div tw='flex flex-col items-center justify-center py-4 text-icons-dark text-2xl'>{children}</div>}
      </div>
    </Skeleton>
  )
}
