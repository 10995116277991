import assets from '@assets/assets'
import { useWorkingHoursManagementFunctions } from '@hooks/api/working-hours-management/useWorkingHoursManagementFunctions'
import { memo, useCallback, useState } from 'react'
import { NotificationModal } from '../../../WorkingHours/Components/NotificationModal'
import { ResendHoursModal } from '../ResendHoursModal'

export const ActiveActionsRenderer = memo(({ data }: { data: any }) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)
  const { approveWorkingHours } = useWorkingHoursManagementFunctions()
  const handleApprove = useCallback(
    async e => {
      e.stopPropagation()
      e.preventDefault()
      if (data?.id) {
        await approveWorkingHours.mutateAsync(data?.id)
        setIsApproveModalOpen(false)
      }
    },
    [approveWorkingHours, data],
  )

  return (
    <>
      <div className={'z-50 flex gap-2 opacity-0 transition-all group-hover/row:opacity-100'}>
        <div
          className={'group/approve-icon relative cursor-pointer transition-colors'}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setIsApproveModalOpen(true)
          }}
        >
          <img alt={'approve'} src={assets.ApproveIcon} />
          <div className='absolute inset-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-0 transition-opacity group-hover/approve-icon:opacity-20'></div>
        </div>
        <div
          className={'group/resend-icon relative cursor-pointer transition-colors'}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setIsResendModalOpen(true)
          }}
        >
          <img alt={'reject'} src={assets.ResendIcon} />
          <div className='bg-primary absolute inset-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition-opacity group-hover/resend-icon:opacity-20'></div>
        </div>
      </div>
      <NotificationModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        title={'Odobri unesene radne sate'}
        subtitle={`Potvrđivanjem ove radnje radni sati za osobu ${data?.name} bit će odobreni. Pregledati ih možete u arhivi.`}
        secondaryButtonText={'Odustani'}
        primaryButtonText={'Odobri'}
        isLoading={approveWorkingHours.isPending}
        onSecondaryPress={() => setIsApproveModalOpen(false)}
        onPrimaryClick={handleApprove}
      />
      <ResendHoursModal isOpen={isResendModalOpen} hoursId={data['id']} onClose={() => setIsResendModalOpen(false)} />
    </>
  )
})
