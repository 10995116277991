export type ComponentSize = 'large' | 'medium' | 'small' | 'mini'
export type ComponentDirection = 'horizontal' | 'vertical'

export type WorkingHoursStatus = 'APPROVED' | 'REJECTED'
export type WorkingHoursType = 'NORMAL' | 'WFH' | 'OVERTIME'
export type NonWorkingHoursType = 'MATERNITY' | 'SICK' | 'REST' | 'VACATION'

export enum WorkingHoursTypeEnum {
  NORMAL = 'NORMAL',
  WFH = 'WFH',
  OVERTIME = 'OVERTIME',
  REST = 'REST',
}

export enum NonWorkingHoursTypeEnum {
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  VACATION = 'VACATION',
}
export type IWorkingHoursActiveTableData = {
  id: number | string
  date: string
  hours: number
  percentage: number
  date_approved: string
  date_sent: string
  employee_id: number
  status: WorkingHoursStatus
  note?: string
}

export type WorkingHours = {
  id: number
  hours: number | string
  name: string
  type: WorkingHoursType
  working_hours_id?: number
}

export type NonWorkingHours = {
  id: number
  hours: number | string
  name: string
  type: NonWorkingHoursType
  working_hours_id?: number
}

export type IWorkingHoursArchiveTableData = {
  date: string
  date_approved: string
  date_sent: string
  employee_id: number
  id: number
  note?: string
  status: WorkingHoursStatus
}

export type EmployeeClients = {
  createdAt: string
  deletedAt?: string
  id: number
  name: string
  snowflake: string
  updatedAt: string
}
