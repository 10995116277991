import { PageContent } from '@components/ui'
import { PLHeader } from './Components/PLHeader'
import { PLMonthView } from './Components/PLMonthView'

export const PL = () => {
  return (
    <>
      <PLHeader />
      <PageContent>
        <PLMonthView />
      </PageContent>
    </>
  )
}
