import { isNumber } from '@utils/isNumber'
import { Chart as ChartJS } from 'chart.js'
import { FC, useEffect, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import 'twin.macro'
import { theme } from 'twin.macro'

type DataSet = {
  data?: number[]
  title: string
}

interface IBarChartProps {
  labels?: (string | number)[]
  datasets: DataSet[]
  title?: string
  loading?: boolean
  singleColor?: boolean
}

export const CompareBarChart: FC<IBarChartProps> = ({
  labels,
  datasets,
  title,
  loading = false,
  singleColor = false,
}) => {
  const chartRef = useRef<ChartJS>(null)
  const [colors, setColors] = useState<any>(singleColor ? undefined : [])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)

    gradient.addColorStop(0.33, theme`colors.primary` + '80')
    gradient.addColorStop(1, theme`colors.primary`)

    gradient

    const gradient2 = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)

    gradient2.addColorStop(0.33, theme`colors.background` + '80')
    gradient2.addColorStop(1, theme`colors.background`)

    gradient2

    setColors([gradient, gradient2])
  }, [])

  return (
    <Chart
      ref={chartRef}
      type='bar'
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              usePointStyle: true,
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback(tickValue, index, ticks) {
                if (!isNumber(tickValue)) {
                  return tickValue
                }
                const ticksValue = parseInt(tickValue.toString())
                if (Math.abs(ticksValue) >= 1000 && Math.abs(ticksValue) < 1000000) {
                  return Math.round(ticksValue / 1000) + 'k'
                }
                if (Math.abs(ticksValue) >= 1000000) {
                  return Math.round(ticksValue / 1000000) + 'm'
                }
                return ticksValue
              },
            },
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            tension: 0.2,
            fill: 'origin',
            label: datasets[0].title ?? '',
            data: datasets[0].data ?? [],
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: colors[0],
          },
          {
            tension: 0.2,
            fill: 'origin',
            label: datasets[1].title ?? '',
            data: datasets[1].data ?? [],
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: colors[1],
          },
        ],
      }}
    />
  )
}
