import { Segmented } from '@components/inputs'
import { LegendDot, PercentageButton, Table } from '@components/ui'
import { useDepartmentsList } from '@hooks/api/department'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useMe } from '@stores/me.store'
import { ColorContext } from '@utils/colorContext'
import { formatPercentage } from '@utils/formatPercentage'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import 'twin.macro'
import tw from 'twin.macro'
import { DepartmentTableItem } from './DepartmentsTableItem'

export const DepartmentsTable = () => {
  const [filter, setFilter] = useState('salary')
  const [showPercentage, setShowPercentage] = useState(false)
  const { array, push, removeItem } = useArray<string | number>([])
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { getColor } = useContext(ColorContext)
  const { me } = useMe()
  const { data } = useDepartmentsList()

  let tableData = (data?.data ?? []).map(l => ({
    ...l,
    key: l.id,
    children: (l?.children ?? []).map(c => ({ ...c, key: c.id })),
  }))

  const [totalIncome, totalExpense, totalSalary, totalProfit, totalMargin] = tableData.reduce(
    (acc, k) => {
      return [acc[0] + k.income, acc[1] + k.expense, acc[2] + k.salary, acc[3] + k.profit, acc[4] + k.margin]
    },
    [0, 0, 0, 0, 0],
  )

  tableData = tableData.map(k => ({
    ...k,
    income_share: (k.income * 100) / totalIncome,
    expense_share: (k.expense * 100) / totalExpense,
    salary_share: (k.salary * 100) / totalSalary,
    profit_share: (k.profit * 100) / totalProfit,
    margin_share: (k.margin * 100) / totalMargin,
  }))

  const segmentOptions = [
    {
      label: t('shared.income'),
      value: 'income',
    },
    {
      label: t('shared.costs'),
      value: 'expense',
    },
    {
      label: t('shared.salary'),
      value: 'salary',
    },
    {
      label: t('shared.profit'),
      value: 'profit',
    },
    {
      label: t('shared.margin'),
      value: 'margin',
    },
  ]
  return (
    <>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'>{t('clients.profit_overview_per_department')}</span>
        <div tw='flex items-center gap-2'>
          <PercentageButton showPercentage={showPercentage} setShowPercentage={() => setShowPercentage(p => !p)} />
          {me?.companySettings?.hasPL && (
            <Segmented options={segmentOptions} value={filter} onChange={val => setFilter(val)} />
          )}
        </div>
      </div>
      <div tw='mb-7 flex h-9 w-full items-center overflow-hidden rounded shadow'>
        <Tooltip id={'department-tooltip'} />
        {tableData
          .sort((a: any, b: any) => b[`${filter}_share`] - a[`${filter}_share`])
          .map(({ name, [`${filter}_share`]: share }: any) => (
            <div
              key={name}
              data-tooltip-id={'department-tooltip'}
              data-tooltip-content={`${name} - ${formatPercentage(share ?? '0')}`}
              tw='flex h-full items-center p-3 text-icons-light text-sm transition-all duration-500'
              style={{ flex: share, background: getColor(name) }}
            >
              {formatPercentage(share ?? '0')}
            </div>
          ))}
      </div>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('shared.department'),
              render: data => <LegendDot title={data.name} showDot={!data.isSub} support={data.support} />,
            },
            {
              key: 'income',
              dataIndex: 'income',
              title: t('shared.income'),
              decoration: tw`w-[200px]`,
              hidden: !me?.companySettings?.hasPL,
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='income'
                  formatValue={formatCurrency}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'expense',
              dataIndex: 'expense',
              decoration: tw`w-[200px]`,
              title: t('shared.costs'),
              hidden: !me?.companySettings?.hasPL,
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='expense'
                  formatValue={formatCurrency}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'salary',
              dataIndex: 'salary',
              decoration: tw`w-[200px]`,
              title: t('shared.salary'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='salary'
                  formatValue={formatCurrency}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'profit',
              dataIndex: 'profit',
              decoration: tw`w-[200px]`,
              hidden: !me?.companySettings?.hasPL,
              title: t('shared.profit'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='profit'
                  formatValue={formatCurrency}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'margin',
              dataIndex: 'margin',
              decoration: tw`w-[200px]`,
              hidden: !me?.companySettings?.hasPL,
              title: t('shared.margin'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='margin'
                  formatValue={formatPercentage}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'employees',
              dataIndex: 'employees',
              title: t('shared.employed'),
              decoration: tw`w-[200px]`,
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='employees'
                  formatValue={v => v?.toString() ?? '-'}
                  showPercentage={showPercentage}
                />
              ),
            },
            {
              key: 'avg_salary',
              dataIndex: 'avg_salary',
              decoration: tw`w-[200px]`,
              title: t('shared.avg_salary'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey='avg_salary'
                  formatValue={formatCurrency}
                  showPercentage={showPercentage}
                />
              ),
            },
          ]}
          data={[
            ...tableData.filter(v => !v.support).sort((a: any, b: any) => b['income'] - a['income']),
            ...tableData
              .filter(v => v.support)
              .map(v => ({ ...v, decoration: tw`bg-table-accent-grey` }))
              .sort((a: any, b: any) => b['salary'] - a['salary']),
          ]}
        />
      </div>
    </>
  )
}
