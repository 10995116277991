import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface IImportButton {
  parseFile: (val: ChangeEvent<HTMLInputElement>) => void
  accept?: string
}

export const ImportButton: FC<IImportButton> = ({ parseFile, accept }) => {
  const { t } = useTranslation()

  return (
    <label tw='my-3 w-fit cursor-pointer whitespace-nowrap bg-primary px-4 py-2 text-white rounded-[4px] hover:bg-primary-light'>
      <input tw='hidden' type='file' onChange={parseFile} accept={accept} />
      <i tw='mr-3' className='fa fa-upload' />
      {t('shared.import_data')}
    </label>
  )
}
