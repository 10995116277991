import { ComponentWrapper } from '@components/ui'
import { DepartmentsCompare } from './DepartmentsCompare'
import { DepartmentsTable } from './DepartmentsTable'

export const DepartmentsOverview = () => {
  return (
    <ComponentWrapper>
      <DepartmentsTable />
      <DepartmentsCompare />
    </ComponentWrapper>
  )
}
