import { createRef, FC, ReactNode, useEffect, useMemo, useState } from 'react'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'

type TabItem = {
  key: string
  title: string
  content: ReactNode
  disabled?: boolean
}

interface ITabsProps {
  tabs: TabItem[]
  defaultActiveKey?: string
  activeKey?: string
  $tabTwStyle?: TwStyle
  tabHeaderContainerTwStyle?: TwStyle
}

export const Tabs: FC<ITabsProps> = ({ tabs, defaultActiveKey, $tabTwStyle, tabHeaderContainerTwStyle, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultActiveKey ? tabs.findIndex(val => val.key == defaultActiveKey) : 0,
  )
  const [elRefs, setElRefs] = useState<any>(
    Array(tabs.length)
      .fill(null)
      .map((_, i) => createRef()),
  )

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs: any) =>
      Array(tabs.length)
        .fill(null)
        .map((_, i) => elRefs[i] || createRef()),
    )
  }, [tabs])

  const selected = elRefs[selectedTab]?.current

  const tabHeaders = useMemo(
    () =>
      tabs.map((tab, index) => (
        <div
          tw='cursor-pointer py-1 px-1 text-lg text-icons'
          css={[
            index == selectedTab && tw`text-primary`,
            index != 0 && tw`ml-6`,
            $tabTwStyle,
            tab.disabled && tw`cursor-not-allowed text-gray-400`,
          ]}
          onClick={!tab.disabled ? () => setSelectedTab(index) : undefined}
          ref={elRefs[index]}
          key={tab.key}
        >
          {tab.title}
        </div>
      )),
    [$tabTwStyle, elRefs, selectedTab, tabs],
  )

  const tabContent = useMemo(
    () =>
      tabs.map((tab, index) => (
        <div css={[index != selectedTab && tw`hidden`]} tabIndex={index} key={tab.key}>
          {tab.content}
        </div>
      )),
    [selectedTab, tabs],
  )

  return (
    <div {...props}>
      <div tw='flex' css={[tabHeaderContainerTwStyle]}>
        {tabHeaders}
      </div>
      <div tw='relative mb-10 h-0.5 w-full bg-gray-300'>
        <div
          tw='absolute h-0.5 w-12 bg-primary transition-all'
          css={[
            selected != null && {
              width: selected.clientWidth + 'px',
              left: selected.offsetLeft - selected.parentElement.offsetLeft,
            },
          ]}
        />
      </div>
      <div>{tabContent}</div>
    </div>
  )
}
