import { Table } from '@components/ui'
import { useEmployeesMaternitySummary, useEmployeesSickSummary, useEmployeesVacationSummary } from '@hooks/api/employee'
import { useOrder } from '@hooks/useOrder'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

export const EmployeeNonWorkingHoursTable = ({ tab }: { tab: 'vacation' | 'sick' | 'maternity' }) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { data: vacationSummaryData } = useEmployeesVacationSummary()
  const { data: sickSummaryData } = useEmployeesSickSummary()
  const { data: maternitySummaryData } = useEmployeesMaternitySummary()
  const [order, setOrder, orderData] = useOrder({ key: 'name', direction: 'ASC' })
  const { t } = useTranslation()

  const totalData = useMemo(
    () => ({
      vacation: {
        data: (vacationSummaryData?.data || []).map(vs => ({
          ...vs,
          hours: Number(vs.hours),
          salary: Number(vs.salary),
        })),
        title: 'Godišnji odmor',
      },
      sick: {
        data: (sickSummaryData?.data || []).map(vs => ({
          ...vs,
          hours: Number(vs.hours),
          salary: Number(vs.salary),
        })),
        title: 'Bolovanje',
      },
      maternity: {
        data: (maternitySummaryData?.data || []).map(vs => ({
          ...vs,
          hours: Number(vs.hours),
          salary: Number(vs.salary),
        })),
        title: 'Porodiljni',
      },
    }),
    [maternitySummaryData?.data, sickSummaryData?.data, vacationSummaryData?.data],
  )
  const currentTab = totalData[tab]
  const data = currentTab.data.filter(d => (d.name || '').toLowerCase().includes(search.toLowerCase()))
  return (
    <>
      <div tw='mb-10 overflow-scroll'>
        <Table
          title={currentTab.title}
          search={(search?: string) => setSearch(search ?? '')}
          order={order}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            perPage: 10,
            total: (data || []).length,
          }}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('employees.employee'),
              order: setOrder,
              render: data => <>{data['name']}</>,
            },
            {
              key: 'days',
              dataIndex: 'days',
              title: t('shared.worked_days'),
              decoration: tw`w-[150px]`,
              order: setOrder,
              render: data => <>{data['hours'] ? Math.round(Math.round(data['hours']) / 8) : '-'}</>,
            },
            {
              key: 'hours',
              dataIndex: 'hours',
              title: t('shared.worked_hours'),
              decoration: tw`w-[150px]`,
              order: setOrder,
              render: data => <>{data['hours'] ? Math.round(data['hours']) : '-'}</>,
            },
            {
              key: 'salary',
              dataIndex: 'salary',
              decoration: tw`w-[150px]`,
              title: 'Trošak plaće',
              order: setOrder,
              render: data => <>{data['salary'] ?? '-'}</>,
            },
          ]}
          data={(data || []).sort(orderData)}
        />
      </div>
    </>
  )
}
