import { axiosApiInstance } from '@api/axiosInstance'
import { useMutation } from '@tanstack/react-query'

export const useAuthFunctions = () => {
  const { user } = axiosApiInstance
  const checkIfHasPassword = useMutation({ mutationFn: user.userControllerHasPassword })
  return {
    checkIfHasPassword,
  }
}
