import { FC, PropsWithChildren } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import 'twin.macro'

interface IFormProps {
  methods: UseFormReturn<any>
  onSubmit: (...props: any) => void
}

export const Form: FC<PropsWithChildren<IFormProps>> = ({ methods, onSubmit, children, ...formProps }) => {
  return (
    <FormProvider {...methods}>
      <form {...formProps} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  )
}
