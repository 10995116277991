import { CreateEmployeeDTO, CreateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { DatePicker, TagSelect, TextInput } from '@components/inputs'
import { Button, Dropdown } from '@components/ui'
import { useDepartmentsList } from '@hooks/api/department'
import { useMe } from '@stores/me.store'
import { FC, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { DropdownItem } from '../../../components/UI/Dropdown/Dropdown'

interface ICreateEmployeeFormProps {
  onCreate: (employee: CreateEmployeeDTO) => void
  methods: UseFormReturn<CreateEmployeeDTO, any>
  isLoading?: boolean
  handleClose?: () => void
}

export const CreateEmployeeForm: FC<ICreateEmployeeFormProps> = ({ onCreate, methods, isLoading, handleClose }) => {
  const { me } = useMe()
  const { t } = useTranslation()
  const { data, isPending } = useDepartmentsList()

  const workTimeItems = useMemo(
    () =>
      [
        {
          key: CreateEmployeeDtoHoursTypeEnum.FULLTIME,
          title: 'Puno radno vrijeme',
          onClick: e => methods.setValue('hoursType', e.key),
        },
        {
          key: CreateEmployeeDtoHoursTypeEnum.HALFTIME,
          title: 'Pola radnog vremena',
          onClick: e => methods.setValue('hoursType', e.key),
        },
        {
          key: CreateEmployeeDtoHoursTypeEnum.STUDENT,
          title: 'Student',
          onClick: e => methods.setValue('hoursType', e.key),
        },
      ] as DropdownItem[],
    [methods],
  )

  const [workTime] = methods.watch(['hoursType'])

  return (
    <Form methods={methods} onSubmit={methods.handleSubmit(onCreate)} tw={'flex flex-1 flex-col justify-between'}>
      <section className={'flex flex-1 flex-col'}>
        <div className='text-2xl font-normal leading-[28.80px] text-neutral-500'>Osnovni podaci</div>
        <section tw='mb-7 grid grid-cols-2 gap-x-6 border-b pb-4'>
          <TextInput id='name' label={t('employees.name')} placeholder={t('shared.placeholders.enter')} />
          <TextInput id='email' label={t('employees.email')} placeholder={t('shared.placeholders.email')} />
        </section>
        <div className='text-2xl font-normal leading-[28.80px] text-neutral-500'>Podaci o zaposlenju</div>
        <section tw='mb-8 grid grid-cols-3 gap-x-6'>
          <DatePicker id='arrival' label={t('shared.dateOfArrival') as string} />
          <div>
            <div className='text-secondary inline-block py-3 text-sm font-semibold'>Radno vrijeme</div>
            <Dropdown trigger={'click'} items={workTimeItems}>
              {workTimeItems.find(wti => wti.key === workTime)?.title || '-'}
            </Dropdown>
          </div>
        </section>
        <TagSelect
          id='departments'
          name='departments'
          sublabel={'Osobu možete staviti samo u odjel u kojem ste definirati kao voditelj. '}
          label={t('employees.select_departments')}
          options={
            isPending
              ? []
              : (data?.data || []).map(dep => ({
                  title: dep.name,
                  value: dep.id,
                  employees: dep.employees,
                })) ?? []
          }
        />
        <div className='mt-8 mb-4 border-t pt-6 text-2xl font-normal leading-[28.80px] text-neutral-500'>
          Plaća i troškovi
        </div>
        <section tw='mb-4 grid grid-cols-3 gap-x-6'>
          <TextInput
            type={'number'}
            id='salary.bruto2'
            label={t('employees.bruto2')}
            placeholder={t('shared.placeholders.enter_amount')}
          />
          <TextInput
            type={'number'}
            id='salary.neto'
            label={t('employees.neto')}
            placeholder={t('shared.placeholders.enter_amount')}
          />
          <TextInput
            type={'number'}
            id='salary.kind'
            label={t('employees.pay_in_kind')}
            placeholder={t('shared.placeholders.optional')}
          />
          <TextInput
            type={'number'}
            id='salary.transport'
            label={t('employees.transport')}
            placeholder={t('shared.placeholders.optional')}
          />
          <TextInput
            type={'number'}
            id='salary.fuel'
            label={t('employees.fuel_and_toll')}
            placeholder={t('shared.placeholders.optional')}
          />
          <TextInput
            type={'number'}
            id='salary.parking'
            label={t('employees.parking')}
            placeholder={t('shared.placeholders.optional')}
          />
          <TextInput
            type={'number'}
            id='salary.other'
            label={t('employees.other_expenses')}
            placeholder={t('shared.placeholders.optional')}
          />
        </section>
      </section>

      <section className='mt-4 flex justify-end gap-4'>
        <Button tw='font-medium uppercase' type='button' buttonType={'secondary'} onClick={handleClose}>
          {t('shared.give_up')}
        </Button>
        <Button tw='font-medium text-white uppercase' type='submit'>
          {t('shared.save')}
        </Button>
      </section>
    </Form>
  )
}
