import { LineChart, Skeleton } from '@components/ui'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { formatPercentage } from '@utils/formatPercentage'
import { FC } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

type DashboardCardDescription = {
  title: string
  change: number
  total: number | string
}

interface IDashboardLineCardProps {
  title: string
  change?: number | null
  total?: number
  data?: number[]
  labels?: number[]
  loading?: boolean
  descriptions?: DashboardCardDescription[]
}

export const DashboardLineCard: FC<IDashboardLineCardProps> = ({
  total,
  change,
  data,
  labels,
  descriptions,
  loading,
  title,
}) => {
  const formatCurrency = useFormatCurrency()

  return (
    <Skeleton showSkeleton={loading}>
      <div tw='rounded-t-lg bg-white px-7 py-5 text-icons-dark text-2xl shadow-md'>
        <div tw='mb-8 flex justify-between'>
          <span>{title}</span>
          <span tw='font-medium'>
            <span tw='mr-2 font-normal text-success text-sm'>{formatPercentage(change)}</span>
            {formatCurrency(total)}
          </span>
        </div>
        <LineChart title={title} data={data} labels={labels} />
        {descriptions && (
          <div tw='mt-7 border-accent border-y text-sm'>
            {descriptions?.map(({ title, change, total }, index) => (
              <div
                key={index}
                tw='flex justify-between py-2'
                css={[index != descriptions.length - 1] && tw`border-accent border-b`}
              >
                <span>{title}</span>
                <span tw='font-medium'>
                  <span tw='mr-2 font-normal text-success text-sm' css={[change < 0 && tw`text-error`]}>
                    {Math.round(change ?? 0)}%
                  </span>
                  {total}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </Skeleton>
  )
}
