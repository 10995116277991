import { FieldSelect, Select } from '@components/inputs'
import { Button } from '@components/ui'
import { GlobalFilter } from '@stores/globalFilter.store'
import dayjs from 'dayjs'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { Form } from '../Form/Form'

interface IGlobalFilterFormProps {
  onUpdate: (filter: Partial<GlobalFilter>) => void
  initialValue: GlobalFilter
}

export const GlobalFilterForm: FC<IGlobalFilterFormProps> = ({ onUpdate, initialValue }) => {
  const { t } = useTranslation()
  const methods = useForm<GlobalFilter>({ values: initialValue })
  const [currentFilter, setCurrentFilter] = useState('month')

  const { watch, setValue } = methods
  const watchFields = watch(['month', 'quartal', 'year'])

  useEffect(() => {
    if (currentFilter == 'month' && watchFields[1].length) {
      setValue('month', [])
      setCurrentFilter('quartal')
    }
    if (currentFilter == 'quartal' && watchFields[0].length) {
      setValue('quartal', [])
      setCurrentFilter('month')
    }
  }, [watchFields])

  const resetNonYearFilter = useCallback(() => {
    setValue('quartal', [])
    setValue('month', [])
  }, [setValue])

  return (
    <Form tw='pt-14' methods={methods} onSubmit={onUpdate}>
      {/* <Select
        id='location'
        name='location'
        label={t('global_filter.locations')}
        options={[{ title: 'Hrvatska', value: 'hr' }]}
      /> */}
      <Select
        id='year'
        name='year'
        label={t('global_filter.year')}
        options={Array(dayjs().year() + 1 - 2018)
          .fill(2018)
          .map((v, idx) => v + idx)
          .map(v => ({ title: v, value: v, onClick: resetNonYearFilter }))
          .reverse()}
      />
      <FieldSelect
        id='quartal'
        name='quartal'
        label={t('global_filter.quartal')}
        options={Array(4)
          .fill(undefined)
          .map((_, index) => ({
            title: 'Q' + (index + 1),
            value: index + 1,
            disabled: watchFields[2] == dayjs().year() && index * 3 >= dayjs().month(),
          }))}
      />
      <FieldSelect
        id='month'
        name='month'
        label={t('global_filter.month')}
        options={Array(12)
          .fill(undefined)
          .map((_, index) => ({
            title: index + 1,
            value: index + 1,
            disabled: watchFields[2] == dayjs().year() && index >= dayjs().month(),
          }))}
      />
      <Button tw='mt-4 w-full font-medium text-white uppercase'>{t('global_filter.apply')}</Button>
    </Form>
  )
}
