import { Button, PageContent, PageHeader } from '@components/ui'
import { useGetCompanyList } from '@hooks/api/company/useGetCompanyList'
import { useState } from 'react'
import { AddCompanyDrawer } from './Components/AddCompanyDrawer'
import { CompanyCard } from './Components/CompanyCard'

export const CompanyListPage = () => {
  const [isAddCompanyDrawerOpen, setIsAddCompanyDrawerOpen] = useState<boolean>(false)
  const { data } = useGetCompanyList()

  return (
    <>
      <PageHeader
        title={'Popis klijenata'}
        rightContent={
          <Button type={'button'} onClick={() => setIsAddCompanyDrawerOpen(true)}>
            Dodaj klijenta
          </Button>
        }
      />
      <PageContent className={'flex flex-wrap gap-10'}>
        {(data || []).map(company => (
          <CompanyCard key={company.name} company={company} />
        ))}
      </PageContent>
      <AddCompanyDrawer isOpen={isAddCompanyDrawerOpen} onClose={() => setIsAddCompanyDrawerOpen(false)} />
    </>
  )
}
