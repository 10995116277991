import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type Currency = 'EUR' | 'HRK'

const conversionRates = {
  EUR: 1,
  HRK: 7.534716,
}

interface IGlobalAuthStore {
  currency: Currency
  conversionRate: number
  setCurrency: (cur: Currency) => void
}

export const useCurrency = create(
  persist<IGlobalAuthStore>(
    (set, _) => ({
      currency: 'EUR',
      conversionRate: 1,
      setCurrency: (cur: Currency) => {
        set({ currency: cur, conversionRate: conversionRates[cur] })
      },
    }),
    {
      name: 'currency-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
