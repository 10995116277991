import { Card } from '@components/ui'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'
import tw from 'twin.macro'

export const MediaHeader = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const formatCurrency = useFormatCurrency()

  return (
    <PageHeader title={id} subtitle={t('media.media_profile')}>
      <section tw='mb-5 grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('media.plan')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          {formatCurrency(Math.round(Math.random() * 400000))}
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.costs')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          {formatCurrency(Math.round(Math.random() * 400000))}
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('media.client_profit')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          {formatCurrency(Math.round(Math.random() * 400000))}
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.profit')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          {formatCurrency(Math.round(Math.random() * 400000))}
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.margin')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          {formatCurrency(Math.round(Math.random() * 400000))}
        </Card>
      </section>
      <section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card title={<span>{t('media.avb%')}</span>} subtitle={<span>{Math.round(Math.random() * 50) + ' %'}</span>} />
        <Card
          title={<span>{t('media.avb')}</span>}
          subtitle={<span>{formatCurrency(Math.round(Math.random() * 100000))}</span>}
        />
        <Card
          title={<span>{t('media.realization_part')}</span>}
          subtitle={<span>{Math.round(Math.random() * 50) + ' %'}</span>}
        />
        <Card
          title={<span>{t('media.income_part')}</span>}
          subtitle={<span>{Math.round(Math.random() * 50) + ' %'}</span>}
        />
        <Card
          title={<span>{t('media.profit_part')}</span>}
          subtitle={<span>{Math.round(Math.random() * 50) + ' %'}</span>}
        />
      </section>
    </PageHeader>
  )
}
