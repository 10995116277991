import { EmployeeContent } from './Components/EmployeeContent'
import { EmployeeHeader } from './Components/EmployeeHeader'

export const Employee = () => {
  return (
    <>
      <EmployeeHeader />
      <EmployeeContent />
    </>
  )
}
