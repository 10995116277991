import { deleteExpenses } from '@api/expense'
import { deleteIncomes } from '@api/income'
import { ComponentWrapper, Table } from '@components/ui'
import { useIncomeAndExpense } from '@hooks/api/incomeExpense'
import { useArray } from '@hooks/useArray'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useMutation } from '@tanstack/react-query'
import { formatCurrency } from '@utils/formatCurrency'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteModal } from 'src/components/UI/Modal/DeleteModal'
import 'twin.macro'
import { importData, IncomeRow, updateRowsWithExistingData } from '../utils'
import { IncomeAndCostItem } from './IncomeAndCostItem'
import { IncomeAndCostTableHeader } from './IncomeAndCostTableHeader'

export const IncomeAndCostTable = () => {
  const {
    filter: { year, month },
  } = useGlobalFilter()
  const { t } = useTranslation()
  const containerRef = useRef<any>(null)
  const [filter, setFilter] = useState({ page: 1, search: '' })
  const [selectedField, setSelectedField] = useState<string>('')
  const date = useMemo(() => new Date(year, month?.[0] || new Date().getMonth()), [month, year])
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)

  const {
    array: rows,
    push: addRow,
    setArray: setRows,
    clearArray: clearRows,
    findIndex: findRowIndex,
    find: findRow,
    updateIndex: updateRow,
  } = useArray<IncomeRow>([])

  const {
    data: incomeExpenseData,
    refetch,
    isLoading: isIncomeExpenseLoading,
  } = useIncomeAndExpense(dayjs(date).format('YYYY-MM-15'))

  const { mutate: importMutate, isPending: isImportLoading } = useMutation({
    mutationFn: async () => {
      await importData(rows, dayjs(date).format('YYYY-MM-15'))
      clearRows()
      await refetch()
    },
  })
  const { mutate: deleteMutate, isPending: isDeleteLoading } = useMutation({
    mutationFn: async () => {
      await Promise.all([
        deleteIncomes(dayjs(date).format('YYYY-MM-15')),
        deleteExpenses(dayjs(date).format('YYYY-MM-15')),
      ])
      clearRows()
      await refetch()
      setIsDeleteOpen(false)
    },
  })

  const updateRows = (department: string, company: string, value: number | null) => {
    const rowIndex = findRowIndex(e => e.key == company)
    const row = findRow(e => e.key == company)
    if (row && value != null) {
      updateRow(rowIndex, { ...row, [`${department}`]: formatCurrency(value) })
      setSelectedField('')
    } else {
      setSelectedField('')
    }
  }

  const isLoading = useMemo(
    () => isIncomeExpenseLoading || isImportLoading || isDeleteLoading,
    [isIncomeExpenseLoading, isImportLoading, isDeleteLoading],
  )

  useOutsideClick(containerRef, () => {
    setSelectedField('')
  })

  useEffect(() => {
    updateRowsWithExistingData(setRows, clearRows, incomeExpenseData?.data)
  }, [incomeExpenseData])

  return (
    <ComponentWrapper>
      <IncomeAndCostTableHeader
        isLoading={isLoading}
        addRow={addRow}
        clearRows={clearRows}
        setIsDeleteOpen={setIsDeleteOpen}
        importMutate={importMutate}
        hasRows={!!rows}
        reload={refetch}
        onSearch={(search: string) => setFilter(f => ({ ...f, page: 1, search }))}
        rows={rows}
      />
      <div tw='overflow-scroll transition-all duration-500' className='hide-scrollbar' ref={containerRef}>
        <Table
          isLoading={isLoading}
          pagination={{
            currentPage: filter.page,
            onPageChange: (page: number) => setFilter(f => ({ ...f, page })),
            perPage: 20,
            total: rows.filter(r => r?.client?.toLowerCase()?.includes(filter.search))?.length ?? 0,
          }}
          columns={[
            {
              key: 'client',
              dataIndex: 'client',
              title: t('shared.client'),
              render: data => <span tw='whitespace-nowrap'>{data['client']}</span>,
            },
            ...(Object.keys(rows[0] ?? {}) ?? [])
              .filter(val => !['key', 'client'].includes(val))
              .map(val => ({
                key: val,
                dataIndex: val,
                title: val.replace(' - trošak', ''),
                render: (data: any) => {
                  return (
                    <IncomeAndCostItem
                      selectedField={selectedField}
                      setSelectedField={setSelectedField}
                      data={data}
                      updateRows={updateRows}
                      val={val}
                    />
                  )
                },
                sortableData: (data: { [x: string]: string }) => {
                  const tempValue = ((data?.[val] as string) || '').replaceAll('.', '').replaceAll(',', '.')
                  return Number(tempValue === '-' ? 0 : tempValue)
                },
              })),
          ]}
          data={rows.filter(r => r?.client?.toLowerCase()?.includes(filter.search))}
          options={{
            stickyHeader: true,
            stickyColumns: ['client'],
          }}
        />
      </div>
      <DeleteModal isOpen={isDeleteOpen} close={() => setIsDeleteOpen(false)} onDelete={deleteMutate} />
    </ComponentWrapper>
  )
}
