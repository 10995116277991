import { QueryProvider } from '@components'
import { NotificationProvider } from '@components/ui'
import { Router } from '@routes/Router'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './chart'
import './dayjs'
import './i18n'
import './index.css'
import './zod'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <NotificationProvider>
      <QueryProvider>
        <Router />
      </QueryProvider>
    </NotificationProvider>
  </StrictMode>,
)
