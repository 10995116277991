import { ComponentWrapper, LegendDot, Table } from '@components/ui'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { navigateRoutes } from '@routes/navigate'
import { ColorContext } from '@utils/colorContext'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'twin.macro'

export const TopMedias = () => {
  const [filter, setFilter] = useState('PLAN')
  const [tableData, setTableData] = useState<any>([])
  const { array, push, removeItem } = useArray<string | number>([])
  const { t } = useTranslation()
  const { getColor } = useContext(ColorContext)
  const formatCurrency = useFormatCurrency()

  useEffect(() => {
    setTableData(
      [
        {
          key: '1',
          media: 'Nova TV',
          share: 41,
        },
        {
          key: '2',
          media: 'HTV',
          share: 29,
        },
        {
          key: '3',
          media: 'RTL',
          share: 7,
        },
        {
          key: '4',
          media: 'Hanza',
          subItems: [
            {
              key: '4-1',
              media: 'Jutarnji list',
            },
            {
              key: '4-2',
              media: 'Jutarnji.hr',
            },
            {
              key: '4-3',
              media: 'Gloria',
            },
          ],
          share: 5,
        },
      ].map(val => ({
        ...val,
        plan: Math.round(Math.random() * 100000),
        realization: Math.round(Math.random() * 100000),
        client_profit: Math.round(Math.random() * 100000),
        profit: Math.round(Math.random() * 100000),
        margin: Math.round(Math.random() * 100000),
        subItems: val?.subItems?.map(si => ({
          ...si,
          plan: Math.round(Math.random() * 100000),
          realization: Math.round(Math.random() * 100000),
          client_profit: Math.round(Math.random() * 100000),
          profit: Math.round(Math.random() * 100000),
          margin: Math.round(Math.random() * 100000),
        })),
      })),
    )
  }, [])

  const totalShares = tableData.reduce((acc: number, val: any) => acc + val.share, 0)

  return (
    <ComponentWrapper>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'>{t('media.top_5_media')}</span>
        {/* <Segmented
          options={['PLAN', 'REALIZACIJA', 'PRIHOD', 'PROFIT', 'MARŽA']}
          value={filter}
          onChange={val => setFilter(val)}
        /> */}
      </div>
      <div tw='mb-7 flex h-9 w-full items-center overflow-hidden rounded shadow'>
        {tableData.map(({ media, share }: any) => (
          <div
            key={media}
            tw='flex h-full items-center p-3 text-icons-light text-sm transition-all duration-500'
            style={{ width: `${(share * 100) / totalShares}%`, background: getColor(media) }}
          >
            {`${share}%`}
          </div>
        ))}
      </div>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          columns={[
            {
              key: 'media',
              dataIndex: 'media',
              title: t('media.media'),
              render: data => (
                <Link to={navigateRoutes.media(data['key'])}>
                  <LegendDot title={data.media} showDot={!data.isSub} />
                </Link>
              ),
            },
            {
              key: 'plan',
              dataIndex: 'plan',
              title: t('media.plan'),
              render: data => <>{formatCurrency(data['plan'])}</>,
            },
            {
              key: 'realization',
              dataIndex: 'realization',
              title: t('media.realization'),
              render: data => <>{formatCurrency(data['realization'])}</>,
            },
            {
              key: 'client_profit',
              dataIndex: 'client_profit',
              title: t('media.client_profit'),
              render: data => <>{formatCurrency(data['client_profit'])}</>,
            },
            {
              key: 'profit',
              dataIndex: 'profit',
              title: t('media.profit'),
              render: data => <>{formatCurrency(data['profit'])}</>,
            },
            {
              key: 'margin',
              dataIndex: 'margin',
              title: t('shared.margin'),
              render: data => <>{formatCurrency(data['margin'])}</>,
            },
          ]}
          data={tableData}
        />
      </div>
    </ComponentWrapper>
  )
}
