import { UpdateClientDTO } from '@api/generated-api'
import { Form } from '@components/forms'
import { TextInput } from '@components/inputs'
import { Button, Dropdown } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { IClient } from '../../../../types/client'

interface IUpdateClientFormProps {
  onUpdate: (client: UpdateClientDTO) => void
  initialValue?: IClient
}

const UpdateClientSchema = z.object({
  id: z.number(),
  name: z.string().min(1),
  isActive: z.boolean(),
})

export const UpdateClientsForm: FC<IUpdateClientFormProps> = ({ onUpdate, initialValue }) => {
  const methods = useForm<UpdateClientDTO>({
    defaultValues: { ...initialValue, isActive: !initialValue?.deleted_at },
    resolver: zodResolver(UpdateClientSchema),
  })
  const { t } = useTranslation()
  const isActive = methods.watch('isActive')

  const activeOptions = useMemo(
    () => [
      {
        title: t('shared.active'),
        value: true,
        key: 'active',
        onClick: () => methods.setValue('isActive', true),
      },
      {
        title: t('shared.not_active'),
        value: false,
        key: 'not_active',
        onClick: () => methods.setValue('isActive', false),
      },
    ],
    [methods, t],
  )

  return (
    <Form methods={methods} onSubmit={onUpdate}>
      <TextInput id='name' name={'name'} label={t('shared.name')} containerStyle={`mb-3`} />
      <label className='text-secondary inline-block pb-3 text-sm font-semibold'>{t('shared.status')}</label>
      <Dropdown items={activeOptions} trigger='click'>
        <span tw='flex w-full overflow-hidden whitespace-nowrap'>{isActive ? 'Aktivan' : 'Neaktivan'}</span>
      </Dropdown>
      <section tw='flex justify-start'>
        <Button tw='mt-4 w-full font-medium text-white uppercase' type='submit'>
          {t('shared.edit')}
        </Button>
      </section>
    </Form>
  )
}
