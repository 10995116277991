import { PageContent } from '@components/ui'
import { ClientDepartmentsOverview } from './Components/ClientDepartments/ClientDepartmentsOverview'
import { ClientHeader } from './Components/ClientHeader'

export const Client = () => {
  return (
    <>
      <ClientHeader />
      <PageContent>
        <ClientDepartmentsOverview />
      </PageContent>
    </>
  )
}
