import { PageContent } from '@components/ui'
import { MediaHeader } from './Components/MediaHeader'
import { MediaOverview } from './Components/MediaOverview'

export const Media = () => {
  return (
    <>
      <MediaHeader />
      <PageContent>
        <MediaOverview />
      </PageContent>
    </>
  )
}
