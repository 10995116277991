import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'
const navItemStyle = tw`ml-2 mb-13 flex h-7 cursor-pointer items-center gap-4 hover:text-primary-light`
const navItemActiveStyle = tw`fill-primary text-primary hover:text-primary`
const navItemTitleStyle = tw`hidden scale-x-0 font-normal`

interface INavigationRowProps {
  name: string
  icon: any
  activeIcon: any
  title: string
  isExpanded: boolean
  activePage: string
  closeExpanded: () => void
  to: string
}

export const NavigationRow = ({
  name,
  icon,
  title,
  to,
  activeIcon,
  isExpanded,
  activePage,
  closeExpanded,
}: INavigationRowProps) => {
  const isActive = useMemo(() => activePage === name, [activePage, name])
  return (
    <Link to={to} onClick={closeExpanded}>
      <section css={[navItemStyle, isActive && navItemActiveStyle]}>
        <img
          width={24}
          height={24}
          style={{ height: 24, fill: 'crimson' }}
          src={isActive ? activeIcon : icon}
          alt={name}
          css={[isExpanded && tw`mr-6`]}
        />
        <span
          className='font-sans leading-snug group-hover/nav:inline group-hover/nav:scale-x-100'
          css={[navItemTitleStyle]}
        >
          {title}
        </span>
      </section>
    </Link>
  )
}
