import { axiosApiInstance } from '@api/axiosInstance'
import { RejectWorkingHourStatus, StatusIdsDTO } from '@api/generated-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useWorkingHoursManagementFunctions = () => {
  const queryClient = useQueryClient()
  const approveWorkingHours = useMutation({
    mutationFn: axiosApiInstance.workingHours.workingHoursControllerApproveWorkingHour,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours'] }),
  })
  const denyWorkingHours = useMutation({
    mutationFn: ({ whStatusId, data }: { whStatusId: number; data: RejectWorkingHourStatus }) =>
      axiosApiInstance.workingHours.workingHoursControllerRejectWorkingHour(whStatusId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours'] }),
  })

  const sendWorkingHoursReminders = useMutation({
    mutationFn: ({ data }: { data: StatusIdsDTO }) =>
      axiosApiInstance.workingHours.workingHoursControllerSendReminder(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours'] }),
  })

  return { approveWorkingHours, denyWorkingHours, sendWorkingHoursReminders }
}
