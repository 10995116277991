import { PageContent } from '@components/ui'
import { MediasHeader } from './Components/MediasHeader'
import { TopMedias } from './Components/TopMedias'

export const Medias = () => {
  return (
    <>
      <MediasHeader />
      <PageContent>
        <TopMedias />
      </PageContent>
    </>
  )
}
