import { Button } from '@components/ui'
import { notificationStore } from '@stores/notification.store'
import { formatCurrency, parseCurrency } from '@utils/formatCurrency'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { SearchInput } from '../../../components/Inputs/SearchInput/SearchInput'
import { ImportButton } from '../../../components/UI/Button/ImportButton'
import { importFile, IncomeRow } from '../utils'

interface IIncomeAndCostTableHeader {
  isLoading: boolean
  hasRows: boolean
  addRow: any
  clearRows: any
  setIsDeleteOpen: any
  importMutate: any
  reload: any
  onSearch: any
  rows: IncomeRow[]
}

export const IncomeAndCostTableHeader: FC<IIncomeAndCostTableHeader> = ({
  isLoading,
  addRow,
  clearRows,
  setIsDeleteOpen,
  importMutate,
  hasRows,
  reload,
  onSearch,
  rows,
}) => {
  const { addNotification } = notificationStore.getState()
  const { t } = useTranslation()

  const importIncomeFile = async (e: any) => {
    try {
      await importFile(e, addRow, clearRows)
    } catch (error: any) {
      addNotification({ title: 'Error', content: error.message, status: 'error' })
    }
  }

  const income = rows.reduce((acc, r) => {
    const val = parseCurrency(r['Ukupno prihodi'])
    return acc + (!isNaN(val) ? val : 0)
  }, 0)

  const expense = rows.reduce((acc, r) => {
    const val = parseCurrency(r['Ukupno troškovi'])
    return acc + (!isNaN(val) ? val : 0)
  }, 0)

  return (
    <section tw='flex items-center justify-between bg-white top-[150px]'>
      <section tw='flex items-center gap-3'>
        <ImportButton parseFile={e => importIncomeFile(e)} accept={'.xlsx,.xls'} />
        {!!(income || expense) && (
          <section tw='flex flex-col text-icons-dark text-sm'>
            <span>Ukupni Prihodi: {income ? formatCurrency(income) : 0}</span>
            <span>Ukupni Troškovi: {expense ? formatCurrency(expense) : 0}</span>
          </section>
        )}
      </section>
      {hasRows && (
        <section tw='flex justify-between'>
          <SearchInput onChange={e => onSearch(e.target.value)} />
          <Button isLoading={isLoading} buttonType='text' onClick={() => reload()}>
            <i className='fa fa-sync' tw='text-primary hover:text-primary-light' />
          </Button>
          <Button isLoading={isLoading} buttonType='text' onClick={() => setIsDeleteOpen(true)} tw='pl-0'>
            <i className='fa fa-trash' tw='text-error hover:text-error-light' />
          </Button>
          <Button isLoading={isLoading} onClick={() => importMutate()} tw='whitespace-nowrap'>
            {t('shared.save_data')}
          </Button>
        </section>
      )}
    </section>
  )
}
