import { TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { ICreatePl, IPl } from '@interfaces/pl'
import { zodNumber } from '@utils/validation'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface IPLFormProps {
  onUpdate: (pl: Omit<ICreatePl, 'date'>) => void
  isLoading?: boolean
  initialData: IPl
}

const PLSchema = z.object({
  special_projects: zodNumber(z.nan().or(z.number().positive())),
  sale_goods: zodNumber(z.nan().or(z.number().positive())),
  extraordinary_income: zodNumber(z.nan().or(z.number().positive())),
  other_income: zodNumber(z.nan().or(z.number().positive())),
  special_projects_costs: zodNumber(z.nan().or(z.number().positive())),
  representation: zodNumber(z.nan().or(z.number().positive())),
  office_materials: zodNumber(z.nan().or(z.number().positive())),
  consulting_costs: zodNumber(z.nan().or(z.number().positive())),
  lease: zodNumber(z.nan().or(z.number().positive())),
  memberships: zodNumber(z.nan().or(z.number().positive())),
  goods_procurement: zodNumber(z.nan().or(z.number().positive())),
  travel: zodNumber(z.nan().or(z.number().positive())),
  write_offs: zodNumber(z.nan().or(z.number().positive())),
  education: zodNumber(z.nan().or(z.number().positive())),
  bank_insurance_costs: zodNumber(z.nan().or(z.number().positive())),
  other_costs: zodNumber(z.nan().or(z.number().positive())),
  amortization: zodNumber(z.nan().or(z.number().positive())),
  financial_revenue: zodNumber(z.nan().or(z.number().positive())),
  financial_expenses: zodNumber(z.nan().or(z.number().positive())),
  profit_tax: zodNumber(z.nan().or(z.number().positive())),
})

export const PLForm: FC<IPLFormProps> = ({ onUpdate, initialData }) => {
  const methods = useForm<Omit<ICreatePl, 'date'>>({ resolver: zodResolver(PLSchema), defaultValues: initialData })
  const { t } = useTranslation()

  return (
    <Form methods={methods} onSubmit={onUpdate}>
      <section tw='mb-4 grid grid-cols-2 gap-8 max-lg:grid-cols-1'>
        <section tw='flex flex-col'>
          <h2 tw='mb-3 block text-icons-dark'>{t('shared.income')}</h2>
          <section tw='flex flex-col gap-3'>
            <TextInput id='total_income' label={t('pl.total_income')} disabled />
            <TextInput id='sale_services' label={t('pl.sale_services')} disabled />
            <TextInput id='avb_income' label={t('pl.avb')} disabled />
            <TextInput id='special_projects' label={t('pl.special_projects')} />
            <TextInput id='sale_goods' label={t('pl.sale_goods')} />
            <TextInput id='extraordinary_income' label={t('pl.extraordinary_income')} />
            <TextInput id='other_income' label={t('pl.other_income')} />
          </section>
        </section>
        <section tw='flex flex-col'>
          <h2 tw='mb-3 block text-icons-dark'>{t('shared.costs')}</h2>
          <section tw='grid grid-cols-2 gap-3'>
            <TextInput id='total_costs' label={t('pl.total_costs')} disabled />
            <TextInput id='direct_client_costs' label={t('pl.direct_client_costs')} disabled />
            <TextInput id='avb_costs' label={t('pl.avb')} disabled />
            <TextInput id='special_projects_costs' label={t('pl.special_projects')} />
            <TextInput id='salary_costs' label={t('pl.salary_costs')} disabled />
            <TextInput id='representation' label={t('pl.representation')} />
            <TextInput id='office_materials' label={t('pl.office_materials')} />
            <TextInput id='consulting_costs' label={t('pl.consulting_costs')} />
            <TextInput id='lease' label={t('pl.lease')} />
            <TextInput id='memberships' label={t('pl.memberships')} />
            <TextInput id='goods_procurement' label={t('pl.goods_procurement')} />
            <TextInput id='travel' label={t('pl.travel')} />
            <TextInput id='write_offs' label={t('pl.write_offs')} />
            <TextInput id='education' label={t('pl.education')} />
            <TextInput id='bank_insurance_costs' label={t('pl.bank_insurance_costs')} />
            <TextInput id='other_costs' label={t('pl.other_costs')} />
          </section>
        </section>
        <section tw='flex flex-col gap-3'>
          <h2 tw='block text-icons-dark'>{t('shared.gain')}</h2>
          <TextInput id='gross_without_salary' label={t('pl.gross_without_salary')} disabled />
          <TextInput id='gross_without_salary_margin' label={t('pl.gross_without_salary_margin')} disabled />
          <TextInput id='salary_costs_clients' label={t('pl.salary_costs_clients')} disabled />
          <TextInput id='gross_profit' label={t('pl.gross_profit')} disabled />
          <TextInput id='gross_profit_margin' label={t('pl.gross_profit_margin')} disabled />
          <TextInput id='amortization' label={t('pl.amortization')} />
        </section>
        <section tw='flex flex-col gap-3'>
          <h2 tw='block text-icons-dark'>{t('shared.editba')}</h2>
          <TextInput id='editba' label={t('pl.editba')} disabled />
          <TextInput id='editba_margin' label={t('pl.editba_margin')} disabled />
          <TextInput id='financial_revenue' label={t('pl.financial_revenue')} />
          <TextInput id='financial_expenses' label={t('pl.financial_expenses')} />
          <TextInput id='profit_tax' label={t('pl.profit_tax')} />
          <TextInput id='net_profit' label={t('pl.net_profit')} disabled />
          <TextInput id='net_profit_margin' label={t('pl.net_profit_margin')} disabled />
        </section>
      </section>

      <section tw='flex justify-start'>
        <Button tw='mt-4 w-full font-medium text-white uppercase' type='submit'>
          {t('shared.edit')}
        </Button>
      </section>
    </Form>
  )
}
