import { ColorContext } from '@utils/colorContext'
import { FC, useContext } from 'react'
import 'twin.macro'

interface ISectionBarProps {
  title: string
  values: { department: string; value: number }[]
}

export const SectionBar: FC<ISectionBarProps> = ({ title, values }) => {
  const { getColor } = useContext(ColorContext)

  return (
    <div tw='mb-4 flex flex-col'>
      <span tw='mb-3 text-lg text-icons-dark'>{title}</span>
      <div tw='flex h-9 w-full items-center overflow-hidden rounded shadow'>
        {values
          .sort((a, b) => b.value - a.value)
          .map(({ department, value }) => (
            <div
              key={department}
              tw='flex h-full items-center text-icons-light text-sm transition-all duration-500'
              style={{ width: `${value}%`, background: getColor(department) }}
            >
              {value >= 4 && <span tw='pl-4'>{`${value}%`}</span>}
            </div>
          ))}
      </div>
    </div>
  )
}
