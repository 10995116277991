import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const useGetWorkingHoursStatusByDate = ({ date, employeeId }: { date: string; employeeId: number }) => {
  const { workingHours } = axiosApiInstance
  return useQuery({
    queryKey: ['working-hours', 'status', date, employeeId],
    queryFn: () => workingHours.workingHoursControllerGetWorkingHoursStatusesByDate(employeeId, date),
    select: data => data.data,
    enabled: !!employeeId && !!date,
  })
}
