import { WorkingHoursStatusDtoStatusEnum } from '@api/generated-api'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface IChipProps {
  state?: WorkingHoursStatusDtoStatusEnum
}

const colorPerStatus = {
  'NOT SENT': '#FCF4C5',
  POPULATED: '#FCF4C5',
  SENT: '#ECEFF5',
  REJECTED: '#FADDDD',
  APPROVED: 'rgb(220, 246, 221)',
}

const translationPerStatus = {
  'NOT SENT': 'working_hours.status.incomplete',
  SENT: 'working_hours.status.sent',
  REJECTED: 'working_hours.status.rejected',
  APPROVED: 'working_hours.status.approved',
  POPULATED: 'working_hours.status.populated',
}

export const Chip: FC<IChipProps> = ({ state }) => {
  const { t } = useTranslation()
  const color = colorPerStatus[state]
  const text = translationPerStatus[state]

  return (
    <div
      className='flex items-center justify-center rounded-[3px] bg-red-400 py-1 px-[14px]'
      style={{ backgroundColor: color }}
    >
      {t(text)}
    </div>
  )
}
