import { clientOverview, clientsList, clientsSummary, clientSummary, clientYearCompare } from '@api/client'
import { useRefreshQuery } from './useRefreshQuery'

export const useClientsSummary = () => useRefreshQuery(['clients-summary'], clientsSummary)

export const useClientsList = () => useRefreshQuery(['clients-list'], clientsList)

export const useClientSummary = (id?: string) =>
  useRefreshQuery(['client-summary', id!], () => clientSummary(id!), { enabled: !!id })

export const useClientOverview = (id?: string) =>
  useRefreshQuery(['client-overview', id!.toString()], () => clientOverview(id!), { enabled: !!id })

export const useClientYearCompare = (id?: string) =>
  useRefreshQuery(['client-year-compare', id!.toString()], () => clientYearCompare(id!), { enabled: !!id })
