import { Card } from '@components/ui'
import { useCompanySummary } from '@hooks/api/company'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useMe } from '@stores/me.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'

export const CompanyHeader = () => {
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()
  const { data, isLoading } = useCompanySummary()

  const { id, name, income, expense, salary, bruto, bruto_margin, editba, editba_margin, neto, neto_margin } =
    data?.data ?? {}

  return (
    <PageHeader title={t('navigation.company')}>
      <section tw='mb-3 grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<span>{t('shared.income')}</span>}
            subtitle={<span>{formatCurrency(income)}</span>}
          />
        )}
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<span>{t('shared.overall_expenses')}</span>}
            subtitle={<span>{formatCurrency(expense)}</span>}
          />
        )}
        <Card
          loading={isLoading}
          title={<span>{t('shared.salary')}</span>}
          subtitle={<span>{formatCurrency(salary)}</span>}
        />
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<span>{t('shared.bruto_profit')}</span>}
            subtitle={<span>{formatCurrency(bruto)}</span>}
          />
        )}
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<span>{t('shared.bruto_margin')}</span>}
            subtitle={<span>{bruto_margin ? Math.round(bruto_margin) + '%' : '-'}</span>}
          />
        )}
      </section>
      {me?.companySettings?.hasPL && (
        <section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
          <Card loading={isLoading} title='EDITBA' subtitle={<span>{formatCurrency(editba)}</span>} />
          <Card
            loading={isLoading}
            title={<span>EDITBA {t('shared.margin')}</span>}
            subtitle={<span>{formatPercentage(editba_margin)}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('shared.neto_profit')}</span>}
            subtitle={<span>{formatCurrency(neto)}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('shared.neto_margin')}</span>}
            subtitle={<span>{formatPercentage(neto_margin)}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('departments.salary_share')}</span>}
            subtitle={<span>{Math.round((salary ?? 0) / (income ?? 1)) + '%'}</span>}
          />
        </section>
      )}
    </PageHeader>
  )
}
