import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export const useGetClientWorkingHoursExistingDates = ({ departmentId }: { departmentId: number }) => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<string[]>, null, string[]>({
    queryKey: ['working-hours', 'management', 'clients', 'dates', departmentId],
    queryFn: () => workingHours.workingHoursControllerGetExistingDates({ departmentId }),
    select: data => data.data,
    enabled: !!departmentId,
  })
}
