import 'twin.macro'
import { WorkingHoursMonthContent } from './Components/WorkingHoursMonthContent'
import { WorkingHoursMonthHeader } from './Components/WorkingHoursMonthHeader'

export const WorkingHoursMonth = () => {
  return (
    <div className={'flex h-full flex-1 flex-col'}>
      <WorkingHoursMonthHeader />
      <WorkingHoursMonthContent />
    </div>
  )
}
