import { AdminRouter } from '@routes/AdminRouter'
import { EmployeeRouter } from '@routes/EmployeeRouter'
import { ManagerRouter } from '@routes/ManagerRouter'
import { SuperAdminRouter } from '@routes/SuperAdminRouter'
import { useMe } from '@stores/me.store'
import { BrowserRouter } from 'react-router-dom'
import { PublicRouter } from './PublicRouter'

export const Router = () => {
  const { me } = useMe()
  return (
    <BrowserRouter>
      {!me ? (
        <PublicRouter />
      ) : me?.isSuperAdmin ? (
        <SuperAdminRouter />
      ) : me?.isAdmin ? (
        <AdminRouter />
      ) : me?.isManager ? (
        <ManagerRouter />
      ) : (
        <EmployeeRouter />
      )}
    </BrowserRouter>
  )
}
