import { PageContent } from '@components/ui'
import { ClientsHeader } from './Components/ClientsHeader'
import { ClientsTable } from './Components/ClientsTable'

export const Clients = () => {
  return (
    <>
      <ClientsHeader />
      <PageContent>
        <ClientsTable />
      </PageContent>
    </>
  )
}
