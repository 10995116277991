import { createStore } from 'zustand'

export type Notification = {
  key: number
  status?: 'info' | 'warning' | 'error' | 'success'
  autoClose?: boolean
  title?: string
  content: string
  timerStarted?: boolean
  mounted?: boolean
}
export interface INotificationStore {
  notifications: Notification[]
  getNotification: (key: number) => Notification | null
  addNotification: (notification: Omit<Notification, 'key'>) => void
  removeNotification: (key: number) => void
  updateNotification: (key: number, notification: Partial<Omit<Notification, 'key'>>) => void
  closeNotification: (key: number) => void
}

export const notificationStore = createStore<INotificationStore>((set, get) => ({
  notifications: [],
  getNotification: (key: number) => {
    return get()?.notifications?.find(val => val.key == key) ?? null
  },
  addNotification: ({ autoClose = true, ...notification }: Omit<Notification, 'key'>) =>
    set(state => ({
      notifications: [...state.notifications, { ...notification, autoClose, key: Math.random() }],
    })),
  removeNotification: (key: number) => {
    set(state => {
      const notificationIntex = state.notifications.findIndex(n => n.key == key)
      if (notificationIntex != -1) {
        return {
          notifications: [
            ...state.notifications.slice(0, notificationIntex),
            ...state.notifications.slice(notificationIntex + 1),
          ],
        }
      }
      return state
    })
  },
  updateNotification: (key: number, notification: Partial<Omit<Notification, 'key'>>) => {
    set(state => {
      const notificationIntex = state.notifications.findIndex(n => n.key == key)
      if (notificationIntex != -1) {
        return {
          notifications: [
            ...state.notifications.slice(0, notificationIntex),
            { ...state.notifications[notificationIntex], ...notification },
            ...state.notifications.slice(notificationIntex + 1),
          ],
        }
      }
      return state
    })
  },
  closeNotification: (key: number) => {
    set(state => {
      const notificationIntex = state.notifications.findIndex(n => n.key == key)
      if (notificationIntex != -1) {
        return {
          notifications: [
            ...state.notifications.slice(0, notificationIntex),
            { ...state.notifications[notificationIntex], mounted: false, timerStarted: true },
            ...state.notifications.slice(notificationIntex + 1),
          ],
        }
      }
      return state
    })
  },
}))
