import { createClient } from '@api/client'
import Assets from '@assets/assets'
import { navigateRoutes } from '@routes/navigate'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import 'twin.macro'
import { ICreateClient } from '../../../../types/client'
import { CreateClientModal } from '../../../pages/Clients/Components/CreateClientsModal'
import { CreateEmployeeDrawer } from '../../../pages/Employees/Components/CreateEmployeeDrawer'
import { ConfirmationModal } from '../../ConfirmationModal'
import { Button } from '../Button/Button'
import { Drawer } from '../Drawer/Drawer'
import { Filter } from './Filter'

export const Header: FC = () => {
  const [isCreateClientOpen, setIsCreateClientOpen] = useState<boolean>(false)
  const [isCreateEmployeeOpen, setIsCreateEmployeeOpen] = useState<boolean>(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean | string>(false)

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isVisible = useMemo(
    () =>
      !pathname.includes('/working-hours') && !pathname.includes('/account') && !pathname.includes('/edit-departments'),
    [pathname],
  )
  const isEmployeeList = pathname === '/employees'
  const isClientsList = pathname === '/clients'
  const isDepartments = pathname === '/departments'
  const isCompany = pathname === '/company'
  const queryClient = useQueryClient()
  const employeePatern = /(?<=employees\/)\d+/
  const clientPattern = /(?<=clients\/)\d+/

  const employeeMatch = pathname.match(employeePatern)
  const clientMatch = pathname.match(clientPattern)

  const { mutateAsync: createClientAsync } = useMutation({
    mutationFn: createClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['clients-list'] })
    },
  })

  if (!isVisible) {
    return null
  }

  const createClientFn = async (client: ICreateClient) => {
    const resp = await createClientAsync(client)
    setIsCreateClientOpen(false)
    if (resp?.data?.name) {
      setIsConfirmationModalOpen(resp?.data?.name)
    }
  }

  return (
    <header className='h-18 border-accent bg-background flex items-center justify-between border-b px-7'>
      {employeeMatch || clientMatch ? (
        <div
          className='cursor-pointer'
          onClick={() => {
            history.go(-1)
          }}
        >
          <img src={Assets.ArrowLeft} className='transition-transform' alt={'arrow-back'} />
        </div>
      ) : (
        <div></div>
      )}
      <div className={'flex items-center gap-4'}>
        {isClientsList && (
          <div className={'flex gap-4'}>
            <Button buttonType={'secondary'} onClick={() => {}}>
              {t('clients.import_clients')}
            </Button>
            <Button onClick={() => setIsCreateClientOpen(true)}>{t('clients.add_client')}</Button>
          </div>
        )}
        {isEmployeeList && (
          <div className={'flex gap-4'}>
            {/*<Button buttonType={'secondary'} onClick={() => {}}>*/}
            {/*  {t('employees.import_employees')}*/}
            {/*</Button>*/}
            <Button onClick={() => setIsCreateEmployeeOpen(true)}>{t('employees.add_employee')}</Button>
          </div>
        )}
        {isDepartments && (
          <Link to={navigateRoutes.editDepartments()}>
            <Button onClick={() => {}}>{t('departments.edit_departments')}</Button>
          </Link>
        )}
        {isCompany && (
          <div className={'flex gap-4'}>
            <Button onClick={() => {}}>{t('company.import_data')}</Button>
          </div>
        )}
        <i onClick={() => setIsFilterOpen(true)} tw='ml-4 cursor-pointer text-primary' className='fa fa-filter' />
      </div>

      <Drawer
        title={t('global_filter.title') as string}
        placement='right'
        isOpen={isFilterOpen}
        close={() => setIsFilterOpen(false)}
      >
        <Filter closeDrawer={() => setIsFilterOpen(false)} />
      </Drawer>
      <CreateEmployeeDrawer
        isCreateEmployeeOpen={isCreateEmployeeOpen}
        setIsCreateEmployeeOpen={setIsCreateEmployeeOpen}
      />
      <CreateClientModal
        isOpen={isCreateClientOpen}
        close={() => setIsCreateClientOpen(false)}
        createClient={createClientFn}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={`Klijent '${isConfirmationModalOpen}' uspješno dodan`}
      />
    </header>
  )
}
