import { useWindowSize } from '@hooks/useWindowSize'
import { Order } from '@interfaces/order'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchInput } from 'src/components/Inputs/SearchInput/SearchInput'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'
import { SpinningLoader } from '../Loaders/SpinningLoader'
import { IPaginationProps, Pagination } from '../Pagination/Pagination'
import { TableRow } from './TableRow'

export type ITableData = {
  key: string | number
  subItems?: ITableData[]
  children?: ITableData[]
  isSub?: boolean
  [key: string]: any
  decoration?: TwStyle
}

export interface ITableOptions {
  stickyColumns?: string[]
  stickyHeader?: boolean
}

export type ITableColumn = {
  key: string | number
  title: string
  dataIndex: string
  decoration?: TwStyle
  sortableData?: (data: ITableData) => string | number | undefined
  hidden?: boolean
  rowDecoration?: TwStyle
  render?: (data: ITableData) => JSX.Element
  order?: (key?: string) => any
}

interface ITableProps {
  data: ITableData[]
  columns: ITableColumn[]
  pagination?: IPaginationProps
  search?: (search: string) => void
  onRowClick?: (id: string | number, row: ITableData) => void
  headerActions?: JSX.Element[]
  title?: string
  visibleRows?: (string | number)[]
  order?: Order | null
  rowaction?: (row: ITableData) => JSX.Element
  isLoading?: boolean
  options?: ITableOptions
  expandedChildren?: (data: any) => JSX.Element
}

export const Table: FC<ITableProps> = ({
  data,
  columns,
  pagination,
  headerActions,
  onRowClick,
  visibleRows,
  search,
  title,
  rowaction,
  expandedChildren,
  isLoading,
  options,
  order,
  ...props
}) => {
  const [sorting, setSorting] = useState<{ column: string | null; direction: string }>({
    column: null,
    direction: 'asc', // or 'desc'
  })
  const { height } = useWindowSize()

  const haveChildren = data.some(
    d => (d?.children != null && !!d?.children?.length) || (d?.subItems != null && !!d?.subItems?.length),
  )
  const rows = useMemo(() => {
    return Math.floor(height / 110)
  }, [height])
  const { t } = useTranslation()
  const shownColumns = columns.filter(c => !c.hidden)
  const numOfPages = Number(((pagination?.total || 0) / (rows || 1)).toFixed(0))

  const handleSort = (columnKey: string) => {
    const column = columns.find(col => col.key === columnKey)
    if (column?.sortableData) {
      setSorting(prevSorting => {
        return {
          column: prevSorting.direction === 'desc' ? null : columnKey,
          direction: prevSorting.column === columnKey && prevSorting.direction === 'asc' ? 'desc' : 'asc',
        }
      })
    }
  }

  const sortedData = useMemo(
    () =>
      [...data].sort((a, b) => {
        const column = columns.find(col => col.key === sorting.column)
        if (!column || !column.sortableData) {
          return 0
        }
        const ax = column?.sortableData(a)
        const bx = column?.sortableData(b)

        if ((ax && bx) || (ax === 0 && bx) || (bx === 0 && ax)) {
          if (sorting.direction === 'asc') {
            return ax < bx ? -1 : ax > bx ? 1 : 0
          } else {
            return bx < ax ? -1 : bx > ax ? 1 : 0
          }
        } else {
          return 0
        }
      }),
    [columns, data, sorting.column, sorting.direction],
  )

  if (isLoading) {
    return (
      <div className={'flex min-h-[400px] items-center justify-center'}>
        <SpinningLoader size={'large'} />
      </div>
    )
  }

  return (
    <>
      {(!!headerActions || !!search) && (
        <div tw='mb-5 flex w-full justify-between'>
          <span tw='text-icons-dark text-2xl'>{title}</span>
          <div className={'flex items-center gap-4'}>
            {search && (
              <SearchInput placeholder={t('shared.search') as string} onChange={e => search(e.currentTarget.value)} />
            )}
            {!!headerActions && headerActions.length > 0 && headerActions.map(action => action)}
          </div>
        </div>
      )}
      {!!data && data?.length === 0 ? (
        <div className={'flex min-h-[400px] items-center justify-center'}>
          <div className='text-[32px] font-thin leading-[38.40px] text-gray-600/70'>
            Trenutno nema podataka za prikaz
          </div>
        </div>
      ) : (
        <table {...props} tw='w-full table-fixed border-collapse overflow-hidden rounded-t-lg text-sm'>
          <thead>
            <tr
              css={[
                options?.stickyHeader &&
                  tw`sticky top-0 z-20 bg-gradient-to-b from-white to-transparent via-[rgba(256,256,256,90)]`,
              ]}
            >
              {shownColumns.map(column => (
                <th
                  className='border-b-background text-icons relative whitespace-nowrap border-b py-3 px-2 text-end font-medium uppercase transition-all first-of-type:text-start'
                  css={[
                    column.decoration,
                    options?.stickyColumns?.includes(column.key as string) &&
                      tw`sticky left-0 z-20 space-x-2 border-b bg-gradient-to-br from-white to-transparent via-[rgba(256,256,256,90)]`,
                  ]}
                  key={column.key}
                  onClick={() => handleSort(column.key.toString())}
                >
                  <span
                    css={[
                      column.order && tw`cursor-pointer hover:text-icons-dark`,
                      order?.key == column.dataIndex && tw`text-icons-dark`,
                    ]}
                    onClick={column?.order ? () => column.order!(column.dataIndex) : undefined}
                  >
                    {column.title}
                    {column.key === sorting.column && (
                      <i className={`fa fa-chevron-${sorting.direction === 'asc' ? 'up' : 'down'} ml-2`} />
                    )}
                  </span>
                  {column?.order && (
                    <i
                      className='fa fa-chevron-down'
                      tw='ml-2 transition-transform'
                      css={[order?.key != column.dataIndex && tw`hidden`, order?.direction == 'ASC' && tw`rotate-180`]}
                    />
                  )}
                </th>
              ))}
              {rowaction && (
                <th tw='relative border-b border-b-background py-3 px-2 font-medium text-icons text-end uppercase transition-all first:text-start' />
              )}
            </tr>
          </thead>

          <tbody>
            {sortedData
              .slice(
                pagination ? rows * (pagination.currentPage - 1) : 0,
                pagination ? rows * pagination.currentPage : undefined,
              )
              .map((row, index) => (
                <TableRow
                  key={row.key + index.toString()}
                  row={row}
                  expandedChildren={expandedChildren}
                  onRowClick={onRowClick}
                  haveChildren={haveChildren}
                  rowaction={rowaction}
                  columns={shownColumns}
                  stickyColumns={options?.stickyColumns}
                  visibleRows={visibleRows}
                />
              ))}
          </tbody>
        </table>
      )}
      {pagination && numOfPages > 1 && (
        <div tw='mt-4 flex w-full justify-end'>
          <Pagination {...pagination} perPage={rows} />
        </div>
      )}
    </>
  )
}
