import { ICreatePl, IPl, IUpdatePl } from '@interfaces/pl'
import { del, get, patch, post } from './axiosInstance'

export const getPl = (date: string) => get<IPl>(`/pl/date/${date}`)

export const createPl = (pls: ICreatePl) => post('/pl', pls)

export const updatePls = (plsId: number, pls: IUpdatePl) => patch(`/pl/${plsId}`, pls)

export const deletePls = (date: string) => del<Array<IPl>>(`/pl/date/${date}`)
