import { Table } from '@components/ui'
import { useEmployeesList } from '@hooks/api/employee'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useOrder } from '@hooks/useOrder'
import { navigateRoutes } from '@routes/navigate'
import { useMe } from '@stores/me.store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'twin.macro'

export const ClientEmployeeTable = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { array, push, removeItem } = useArray<string | number>([])
  const [order, setOrder, orderData] = useOrder({ key: 'employee', direction: 'ASC' })
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()
  const { data } = useEmployeesList()

  const tableData = (data?.data || [])
    .map(l => ({
      ...l,
      key: l.employee_id,
      total_cost:
        Number(l['bruto']) + Number(l['rest']) + (!me?.companySettings?.hasOvertime ? 0 : Number(l?.['overtime'] || 0)),
      hours: {
        hours: l.hours,
        percentage: 100,
      },
      children: (l?.clients ?? [])
        .map(c => ({ ...c, key: c.client_id, hours: { hours: c.hours, percentage: (c.hours / l.hours) * 100 } }))
        .sort((a, b) => b.hours.hours - a.hours.hours),
    }))
    .filter(z => z.employee?.toLowerCase().includes(search.toLowerCase()))

  return (
    <>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          title={t('employees.client_per_employee')!}
          search={(search?: string) => setSearch(search ?? '')}
          order={order}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            perPage: 10,
            total: tableData.length,
          }}
          columns={[
            {
              key: 'employee',
              dataIndex: 'employee',
              title: t('shared.employee_client'),
              order: setOrder,
              render: data => (
                <>
                  {data['employee'] && (
                    <Link to={navigateRoutes.employee(data['employee_id'])}>
                      <span tw='text-primary'>{data['employee']}</span>
                    </Link>
                  )}
                  {data['client'] &&
                    (me?.isAdmin ? (
                      <Link to={navigateRoutes.client(data['client_id'])}>
                        <span tw='text-primary'>{data['client']}</span>
                      </Link>
                    ) : (
                      <span tw='text-primary'>{data['client']}</span>
                    ))}
                </>
              ),
            },
            {
              key: 'departments',
              dataIndex: 'departments',
              title: t('employees.departments'),
              order: setOrder,
              render: data => <>{(data?.['departments'] || []).join(', ')}</>,
            },
            {
              key: 'neto',
              dataIndex: 'neto',
              title: t('shared.neto'),
              order: setOrder,
              render: data => <>{formatCurrency(data['neto'])}</>,
            },
            {
              key: 'bruto',
              dataIndex: 'bruto',
              title: t('shared.bruto2'),
              order: setOrder,
              render: data => <>{formatCurrency(data['bruto'])}</>,
            },
            {
              key: 'rest',
              dataIndex: 'rest',
              title: t('shared.other_expenses'),
              order: setOrder,
              render: data => <>{formatCurrency(data['rest'])}</>,
            },
            {
              key: 'hours',
              dataIndex: 'hours',
              title: t('shared.worked_hours'),
              order: setOrder,
              render: data => (
                <>
                  {data['hours']
                    ? `${Math.round(data['hours'].hours)}` +
                      (Number(data['hours'].percentage) < 100
                        ? ` (${Number(data['hours'].percentage).toFixed(0)}%)`
                        : '')
                    : '-'}
                </>
              ),
            },
            {
              key: 'overtime',
              dataIndex: 'overtime',
              title: t('shared.overtime'),
              order: setOrder,
              hidden: !me?.companySettings?.hasOvertime,
              render: data => <>{data?.['overtime'] || '-'}</>,
            },
            {
              key: 'total_cost',
              dataIndex: 'total_cost',
              title: t('shared.total_cost'),
              order: setOrder,
              render: data => <>{formatCurrency(data?.['total_cost']) || '-'}</>,
            },
          ]}
          data={tableData.sort(orderData)}
        />
      </div>
    </>
  )
}
