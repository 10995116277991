import Assets from '@assets/assets'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { useWindowSize } from '@hooks/useWindowSize'
import {
  Dispatch,
  FC,
  MouseEvent,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'
import { DropdownSearch } from './DropdownSearch'
import { calculateMenuPosition } from './utils'

export type DropdownItem = {
  key: string | number
  title?: string
  render?: JSX.Element
  disabled?: boolean
  decoration?: TwStyle | (TwStyle | undefined)[]
  onClick?: (item: any, e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

interface IDropdownProps {
  items: DropdownItem[]
  arrow?: boolean
  disabled?: boolean
  trigger?: 'click' | 'hover' | 'context'
  isOpen?: boolean
  closeOnItemAction?: boolean
  hideTriggerBorder?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  onOutsideClick?: () => void
  containerStyle?: TwStyle
  arrowStyle?: TwStyle
  dropdownStyle?: TwStyle
  onClick?: () => void
  hasSearch?: boolean
}

export const Dropdown: FC<PropsWithChildren<IDropdownProps>> = ({
  items,
  isOpen,
  setIsOpen,
  trigger = 'hover',
  hideTriggerBorder = false,
  closeOnItemAction = true,
  onOutsideClick,
  containerStyle,
  arrowStyle,
  dropdownStyle,
  children,
  onClick,
  hasSearch,
  ...props
}) => {
  const isMountingRef = useRef(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const handlerRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    isMountingRef.current = true
  }, [])

  const isOpenInternal = trigger !== 'context' ? isDropdownOpen : isOpen
  const setIsOpenInternal = trigger !== 'context' ? setIsDropdownOpen : setIsOpen ?? undefined

  const handleOnOutsideClick = () => {
    if (setIsOpenInternal) {
      setIsOpenInternal(false)
    }
    if (onOutsideClick) {
      onOutsideClick()
    }
  }
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (onClick) {
      onClick()
    }
    if (trigger == 'click' && !!setIsOpenInternal) {
      setIsOpenInternal(!isOpenInternal)
    }
  }

  useOutsideClick(containerRef, handleOnOutsideClick)

  const handlerPosition = handlerRef?.current?.getBoundingClientRect()
  const menuPosition = menuRef?.current?.getBoundingClientRect()
  const { top, left } = useMemo(
    () =>
      calculateMenuPosition({
        triggerDimensions: handlerPosition,
        menuDimensions: menuPosition,
      }),
    [handlerPosition, menuPosition],
  )

  const minMenuWidth = useMemo(() => Math.max(handlerPosition?.width || 0, 100), [handlerPosition?.width])
  const filteredItems = useMemo(
    () => items.filter(i => (searchTerm ? (i?.title || '')?.toLowerCase().includes(searchTerm.toLowerCase()) : true)),
    [items, searchTerm],
  )

  const { width } = useWindowSize()

  const initialPosition = Math.min(width - minMenuWidth || 0, left)

  return (
    <div {...props} ref={containerRef} tw='relative flex cursor-pointer items-center transition-none'>
      <button
        tw='flex w-full items-center justify-between gap-2 rounded border border-zinc-200 px-4 py-2 transition-colors min-w-[80px] focus:(border-primary text-gray-700)'
        ref={handlerRef}
        onClick={handleClick}
        css={[containerStyle, hideTriggerBorder && tw`border-none`]}
        disabled={props.disabled}
        type='button'
      >
        {children}
        <img
          tw='-rotate-90 transition-all'
          css={[isOpenInternal && tw`rotate-90`, arrowStyle]}
          src={Assets.CaretRight}
          alt='caret down'
        />
      </button>
      {createPortal(
        <div
          ref={menuRef}
          className='border-accent shadow-dropdown-menu absolute z-50 max-h-64 max-w-full rounded bg-white transition-opacity focus:outline-none'
          style={{
            transform: `translateY(${(handlerRef?.current?.clientHeight || 0) + 4 ?? 40}px)`,
            top,
            left: Math.min(initialPosition, left),
            width: `${minMenuWidth}px`,
          }}
          css={[
            isOpenInternal && (menuPosition?.width || 0) > 0 && isMountingRef.current && left < width
              ? tw`z-[10001]! opacity-100`
              : tw`pointer-events-none opacity-0 z-[-1]`,
            dropdownStyle,
          ]}
        >
          {hasSearch && (
            <DropdownSearch
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onChange={a => {
                setSearchTerm(a.target.value)
              }}
            />
          )}
          <div className={'max-h-52 overflow-scroll'}>
            {filteredItems.map(item => (
              <div
                tw='cursor-pointer px-4 py-2 text-text-unfilled text-[14px] hover:(bg-select-bg text-text-gray)'
                css={item.decoration}
                key={item.key}
                onClick={e => {
                  if (item.onClick) {
                    item.onClick(item, e)
                    if (closeOnItemAction && !!setIsOpenInternal) {
                      setIsOpenInternal(false)
                    }
                  }
                }}
              >
                {item.title ?? item.render}
              </div>
            ))}
          </div>
        </div>,
        document.body,
      )}
    </div>
  )
}
