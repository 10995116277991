import { FC } from 'react'
import { Oval } from 'react-loader-spinner'
import 'twin.macro'

const sizes = {
  small: 20,
  medium: 40,
  large: 80,
}

export const SpinningLoader: FC<{ size: 'small' | 'medium' | 'large' }> = ({ size = 'large' }) => {
  const sizeNumber = sizes[size]
  return (
    <Oval
      visible={true}
      height={sizeNumber}
      width={sizeNumber}
      secondaryColor={'white'}
      color='#527CFB'
      ariaLabel='tail-spin-loading'
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  )
}
