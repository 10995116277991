import { LoginForm } from '@components/forms'

import { useTranslation } from 'react-i18next'
import 'twin.macro'

export const Login = () => {
  const { t } = useTranslation()

  return (
    <div tw='flex h-screen items-center justify-center before:(absolute z-0 h-screen w-screen -translate-y-2 bg-login-background bg-cover)'>
      <div tw='relative z-10 flex flex-col rounded bg-white p-10 shadow-neutral-200 shadow-basic min-w-[430px]'>
        <span tw='mb-6 border-b-accent pb-6 text-xl border-b-[0.5px]'>
          <span tw='text-primary'>{'Business '}</span>Monitor
        </span>
        <span tw='mb-2 font-normal text-title'>{t('login.title')}</span>
        <LoginForm />
      </div>
    </div>
  )
}
