import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'

type FieldSelect = {
  value: string | number
  title: string | number
  disabled?: boolean
}

interface IFieldSelectProps {
  options: FieldSelect[]
  label?: string | null
  cols?: number
  name: string
  id: string
}

export const FieldSelect: FC<IFieldSelectProps> = ({ id, name, options, label, cols = 4 }) => {
  const { register, setValue, watch } = useFormContext()

  const { [name]: fieldValue } = watch()

  const updateFieldValue = (field: string | number) => {
    const values: Array<string | number> = fieldValue ?? []
    if (values.includes(field)) {
      const idx = values.findIndex(val => val == field)
      if (idx != -1) {
        setValue(name, [...values.slice(0, idx), ...values.slice(idx + 1)])
      }
      return
    }
    setValue(name, [...values, field])
  }

  return (
    <>
      <input id={id} tw='hidden' {...register(name)} />
      {label && <span tw='font-semibold text-sm text-secondary'>{label}</span>}
      <div tw='mt-3 mb-13 grid grid-cols-4 gap-0 overflow-hidden rounded-md border border-accent'>
        {options.map((val, index) => (
          <div
            key={val.value}
            onClick={val.disabled ? undefined : () => updateFieldValue(val.value)}
            tw='flex cursor-pointer items-center justify-center border-accent py-2 transition-all hover:bg-background'
            css={[
              index % cols < cols - 1 && tw`border-r`,
              index / cols >= 1 && tw`border-t`,
              val.disabled && tw`cursor-not-allowed text-accent hover:bg-white`,
              (fieldValue ?? []).includes(val.value) && tw`bg-background text-primary`,
            ]}
          >
            {val.title}
          </div>
        ))}
      </div>
    </>
  )
}
