import { ICreateIncomes, IIncome, IUpdateIncome } from '@interfaces/income'
import { del, get, patch, post } from './axiosInstance'

export const getIncomes = (date: string) => get<Array<IIncome>>(`/income/date/${date}`)

export const createIncomes = (incomes: ICreateIncomes) => post('/income', incomes)

export const updateIncomes = (incomesId: number, incomes: IUpdateIncome) => patch(`/income/${incomesId}`, incomes)

export const deleteIncomes = (date: string) => del<Array<IIncome>>(`/income/date/${date}`)
