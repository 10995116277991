import { ICreateExpenses, IExpense, IUpdateExpense } from '@interfaces/expense'
import { del, get, patch, post } from './axiosInstance'

export const getExpenses = (date: string) => get<Array<IExpense>>(`/expense/date/${date}`)

export const createExpenses = (expenses: ICreateExpenses) => post('/expense', expenses)

export const updateExpenses = (expensesId: number, expenses: IUpdateExpense) =>
  patch(`/expense/${expensesId}`, expenses)

export const deleteExpenses = (date: string) => del<Array<IExpense>>(`/expense/date/${date}`)
