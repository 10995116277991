import { ComponentWrapper, Table } from '@components/ui'

import Assets from '@assets/assets'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import tw from 'twin.macro'
import { IWorkingHoursActiveTableData } from '../../../../types'
import { HoursManagementContentModal } from '../HoursManagementContentModal'
import { ActiveActionsRenderer } from '../Renderers/ActiveActionsRenderer'

export const ActiveWorkingHoursManagementTable = ({
  departmentId,
  data = [],
  isLoading,
}: {
  departmentId?: number
  data?: IWorkingHoursActiveTableData[]
  isLoading: boolean
}) => {
  const [page, setPage] = useState(1)
  const [selectedWorkingHours, setSelectedWorkingHours] = useState<IWorkingHoursActiveTableData | undefined>(undefined)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'ASC' })
  const { t } = useTranslation()
  const hasNotes = (data || [])?.some(td => !!td.note)
  return (
    <ComponentWrapper className={'relative'}>
      <Table
        order={order}
        isLoading={isLoading}
        pagination={{
          currentPage: page,
          onPageChange: setPage,
          perPage: 10,
          total: data.length,
        }}
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            title: t('working_hours_management.name'),
            order: setOrder,
            render: data => <>{data['name'] || '-'}</>,
          },
          {
            key: 'date',
            dataIndex: 'date',
            title: t('working_hours_management.month'),
            decoration: tw`w-[200px]`,
            order: setOrder,
            render: data => <>{dayjs(data['date']).format('MMMM')}</>,
          },
          {
            key: 'overtime_hours',
            dataIndex: 'overtime_hours',
            title: t('working_hours_management.overtime'),
            decoration: tw`w-[200px]`,
            order: setOrder,
            render: data => <>{data['overtime_hours']}</>,
          },
          {
            key: 'note',
            dataIndex: 'note',
            title: t('working_hours_management.note'),
            decoration: tw`justify-center w-[200px]`,
            order: setOrder,
            render: data => {
              const hasNote = !!data['note']
              return (
                <>
                  {hasNote ? (
                    <div data-tooltip-id='notes-tooltip' data-tooltip-content={data['note']}>
                      <img src={Assets.NoteIcon} alt={'note-icon'} />
                    </div>
                  ) : (
                    '-'
                  )}
                </>
              )
            },
            hidden: !hasNotes,
          },
          {
            key: 'sent',
            dataIndex: 'sent',
            decoration: tw`w-[200px]`,
            title: t('working_hours_management.sent'),
            order: setOrder,
            render: data => <>{dayjs(data['sent']).format('DD.MM.YYYY.')}</>,
          },
          {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            decoration: tw`w-[100px]`,
            order: setOrder,
            render: data => <ActiveActionsRenderer data={data} />,
          },
        ]}
        onRowClick={(id, row) => setSelectedWorkingHours(row)}
        data={data.map(d => ({ ...d, key: d.id })).sort(orderData)}
      />
      <Tooltip id='notes-tooltip' place={'top'} style={{ maxWidth: 200, lineBreak: 'normal' }} />
      {!!selectedWorkingHours && (
        <HoursManagementContentModal
          isOpen={!!selectedWorkingHours}
          onClose={() => setSelectedWorkingHours(undefined)}
          date={dayjs(selectedWorkingHours.date).format('YYYY-MM-15')}
          departmentId={departmentId}
          employeeId={selectedWorkingHours.employee_id}
        />
      )}
    </ComponentWrapper>
  )
}
