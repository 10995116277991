import { env } from '@utils/environment'
import { privateRoutes } from './routes'

export const navigateRoutes = {
  client: (id: number | string) => privateRoutes.CLIENTS_PATH + '/' + id,
  employee: (id: number | string) => privateRoutes.EMPLOYEES_PATH + '/' + id,
  workingHours: (id: number | string) => privateRoutes.WORKING_HOURS + '/' + id,
  media: (id: number | string) => privateRoutes.MEDIAS_PATH + '/' + id,
  company: (id: number | string) => privateRoutes.COMPANY_LIST_PATH + '/' + id,
  goToAdmin: () => window.open(env.ADMIN_URL + '/login'),
  editDepartments: () => privateRoutes.EDIT_DEPARTMENTS_PATH,
}
