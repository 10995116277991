import { CreateSalaryDTO, UpdateSalaryDTO } from '@api/generated-api'
import { ISalary } from '@interfaces/salary'
import { del, get, patch, post } from './axiosInstance'

export const getSalariesByEmployeeId = (employeeId: number) => get<Array<ISalary>>(`/salary/employee/${employeeId}`)

export const createSalary = (salary: CreateSalaryDTO) => post('/salary', salary)

export const updateSalary = (salaryId: number | string, salary: UpdateSalaryDTO) => patch(`/salary/${salaryId}`, salary)

export const deleteSalary = (salaryId: number) => del(`/salary/${salaryId}`)
