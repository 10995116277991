import { FC, InputHTMLAttributes } from 'react'
import 'twin.macro'

interface ICheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export const CheckBox: FC<ICheckBoxProps> = ({ id, label, ...inputProps }) => {
  return (
    <div className='form-check'>
      <input
        id={id}
        type='checkbox'
        className='form-check-input float-left mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none'
        {...inputProps}
      />
      {label && (
        <label htmlFor={id} className='form-check-label inline-block text-gray-800'>
          {label}
        </label>
      )}
    </div>
  )
}
