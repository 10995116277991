import { FC, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'
import 'twin.macro'
import { Skeleton } from '../Skeleton/Skeleton'

interface IComponentWrapper {
  loading?: boolean
  className?: string
  onClick?: (e: any) => void
}

export const ComponentWrapper: FC<PropsWithChildren<IComponentWrapper>> = ({
  loading,
  children,
  className,
  onClick,
}) => {
  return (
    <Skeleton showSkeleton={loading}>
      <div
        className={twMerge('mb-8 flex flex-col overflow-scroll rounded-md bg-white py-6 px-[15px]', className)}
        onClick={onClick}
      >
        {children}
      </div>
    </Skeleton>
  )
}
