import { FC, TextareaHTMLAttributes } from 'react'
import { ClassNameValue, twMerge } from 'tailwind-merge'

interface ITextAreaInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  containerTwStyle?: ClassNameValue
}

export const TextArea: FC<ITextAreaInputProps> = ({ label, id, containerTwStyle, ...textareaProps }) => {
  return (
    <div className={twMerge('form-floating mb-3 xl:w-96', containerTwStyle)}>
      <textarea
        className='
      form-control
      m-0
      block
      w-full
      rounded
      border
      border-solid
      border-gray-300
      bg-white bg-clip-padding
      px-3 py-1.5 text-base
      font-normal
      text-gray-700
      transition
      ease-in-out
      focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none
    '
        id={id}
        rows={3}
        placeholder={label}
        {...textareaProps}
      ></textarea>
      {label && (
        <label htmlFor={id} className='form-label mb-2 inline-block text-gray-700'>
          {label}
        </label>
      )}
    </div>
  )
}
