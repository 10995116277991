import { createPl } from '@api/pl'
import { PLForm } from '@components/forms'
import { ComponentWrapper } from '@components/ui'
import { usePl } from '@hooks/api/pl'
import { ICreatePl } from '@interfaces/pl'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

export const PLMonthView = () => {
  const {
    filter: { year, month },
  } = useGlobalFilter()
  const date = new Date(year, month?.[0] - 1)
  const { data: plData, isLoading: isFetchLoading, refetch: refetchPls } = usePl(dayjs(date).format('YYYY-MM-15'))
  const { mutate: updatePl, isPending: isPlUpdateLoading } = useMutation({
    mutationFn: (pl: Omit<ICreatePl, 'date'>) => createPl({ ...pl, date: dayjs(date).format('YYYY-MM-15') }),
  })

  const initialData = plData?.data

  return (
    <ComponentWrapper>
      {!isFetchLoading && (
        <PLForm onUpdate={updatePl} isLoading={isPlUpdateLoading || isFetchLoading} initialData={initialData!} />
      )}
    </ComponentWrapper>
  )
}
