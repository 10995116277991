import { CreateSalaryDTO, UpdateSalaryDTO } from '@api/generated-api'
import assets from '@assets/assets'
import { Modal } from '@components/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { CreateExpenseForm } from '../../../components/Forms/Form/CreateExpenseForm'

interface ICreateExpenseModalProps {
  isOpen: boolean
  close: () => void
  createExpense: (expense: CreateSalaryDTO | UpdateSalaryDTO) => void
  initialValues?: UpdateSalaryDTO
  employeeArrival: string
}

export const CreateExpenseModal: FC<ICreateExpenseModalProps> = ({
  isOpen,
  close,
  createExpense,
  initialValues,
  employeeArrival,
}) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} close={close}>
      <div tw='relative flex flex-col rounded-md border bg-white py-8 shadow-sm'>
        <div onClick={close} tw='absolute top-4 right-5 cursor-pointer text-lg text-icons-dark'>
          <img alt={'close'} src={assets.XIcon} />
        </div>
        <h2 tw='mb-4 block px-10 text-icons-dark text-2.5xl'>
          {initialValues ? t('employees.edit_expense') : t('employees.add_expense')}
        </h2>
        <section tw='overflow-scroll px-10'>
          <CreateExpenseForm
            onCreate={createExpense}
            close={close}
            initialValues={initialValues}
            employeeArrival={employeeArrival}
          />
        </section>
      </div>
    </Modal>
  )
}
