import { useDashEmployeeAndClient, useDashIncomeAndCosts } from '@hooks/api/dash'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useCurrency } from '@stores/currency.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'
import { DashboardBarCard } from './DashboardBarCard'
import { DashboardLineCard } from './DashboardLineCard'

export const DashboardHeader = () => {
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { currency, conversionRate } = useCurrency()

  const { data: incomeAndCostsData } = useDashIncomeAndCosts()
  const { data: employeeAndClientData } = useDashEmployeeAndClient(currency)

  const { incomes, expenses, profit, incomeStats, expenseStats, profitStats } = incomeAndCostsData?.data ?? {}
  const { clients, employees, totalClients, totalEmployees, employeeDiff } = employeeAndClientData?.data ?? {}

  return (
    <PageHeader title={t('navigation.dashboard') ?? undefined}>
      <section tw='mb-10 grid grid-cols-1 gap-7 lg:grid-cols-3'>
        <DashboardLineCard
          title={t('shared.income')}
          // loading={isIncomeLoading}
          total={incomeStats?.total}
          change={incomeStats?.diff}
          labels={incomes?.map(i => i.month)}
          data={incomes?.map(i => i.amount * conversionRate)}
          descriptions={[
            {
              title: 'Prihod po klijentu',
              change: incomeStats?.perClientDiff ?? 0,
              total: formatCurrency(incomeStats?.perClient ?? 0),
            },
            {
              title: 'Prihod po zaposlenom',
              change: incomeStats?.perEmployeeDiff ?? 0,
              total: formatCurrency(incomeStats?.perEmployee ?? 0),
            },
          ]}
        />
        <DashboardLineCard
          title={t('shared.costs')}
          // loading={isIncomeLoading}
          total={expenseStats?.total ?? 0}
          change={expenseStats?.diff}
          labels={expenses?.map(i => i.month)}
          data={expenses?.map(i => i.amount * conversionRate)}
          descriptions={[
            {
              title: 'Troškovi plaće',
              change: expenseStats?.salaryDiff ?? 0,
              total: formatCurrency(expenseStats?.salary),
            },
            {
              title: 'Troškovi po zaposlenom',
              change: expenseStats?.perEmployeeDiff ?? 0,
              total: formatCurrency(expenseStats?.perEmployee ?? 0),
            },
          ]}
        />
        <DashboardLineCard
          title={t('shared.profit')}
          // loading={isIncomeLoading}
          total={profitStats?.total ?? 0}
          change={profitStats?.totalDiff}
          labels={profit?.map(i => i.month)}
          data={profit?.map(i => i.amount * conversionRate)}
          descriptions={[
            {
              title: 'Neto dobit',
              change: profitStats?.netoDiff ?? 0,
              total: formatCurrency(profitStats?.neto ?? 0),
            },
            {
              title: 'Neto dobit marža',
              change: profitStats?.netoMarginDiff ?? 0,
              total: formatPercentage(profitStats?.netoMargin ?? 0),
            },
          ]}
        />
      </section>
      <section tw='grid grid-cols-1 gap-7 lg:grid-cols-2'>
        <DashboardBarCard
          title={t('shared.employees')}
          total={totalEmployees ?? 0}
          change={Math.round(employeeDiff ?? 0)}
          labels={employees?.map(e => e.department)}
          data={employees?.map(e => e.employees)}
        />
        <DashboardBarCard
          title={t('shared.clients')}
          total={totalClients ?? 0}
          change={Math.round(0)}
          labels={clients?.map(c => c.bracket)}
          data={clients?.map(c => c.count)}
          singleColor={true}
        />
      </section>
    </PageHeader>
  )
}
