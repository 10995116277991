import { z } from 'zod'

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === 'number') {
      return { message: 'Potrebno je unijeti iznos.' }
    } else if (issue.type === 'array') {
      return { message: '' }
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation === 'email') {
      return { message: 'Unesite ispravan email.' }
    }
  }
  if (issue.code === z.ZodIssueCode.not_multiple_of) {
    return { message: 'Unesite broj do dvije decimale.' }
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === 'string') {
      return { message: 'Obavezno!' }
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === 'number' && issue.received === 'nan') {
      return { message: 'Obavezno!' }
    }
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)
