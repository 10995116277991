import { CreateEmployeeDTO, UpdateEmployeeDTO } from '@api/generated-api'
import {
  ClientEmployees,
  EmployeeClientDepartments,
  EmployeeDetails,
  EmployeesSummary,
  EmployeeSummary,
  IEmployee,
} from '@interfaces/employee'
import { del, get, patch, post } from './axiosInstance'

export const employeesSummary = () => get<EmployeesSummary>('/stats/employee/summary')
export const employeesVacationSummary = () => get<any[]>('/stats/employee/vacation-hours')
export const employeesSickSummary = () => get<any[]>('/stats/employee/sick-hours')
export const employeesMaternitySummary = () => get<any[]>('/stats/employee/maternity-hours')

export const employeesClients = () => get<ClientEmployees>('/stats/employee/client-employees')

export const employeeClientDepartments = () => get<EmployeeClientDepartments>(`/stats/employee/client-departments`)

export const employeeSummary = (id: string) => get<EmployeeSummary>(`/stats/employee/${id}/summary`)

export const employeeDetails = (id: string) => get<EmployeeDetails>(`/stats/employee/${id}/details`)

export const createEmployee = (employee: CreateEmployeeDTO) => post('/employee', employee)

export const updateEmployee = (employeeId: number | string, employee: UpdateEmployeeDTO) =>
  patch(`/employee/${employeeId}`, employee)

export const deleteEmployee = (employeeId: number) => del(`/employee/${employeeId}`)

export const getEmployees = () => get<Array<IEmployee>>('/employee')

export const getEmployee = (id: number) => get<IEmployee>(`/employee/${id}`)
