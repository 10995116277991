import { FC, InputHTMLAttributes } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Switch from 'react-switch'
import 'twin.macro'

interface ISwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  id: string
  sublabel?: string | JSX.Element
}

export const SwitchComponent: FC<ISwitchProps> = ({ id, label, sublabel }) => {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className='mb-7 flex items-center gap-4'>
          <Switch
            id={id}
            name={id}
            width={45}
            onChange={val => {
              setValue(id, val, { shouldDirty: true, shouldTouch: true })
              onChange(val)
            }}
            checked={!!value}
            checkedIcon={false}
            handleDiameter={20}
            onColor={'#7EA1FF'}
            offColor={'#DEDEDE'}
            value={value}
            uncheckedIcon={false}
          />
          <div>
            {label && (
              <label htmlFor={id} className='text-base font-normal leading-snug text-neutral-800'>
                {label}
              </label>
            )}
            {sublabel && <div className='text-sm font-normal leading-tight text-zinc-500'>{sublabel}</div>}
          </div>
        </div>
      )}
    />
  )
}
