import { useState } from 'react'

interface IUseArrayOptions<T> {
  compare?: (a: T, b: T) => boolean
}

export const useArray = <T = any>(initial: T[], options?: IUseArrayOptions<T>) => {
  const { compare = (a: T, b: T) => a === b } = options ?? {}
  const [array, setArray] = useState<T[]>(initial)

  const push = (val: T) => {
    if (!array.includes(val)) {
      setArray(oldArray => [...oldArray, val])
    }
  }

  const pop = () => {
    if (array.length) {
      setArray(oldArray => oldArray.slice(0, oldArray.length - 1))
    }
  }

  const clearArray = () => {
    setArray([])
  }

  const findIndex = (predicate: (a: T) => boolean) => {
    return array.findIndex(predicate)
  }

  const find = (predicate: (a: T) => boolean) => {
    return array.find(predicate)
  }

  const removeIndex = (index: number) => {
    if (index < array.length) {
      setArray(oldArray => [...oldArray.slice(0, index), ...oldArray.slice(index + 1)])
    }
  }

  const updateIndex = (index: number, item: T) => {
    if (index < array.length) {
      setArray(oldArray => [...oldArray.slice(0, index), item, ...oldArray.slice(index + 1)])
    }
  }

  const removeItem = (item: T) => {
    setArray(oldArray => oldArray.filter(val => !compare(val, item)))
  }

  const isEmpty = () => array.length === 0

  return { array, push, pop, removeIndex, updateIndex, removeItem, isEmpty, find, findIndex, clearArray, setArray }
}
