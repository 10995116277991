import { GlobalFilter, useGlobalFilter } from '@stores/globalFilter.store'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface IPageHeaderProps {
  title?: string | null
  subtitle?: string | null
  extra?: any
  hidePeriod?: boolean
  rightContent?: JSX.Element
  topContent?: JSX.Element
}

const periodDescription = (filter: GlobalFilter): string => {
  const { year, quartal, month } = filter

  const quartals = quartal
    .sort((a, b) => a - b)
    .map(val => `Q${val}`)
    .join(', ')

  const months = month.length ? month.sort((a, b) => a - b).join('., ') + '. mjesec' : undefined

  return (months ?? quartals) + ' ' + year.toString()
}

export const PageHeader: FC<PropsWithChildren<IPageHeaderProps>> = ({
  title,
  subtitle,
  extra,
  children,
  hidePeriod,
  rightContent,
  topContent,
}) => {
  const { filter } = useGlobalFilter()
  const { t } = useTranslation()
  const TopContent = topContent
  const RightContent = rightContent || (
    <div tw='flex flex-col text-end'>
      <span tw='text-sm'>{t('page_header.period')}</span>
      <span>{periodDescription(filter)}</span>
    </div>
  )

  return (
    <div tw='bg-background py-8 px-7'>
      {topContent ?? TopContent}
      <div tw='mb-6 flex justify-between text-icons-dark text-[28px]'>
        <div tw='mr-3 flex flex-col justify-end'>
          <span tw='text-sm'>{subtitle}</span>
          <span>{title}</span>
          {extra}
        </div>
        {!hidePeriod && RightContent}
      </div>
      {children}
    </div>
  )
}
