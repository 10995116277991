import { Order } from '@interfaces/order'
import { useState } from 'react'

export const useOrder = (
  initialOrder: Order | null = null,
): [Order | null, (key?: string) => void, (a: any, b: any) => number] => {
  const [order, setOrderInternal] = useState<Order | null>(initialOrder)

  const setOrder = (orderKey?: string) => {
    let newOrder: Order | null = null
    if (!orderKey) return
    if (!order || order?.key != orderKey || order?.direction == 'ASC') {
      newOrder = { key: orderKey, direction: 'DESC' }
    }
    if (order?.key == orderKey && order?.direction == 'DESC') {
      newOrder = { key: orderKey, direction: 'ASC' }
    }
    setOrderInternal(newOrder)
    return
  }

  const orderData = (a: any, b: any) => {
    if (!order) return 0
    const aKey = a[order.key]
    const bKey = b[order.key]
    if (!aKey || !bKey) return 0

    if (typeof aKey == 'number') {
      return order.direction == 'ASC' ? aKey - bKey : bKey - aKey
    }
    if (typeof aKey == 'string') {
      return order.direction == 'ASC' ? (aKey as string)?.localeCompare(bKey) : (bKey as string)?.localeCompare(aKey)
    }
    if (Array.isArray(aKey)) {
      return order.direction == 'ASC'
        ? aKey
            .sort((a: string, b: string) => a?.localeCompare(b))?.[0]
            ?.localeCompare(bKey.sort((a: string, b: string) => a?.localeCompare(b))[0])
        : bKey
            .sort((a: string, b: string) => a?.localeCompare(b))?.[0]
            ?.localeCompare(aKey.sort((a: string, b: string) => a?.localeCompare(b))[0])
    }
    return order.direction == 'ASC' ? aKey - bKey : bKey - aKey
  }

  return [order, setOrder, orderData]
}
