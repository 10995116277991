import { Segmented } from '@components/inputs'
import { LegendDot, PercentageButton, Table } from '@components/ui'
import { useClientOverview } from '@hooks/api/client'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useMe } from '@stores/me.store'
import { ColorContext } from '@utils/colorContext'
import { formatPercentage } from '@utils/formatPercentage'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import 'twin.macro'
import tw from 'twin.macro'
import { DepartmentTableItem } from '../../../Departments/Components/DepartmentsTableItem'

const internalClients = ['Financije', 'Administracija', 'Management']

export const ClientDepartmentsTable = () => {
  const { me } = useMe()
  const { id } = useParams()
  const { t } = useTranslation()
  const [filter, setFilter] = useState('salary')
  const [showPercentage, setShowPercentage] = useState(false)
  const { array, push, removeItem } = useArray<string | number>([])
  const formatCurrency = useFormatCurrency()
  const { getColor } = useContext(ColorContext)

  const { data } = useClientOverview(id)

  let tableData = (data?.data ?? [])
    .map(val => ({
      ...val,
      key: val.id,
      children: (val?.children ?? [])
        .map(l => ({ ...l, key: l.id }))
        .sort((a: any, b: any) => b['income'] - a['income']),
    }))
    .sort((a: any, b: any) => b['income'] - a['income'])

  const [totalIncome, totalExpense, totalSalary, totalProfit, totalMargin] = tableData.reduce(
    (acc, k) => {
      return [acc[0] + k.income, acc[1] + k.expense, acc[2] + k.salary, acc[3] + k.profit, acc[4] + k.margin]
    },
    [0, 0, 0, 0, 0],
  )

  tableData = tableData.map(k => ({
    ...k,
    income_share: Math.round((k.income * 100) / totalIncome),
    expense_share: Math.round((k.expense * 100) / totalExpense),
    salary_share: Math.round((k.salary * 100) / totalSalary),
    profit_share: Math.round((k.profit * 100) / totalProfit),
    margin_share: Math.round((k.margin * 100) / totalMargin),
  }))

  const segmentOptions = [
    {
      label: t('shared.income'),
      value: 'income',
    },
    {
      label: t('shared.costs'),
      value: 'expense',
    },
    {
      label: t('shared.salary'),
      value: 'salary',
    },
    {
      label: t('shared.profit'),
      value: 'profit',
    },
    {
      label: t('shared.margin'),
      value: 'margin',
    },
  ]

  return (
    <>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-xl'>{t('clients.profit_overview_per_department')}</span>
        <div tw='flex items-center gap-2'>
          <PercentageButton showPercentage={showPercentage} setShowPercentage={() => setShowPercentage(p => !p)} />
          {me?.companySettings?.hasPL && (
            <Segmented options={segmentOptions} value={filter} onChange={val => setFilter(val)} />
          )}
        </div>
      </div>
      <div tw='mb-7 flex h-9 w-full items-center overflow-hidden rounded shadow'>
        <Tooltip id={'clients-tooltip'} />
        {tableData
          .map(v => v)
          .sort((a: any, b: any) => b[`${filter}_share`] - a[`${filter}_share`])
          .map(({ name, [`${filter}_share`]: share }: any) => (
            <div
              key={name}
              data-tooltip-id={'clients-tooltip'}
              data-tooltip-content={`${name} - ${share}%`}
              tw='flex h-full items-center text-icons-light text-sm transition-all duration-500'
              style={{ width: `${share}%`, background: getColor(name) }}
            >
              {share >= 4 && <span tw='pl-4'>{`${share}%`}</span>}
            </div>
          ))}
      </div>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('shared.department'),
              render: data => <LegendDot title={data.name} showDot={!data.isSub} />,
            },
            {
              key: 'income',
              dataIndex: 'income',
              decoration: tw`w-[200px]`,
              hidden: !me?.companySettings?.hasPL,
              title: t('shared.income'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey={'income'}
                  showPercentage={showPercentage}
                  formatValue={formatCurrency}
                />
              ),
            },
            {
              key: 'expense',
              dataIndex: 'expense',
              decoration: tw`w-[200px]`,
              hidden: !me?.companySettings?.hasPL,
              title: t('shared.costs'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey={'expense'}
                  showPercentage={showPercentage}
                  formatValue={formatCurrency}
                />
              ),
            },
            {
              key: 'salary',
              dataIndex: 'salary',
              decoration: tw`w-[200px]`,
              title: t('shared.salary'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey={'salary'}
                  showPercentage={showPercentage}
                  formatValue={formatCurrency}
                />
              ),
            },
            {
              key: 'profit',
              dataIndex: 'profit',
              hidden: !me?.companySettings?.hasPL,
              decoration: tw`w-[200px]`,
              title: t('shared.profit'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey={'profit'}
                  showPercentage={showPercentage}
                  formatValue={formatCurrency}
                />
              ),
            },
            {
              key: 'margin',
              dataIndex: 'margin',
              hidden: !me?.companySettings?.hasPL,
              decoration: tw`w-[200px]`,
              title: t('shared.margin'),
              render: data => (
                <DepartmentTableItem
                  data={data}
                  dataKey={'margin'}
                  showPercentage={showPercentage}
                  formatValue={formatPercentage}
                />
              ),
            },
          ]}
          data={[...tableData.filter(v => !internalClients.includes(v.name))]}
        />
      </div>
    </>
  )
}
