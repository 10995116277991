import { logout } from '@api/auth'
import { DepartmentDto } from '@api/generated-api'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type Me = {
  id: number
  email: string
  name: string
  companyId: number
  role: string
  employeeId: number
  isAdmin: boolean
  isSuperAdmin: boolean
  isManager: boolean
  companySettings: {
    id: number
    hasOvertime: boolean
    hasPL: boolean
    hasWFH: boolean
    overtimeMultiplier: number
  }
  departments: DepartmentDto[]
  company: { id: number; createdAt: string; updatedAt: string; deletedAt?: string; name: string }
  image: string
  isActive: boolean
  managerDepartmentIds: number[]
  username: string
}

interface IGlobalAuthStore {
  me?: Me
  setMe: (me: Me) => void
  clearMe: () => void
  logout: () => void
}

export const useMe = create(
  persist<IGlobalAuthStore>(
    (set, get) => ({
      me: undefined,
      setMe: (me: Me) => set(() => ({ me })),
      clearMe: () => set(() => ({ me: undefined })),
      logout: async () => {
        get().clearMe()
        await logout()
      },
    }),
    {
      name: 'me-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
