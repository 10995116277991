import { ComponentWrapper, LegendDot } from '@components/ui'
import { useDashDepartmentReview } from '@hooks/api/dash'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { SectionBar } from './SectionBar'

export const DashboardSectionOverview = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useDashDepartmentReview()

  const values = data?.data || []

  return (
    <ComponentWrapper loading={isLoading}>
      <span tw='mb-7 text-icons-dark text-2.5xl'>{t('dashboard.department_overview')}</span>
      <SectionBar
        title={t('shared.income')}
        values={values.map(val => ({ department: val.name, value: val.income }))}
      />
      <SectionBar
        title={t('shared.costs')}
        values={values.map(val => ({ department: val.name, value: val.expense }))}
      />
      <SectionBar
        title={t('shared.profit')}
        values={values.map(val => ({ department: val.name, value: val.profit }))}
      />
      <div tw='mr-7 mt-5 flex flex-wrap text-sm text-icons uppercase'>
        {values
          .filter(v => v.operational)
          .sort((a, b) => b.profit - a.profit)
          .map(({ name }) => (
            <LegendDot key={name} title={name} />
          ))}
      </div>
    </ComponentWrapper>
  )
}
