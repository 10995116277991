import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { EmployeeClients } from '../../../types'

export const useWorkingHoursEmployeeClients = () => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<EmployeeClients[]>, null, EmployeeClients[]>({
    queryKey: ['wh-employee-clients'],
    queryFn: workingHours.workingHoursControllerGetClients,
    select: data => data.data,
  })
}
