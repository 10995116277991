import { Segmented } from '@components/inputs'
import { CompareBarChart } from '@components/ui'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

export const MediaCompare = () => {
  const [filter, setFilter] = useState('PRIHODI')
  const {
    filter: { year },
  } = useGlobalFilter()
  const { t } = useTranslation()

  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const segmentOptions = [
    {
      label: t('shared.income'),
      value: 'income',
    },
    {
      label: t('shared.costs'),
      value: 'expense',
    },
    {
      label: t('shared.salary'),
      value: 'salary',
    },
    {
      label: t('shared.profit'),
      value: 'profit',
    },
    {
      label: t('shared.margin'),
      value: 'margin',
    },
  ]

  return (
    <>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'>{t('shared.income_sing') + ` ${year}. vs ${year - 1}.`}</span>
        <Segmented options={segmentOptions} value={filter} onChange={val => setFilter(val)} />
      </div>
      <div tw='h-48'>
        <CompareBarChart
          labels={labels}
          datasets={[
            { data: labels.map(_ => Math.round(Math.random() * 1000000)), title: year.toString() },
            { data: labels.map(_ => Math.round(Math.random() * 1000000)), title: (year - 1).toString() },
          ]}
        />
      </div>
    </>
  )
}
