import { axiosApiInstance } from '@api/axiosInstance'
import { UpdateWorkingHoursTypeDTO } from '@api/generated-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useWorkingHoursFunctions = () => {
  const { workingHours } = axiosApiInstance
  const queryClient = useQueryClient()
  const createWorkingHours = useMutation({
    mutationFn: workingHours.workingHoursControllerAddWorkingHour,
  })
  const updateWorkingHours = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateWorkingHoursTypeDTO }) =>
      workingHours.workingHoursControllerUpdateWorkingHour(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] })
    },
  })
  const deleteWorkingHours = useMutation({
    mutationFn: workingHours.workingHoursControllerDeleteWorkingHour,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] })
    },
  })
  const sendToManager = useMutation({
    mutationFn: workingHours.workingHoursControllerSendStatusToManager,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] })
    },
  })
  return {
    createWorkingHours,
    updateWorkingHours,
    deleteWorkingHours,
    sendToManager,
  }
}
