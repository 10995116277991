import { WorkingHoursMonthDto } from '@api/generated-api'
import { Table } from '@components/ui'
import { useOrder } from '@hooks/useOrder'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import 'twin.macro'
import tw from 'twin.macro'
import { EditDeleteRender } from '../../../../components/Renders/EditDeleteRender'
import { ClientRenderer } from './renderers/ClientRenderer'
import { HoursRenderer } from './renderers/HoursRenderer'
import { IEditColumnProps } from './WorkingHoursMonthContent'

export const WorkingHoursTable = ({
  data,
  availableHours,
  onDeleteRow,
  isEditable,
  notEnteredHours,
  onEditColumn,
}: {
  data: WorkingHoursMonthDto[]
  notEnteredHours: number
  availableHours: number
  onDeleteRow: (id: number) => void
  isEditable: boolean
  onEditColumn: ({ change, whId, column }: IEditColumnProps) => void
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [order, setOrder, orderData] = useOrder({ key: 'name', direction: 'ASC' })
  const filteredWh = data.filter(d => Number(d.hours) >= 0)

  return (
    <Table
      order={order}
      pagination={{
        currentPage: page,
        onPageChange: setPage,
        perPage: 10,
        total: data.length,
      }}
      columns={[
        {
          key: 'type',
          dataIndex: 'type',
          title: t('working_hours.entry_type'),
          order: setOrder,
          render: data => (
            <div className={'flex items-center space-x-2'}>
              <div
                className={twMerge(
                  'h-5 w-5 rounded-full',
                  data['type'] === 'OVERTIME' ? 'bg-status-over-hours' : 'bg-status-primary-hours',
                )}
              />
              <div>{t(`working_hours.type.${(data['type'] || '').toLowerCase()}`)}</div>
            </div>
          ),
        },
        {
          key: 'name',
          dataIndex: 'name',
          title: t('working_hours.client'),
          decoration: tw`w-[250px]`,
          rowDecoration: tw`h-[40px]`,
          order: setOrder,
          render: data => (
            <ClientRenderer
              rowData={data}
              currentValue={data['name']}
              onEditColumn={onEditColumn}
              isEditable={isEditable}
            />
          ),
        },
        {
          key: 'hours',
          dataIndex: 'hours',
          title: t('working_hours.hours'),
          decoration: tw`w-[220px]`,
          order: setOrder,
          render: data => (
            <HoursRenderer
              rowData={data}
              notEnteredHours={notEnteredHours}
              availableHours={availableHours}
              currentValue={data['hours']}
              onEditColumn={onEditColumn}
              isEditable={isEditable}
            />
          ),
        },
        {
          key: 'percentage',
          dataIndex: 'percentage',
          title: t('working_hours.percentage'),
          decoration: tw`w-[220px]`,
          order: setOrder,
          render: data => <>{((Number(data['hours']) / availableHours) * 100).toFixed(1)}%</>,
        },
        {
          key: 'trash',
          dataIndex: 'trash',
          hidden: !isEditable,
          title: '',
          decoration: isEditable ? tw`w-[60px]` : tw`hidden`,
          render: data => <EditDeleteRender onDelete={() => onDeleteRow(data['id'])} />,
        },
      ]}
      data={filteredWh.map(d => ({ ...d, key: d.id })).sort(orderData)}
    />
  )
}
