import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const useGetActiveWorkingHours = () => {
  const { workingHours } = axiosApiInstance
  return useQuery({
    queryKey: ['working-hours', 'active'],
    queryFn: workingHours.workingHoursControllerGetActiveMonths,
    select: data => data.data,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    refetchInterval: 0,
  })
}
