import { PageContent } from '@components/ui'
import { EmployeesHeader } from './Components/EmployeesHeader'
import { EmployeesTabs } from './Components/EmployeesTabs'

export const Employees = () => {
  return (
    <>
      <EmployeesHeader />
      <PageContent>
        <EmployeesTabs />
      </PageContent>
    </>
  )
}
