import { getPl } from '@api/pl'
import { useQuery } from '@tanstack/react-query'

export const usePl = (date?: string) =>
  useQuery({
    queryKey: ['pl', date],
    queryFn: () => getPl(date!),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    gcTime: 0,
    refetchInterval: 0,
    enabled: !!date,
  })
