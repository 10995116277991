import { UpdateDepartmentDTO } from '@api/generated-api'
import { TextInput } from '@components/inputs'
import { Button, Tag } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface IUpdateDepartmentFormProps {
  onUpdate: (department: UpdateDepartmentDTO) => void
  initialValue?: UpdateDepartmentDTO
  isSub?: boolean
  close?: any
}

const UpdateDepartmentSchema = z.object({
  id: z.number(),
  name: z.string().min(1, 'Required'),
  parentId: z.number().nullable().optional(),
  operational: z.boolean(),
  support: z.boolean(),
  channels: z.boolean().optional(),
})

export const UpdateDepartmentForm: FC<IUpdateDepartmentFormProps> = ({
  onUpdate,
  initialValue,
  isSub = false,
  close,
}) => {
  const methods = useForm<UpdateDepartmentDTO>({
    defaultValues: {
      ...initialValue,
      parentId: initialValue?.parent,
    },
    resolver: zodResolver(UpdateDepartmentSchema),
  })
  const { t } = useTranslation()

  const [operational, support] = methods.watch(['operational', 'support'])
  console.log({ a: methods.formState.errors })
  return (
    <Form methods={methods} onSubmit={onUpdate}>
      <div className={'gap-4'}>
        <TextInput id='name' label={t('shared.name')} />
        {!isSub && (
          <div className={'flex gap-4'}>
            <Tag
              key={t('departments.operative')}
              onChange={() => methods.setValue('operational', !operational)}
              title={t('departments.operative')}
              checked={operational}
            >
              {t('departments.operative')}
            </Tag>
            <Tag
              checked={support}
              key={t('departments.support')}
              onChange={() => methods.setValue('support', !support)}
              title={t('departments.support')}
            >
              {t('departments.support')}
            </Tag>
          </div>
        )}
        <section tw='mt-8 flex justify-between gap-4'>
          <Button buttonType={'secondary'} onClick={close}>
            {t('shared.give_up')}
          </Button>
          <Button type='submit'>{t('shared.edit')}</Button>
        </section>
      </div>
    </Form>
  )
}
