interface IEditDeleteRenderProps {
  onEdit?: () => void
  onDelete?: () => void
}

export const EditDeleteRender = ({ onEdit, onDelete }: IEditDeleteRenderProps) => {
  return (
    <div className={'mr-4 flex items-center justify-end gap-2 opacity-0 transition-all group-hover/row:opacity-100'}>
      {!!onEdit && (
        <div
          className={'relative cursor-pointer text-zinc-400 transition-colors hover:text-zinc-600'}
          onClick={e => {
            e.stopPropagation()
            onEdit()
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
            <path d='M21.3103 6.87817L17.1216 2.68848C16.9823 2.54916 16.8169 2.43864 16.6349 2.36324C16.4529 2.28783 16.2578 2.24902 16.0608 2.24902C15.8638 2.24902 15.6687 2.28783 15.4867 2.36324C15.3047 2.43864 15.1393 2.54916 15 2.68848L3.43969 14.2497C3.2998 14.3885 3.18889 14.5537 3.11341 14.7357C3.03792 14.9178 2.99938 15.113 3.00001 15.31V19.4997C3.00001 19.8976 3.15804 20.2791 3.43935 20.5604C3.72065 20.8417 4.10218 20.9997 4.50001 20.9997H8.6897C8.88675 21.0004 9.08197 20.9618 9.26399 20.8863C9.44602 20.8109 9.61122 20.6999 9.75001 20.56L21.3103 8.99973C21.4496 8.86044 21.5602 8.69507 21.6356 8.51306C21.711 8.33105 21.7498 8.13596 21.7498 7.93895C21.7498 7.74194 21.711 7.54686 21.6356 7.36485C21.5602 7.18284 21.4496 7.01746 21.3103 6.87817ZM18 10.1885L13.8103 5.99973L16.0603 3.74973L20.25 7.93848L18 10.1885Z' />
          </svg>
        </div>
      )}
      {!!onDelete && (
        <div
          className={'relative cursor-pointer text-zinc-400 transition-colors hover:text-zinc-600'}
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
            <path d='M19.2107 3.6837C19.5002 3.6837 19.7896 3.97317 19.7896 4.26264V5.42054C19.7896 5.7462 19.5002 5.99949 19.2107 5.99949H4.15805C3.83239 5.99949 3.5791 5.7462 3.5791 5.42054V4.26264C3.5791 3.97317 3.83239 3.6837 4.15805 3.6837H8.50015L8.82581 3.03238C8.93436 2.74291 9.29621 2.5258 9.58568 2.5258H13.7469C14.0363 2.5258 14.362 2.74291 14.5067 3.03238L14.8686 3.6837H19.2107ZM5.49686 19.4238L4.737 7.15738H18.6317L17.8357 19.4238C17.7995 20.3284 17.0034 21.0521 16.0988 21.0521H7.23371C6.3291 21.0521 5.53305 20.3284 5.49686 19.4238Z' />
          </svg>
        </div>
      )}
    </div>
  )
}
