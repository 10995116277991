import { useWorkingHoursManagementFunctions } from '@hooks/api/working-hours-management/useWorkingHoursManagementFunctions'
import { memo, useCallback, useState } from 'react'
import { NotificationModal } from '../../../WorkingHours/Components/NotificationModal'

export const SendReminderRenderer = memo(({ data }: { data: any }) => {
  const [isSendReminderModalOpen, setIsSendReminderModalOpen] = useState(false)
  const {
    sendWorkingHoursReminders: { mutateAsync: sendWorkingHoursReminders },
  } = useWorkingHoursManagementFunctions()

  const handleApprove = useCallback(async () => {
    await sendWorkingHoursReminders({ data: { ids: [data?.id] } })
    setIsSendReminderModalOpen(false)
  }, [data?.id, sendWorkingHoursReminders])

  return (
    <>
      <div className={'flex gap-2 opacity-0 transition-all group-hover/row:opacity-100'}>
        <div className={'cursor-pointer'} onClick={() => setIsSendReminderModalOpen(true)}>
          <svg
            width='25'
            height='25'
            viewBox='0 0 25 25'
            className={'fill-current text-gray-400 transition-colors hover:text-zinc-600'}
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_2484_12429)'>
              <path d='M16.4063 21.875C16.4063 22.0822 16.324 22.2809 16.1775 22.4274C16.0309 22.5739 15.8322 22.6562 15.625 22.6562H9.37503C9.16783 22.6562 8.96911 22.5739 8.8226 22.4274C8.67609 22.2809 8.59378 22.0822 8.59378 21.875C8.59378 21.6678 8.67609 21.4691 8.8226 21.3226C8.96911 21.1761 9.16783 21.0938 9.37503 21.0938H15.625C15.8322 21.0938 16.0309 21.1761 16.1775 21.3226C16.324 21.4691 16.4063 21.6678 16.4063 21.875ZM21.6612 17.1816C21.1182 16.248 20.3125 13.6064 20.3125 10.1562C20.3125 8.08425 19.4894 6.09711 18.0243 4.63198C16.5592 3.16685 14.572 2.34375 12.5 2.34375C10.428 2.34375 8.44088 3.16685 6.97576 4.63198C5.51063 6.09711 4.68753 8.08425 4.68753 10.1562C4.68753 13.6074 3.88089 16.248 3.33889 17.1816C3.20049 17.419 3.12711 17.6886 3.12617 17.9634C3.12523 18.2381 3.19675 18.5083 3.33352 18.7466C3.4703 18.9849 3.66749 19.1829 3.90521 19.3206C4.14293 19.4584 4.41277 19.5311 4.68753 19.5312H20.3125C20.5872 19.5309 20.8569 19.4581 21.0945 19.3203C21.3321 19.1824 21.5291 18.9844 21.6658 18.7462C21.8025 18.5079 21.8739 18.2378 21.8729 17.9631C21.8719 17.6885 21.7986 17.4189 21.6602 17.1816H21.6612Z' />
            </g>
            <defs>
              <clipPath id='clip0_2484_12429'>
                <rect width='25' height='25' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <NotificationModal
        key={'send_reminders'}
        isOpen={isSendReminderModalOpen}
        onClose={() => setIsSendReminderModalOpen(false)}
        title={'Pošalji podsjetnik'}
        subtitle={`Jeste li sigurni da želite poslati podsjetnik zaposleniku ${data['name']}. Potvrđivanjem ove radnje podsjetnik će biti poslan putem maila.`}
        primaryButtonText={'Pošalji'}
        secondaryButtonText={'Odustani'}
        onPrimaryClick={handleApprove}
        onSecondaryPress={() => setIsSendReminderModalOpen(false)}
      />
    </>
  )
})
