import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IWorkingHoursActiveTableData } from '../../../types'

export const useGetClientWorkingHoursManagement = ({ departmentId, date }: { departmentId: number; date: string }) => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<IWorkingHoursActiveTableData[]>, null, IWorkingHoursActiveTableData[]>({
    queryKey: ['working-hours', 'management', 'clients', departmentId, date],
    queryFn: () => workingHours.workingHoursControllerGetManagerClients({ departmentId, date }),
    select: data => data.data,
    enabled: !!departmentId && !!date,
  })
}
