import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IWorkingHoursActiveTableData } from '../../../types'

export const useGetNotSentWorkingHoursManagement = ({ departmentId }: { departmentId: number }) => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<IWorkingHoursActiveTableData[]>, null, IWorkingHoursActiveTableData[]>({
    queryKey: ['working-hours', 'management', 'not-sent', departmentId],
    queryFn: () => workingHours.workingHoursControllerGetNotSentMonthsManager(departmentId),
    select: data => data.data,
    enabled: !!departmentId,
  })
}
