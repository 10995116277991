import { PageContent } from '@components/ui'
import { IncomeAndCostsHeader } from './Components/IncomeAndCostsHeader'
import { IncomeAndCostTable } from './Components/IncomeAndCostTable'

export const IncomeAndCosts = () => {
  return (
    <>
      <IncomeAndCostsHeader />
      <PageContent>
        <IncomeAndCostTable />
      </PageContent>
    </>
  )
}
