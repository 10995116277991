import { NonWorkingHoursStatusDto, WorkingHoursMonthDto, WorkingHoursMonthDtoTypeEnum } from '@api/generated-api'
import { useMemo } from 'react'
import { Tooltip } from 'react-tooltip'
import { NonWorkingHoursTypeEnum, WorkingHoursTypeEnum } from '../../../../types'
import { colorsByType } from './WorkingHoursMonthContent'

interface IWorkingHoursLineProps {
  totalHours: number
  availableHours: number
  allData: (WorkingHoursMonthDto | NonWorkingHoursStatusDto)[]
  notEnteredHours: number
  hoursByDepartment: {
    hours: number
    department: { id: number; name: string }
  }[]
}

export const WorkingHoursLine = ({
  totalHours,
  availableHours,
  allData,
  notEnteredHours,
  hoursByDepartment,
}: IWorkingHoursLineProps) => {
  const tempNonWorkingHours = (allData || []).filter(ad => Object.values(NonWorkingHoursTypeEnum).includes(ad.type))
  const workingHoursLine = useMemo(
    () =>
      [
        {
          type: WorkingHoursTypeEnum.NORMAL,
          hours: allData
            .filter(
              d =>
                d.type !== WorkingHoursMonthDtoTypeEnum.OVERTIME &&
                [WorkingHoursTypeEnum.WFH, WorkingHoursTypeEnum.NORMAL, WorkingHoursTypeEnum.REST].includes(d?.type),
            )
            .reduce((previousValue, currentValue) => previousValue + Number(currentValue.hours), 0),
        },
        {
          type: 'NON_WORKING',
          hours: tempNonWorkingHours.reduce(
            (previousValue, currentValue) => previousValue + Number(currentValue.hours),
            0,
          ),
        },
        {
          type: WorkingHoursTypeEnum.OVERTIME,
          hours: allData
            .filter(d => d.type === WorkingHoursMonthDtoTypeEnum.OVERTIME)
            .reduce((previousValue, currentValue) => previousValue + Number(currentValue.hours), 0),
        },
        { type: 'NO_ENTRY', hours: notEnteredHours },
      ].filter(wh => (wh?.hours || 0) > 0),
    [allData, notEnteredHours, tempNonWorkingHours],
  )

  return (
    <div>
      <div className={'mb-2 flex items-center justify-between'}>
        <div className={'text-[20px]'}>
          {totalHours}/{availableHours}{' '}
          <span className={'text-sm font-normal text-gray-600'}>
            {(hoursByDepartment || []).length > 1
              ? `(${hoursByDepartment.map(hd => `${hd.hours} ${hd.department.name}`).join(', ')})`
              : 'radnih sati'}
          </span>
        </div>
        <div>{((totalHours / (availableHours || 0)) * 100).toFixed(0)}%</div>
      </div>
      <Tooltip id={'state-tooltip'} />
      <div className={'flex w-full gap-1'}>
        {workingHoursLine.map((p, index, array) => {
          const isFirst = index === 0
          const isLast = index === array.length - 1
          // @ts-ignore
          const color = colorsByType[p.type]
          const borderTopLeftRadius = isFirst ? 4 : 0
          const borderTopRightRadius = isLast ? 4 : 0
          const borderBottomLeftRadius = isFirst ? 4 : 0
          const borderBottomRightRadius = isLast ? 4 : 0
          return (
            <div
              data-tooltip-id='state-tooltip'
              data-tooltip-content={p.hours.toString()}
              key={p.hours}
              style={{
                backgroundColor: color,
                height: 24,
                flex: p.hours / (availableHours || 0),
                borderTopLeftRadius,
                borderTopRightRadius,
                borderBottomRightRadius,
                borderBottomLeftRadius,
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
