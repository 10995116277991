import { createExpenses } from '@api/expense'
import { createIncomes } from '@api/income'
import { ICreateExpenses } from '@interfaces/expense'
import { ICreateIncomes } from '@interfaces/income'
import { IIncomeExpense } from '@interfaces/incomeexpense'
import { formatCurrency, parseCurrency } from '@utils/formatCurrency'
import { parseIncomeAndCostFile } from '@utils/parseFile'
import { ChangeEvent } from 'react'

export type IncomeRow = {
  key: string
  client: string
  [key: string]: string
}

export const importFile = async (
  e: ChangeEvent<HTMLInputElement>,
  addRow: (val: IncomeRow) => void,
  clearArray: () => void,
) => {
  if (e.currentTarget.files) {
    clearArray()
    const res = await parseIncomeAndCostFile(e.currentTarget.files[0])
    let departments = []
    const depId = res.findIndex(row => row.includes('ODJEL'))
    if (depId == -1) {
      throw new Error('Krivi format datoteke!')
    }
    departments = depId != -1 ? res[depId] : []
    for (let i = depId + 1; i < res.length; i++) {
      const row = res[i]
      if (!row || row.includes('Naziv klijenta') || row.includes('SUM')) {
        continue
      }
      const newRow: any = { key: row[0], client: row[0] }
      for (let j = 1; j < row.length; j++) {
        const dep = departments[j] as any
        if (!dep || ['Naziv klijenta', 'ODJEL'].includes(dep as string)) {
          continue
        }
        if (`${dep.toString()}` in newRow) {
          newRow[`${dep.toString()} - trošak`] = row[j]
        } else {
          newRow[`${dep.toString()}`] = row[j]
        }
      }
      addRow(newRow)
    }
  }
  return []
}

export const importData = async (rows: Array<IncomeRow>, date: string) => {
  const incomeData: ICreateIncomes = { date, amounts: [] }
  const expenseData: ICreateExpenses = { date, amounts: [] }
  rows.forEach(row => {
    incomeData.amounts.push({
      client: row.client,
      amounts: Object.keys(row)
        .filter(
          key =>
            !['client', 'key'].includes(key.toLowerCase()) &&
            !key.toLowerCase().includes('trošak') &&
            !key.toLowerCase().includes('ukupno') &&
            !!row[key],
        )
        .map(key => ({
          department: key,
          amount: parseCurrency(row[key]),
        })),
    })
    expenseData.amounts.push({
      client: row.client,
      amounts: Object.keys(row)
        .filter(key => !['client', 'key'].includes(key) && key.toLowerCase().includes('trošak') && !!row[key])
        .map(key => ({
          department: key.replace(' - trošak', ''),
          amount: parseCurrency(row[key]),
        })),
    })
  })

  incomeData.amounts = incomeData.amounts.filter(id => id.amounts.length)
  expenseData.amounts = expenseData.amounts.filter(ed => ed.amounts.length)

  return Promise.all([createIncomes(incomeData), createExpenses(expenseData)])
}

export const updateRowsWithExistingData = (
  setRows: (val: IncomeRow[]) => void,
  clearArray: () => void,
  incomeExpenses?: IIncomeExpense,
) => {
  clearArray()

  if (!incomeExpenses) {
    return
  }

  let totalIncome = 0,
    totalExpense = 0

  const { incomes, expenses, clients, departments } = incomeExpenses

  const departmentRow = {
    ...departments.filter(d => d != 'Ostali troškovi').reduce((acc, d) => ({ ...acc, [`${d}`]: '' }), {}),
    'Ukupno prihodi': '',
    ...departments.filter(d => d != 'Ostali prihodi').reduce((acc, d) => ({ ...acc, [`${d} - trošak`]: '' }), {}),
    'Ukupno troškovi': '',
  }

  const rows: IncomeRow[] = clients.map(c => ({ client: c.name, key: c.name, ...departmentRow }))
  const existingClients = [...new Set([...incomes.map(i => i.client.name), ...expenses.map(e => e.client.name)])]

  const rowsByClient = [
    ...rows.filter(r => existingClients.includes(r.client)).sort((a, b) => a.client.localeCompare(b.client)),
    ...rows.filter(r => !existingClients.includes(r.client)).sort((a, b) => a.client.localeCompare(b.client)),
  ]
  incomes.forEach(income => {
    const idx = rowsByClient.findIndex(row => row.key == income.client.name)
    if (idx != -1) {
      rowsByClient[idx][income.department.name!] = formatCurrency(income.amount)
    } else {
      rowsByClient.push({
        key: income.client.name!,
        client: income.client.name!,
        [income.department.name!]: formatCurrency(income.amount),
      })
    }
  })
  rowsByClient.forEach((row, idx) => {
    const tempTotal = Object.keys(row).filter(
      key =>
        !['client', 'key'].includes(key) &&
        !key.toLowerCase().includes('trošak') &&
        !key.toLowerCase().includes('fortenova') &&
        !key.toLowerCase().includes('ukupno') &&
        key.toLowerCase() !== 'mediji',
    )

    const total = tempTotal.reduce((acc, key) => {
      return acc + parseCurrency(row[key])
    }, 0)
    rowsByClient[idx]['Ukupno prihodi'] = formatCurrency(total)
    totalIncome += total
  })

  expenses.forEach(expense => {
    const idx = rowsByClient.findIndex(row => row.key == expense.client.name)
    if (idx != -1) {
      rowsByClient[idx][expense.department.name! + ' - trošak'] = formatCurrency(expense.amount)
    } else {
      rowsByClient.push({
        key: expense.client.name!,
        client: expense.client.name!,
        [expense.department.name! + ' - trošak']: formatCurrency(expense.amount),
      })
    }
  })
  rowsByClient.forEach((row, idx) => {
    const total = Object.keys(row)
      .filter(
        key =>
          !['client', 'key'].includes(key) &&
          key.toLowerCase().includes('trošak') &&
          !key.toLowerCase().includes('fortenova') &&
          key.toLowerCase() !== 'mediji - trošak',
      )
      .reduce((acc, key) => acc + parseCurrency(row[key]), 0)

    rowsByClient[idx]['Ukupno troškovi'] = formatCurrency(total)
    totalExpense += total
  })

  setRows(rowsByClient)
}
