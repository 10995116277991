import tw, { TwStyle } from 'twin.macro'

const containerStyle: { [key: string]: TwStyle } = {
  top: tw`left-0 top-0 w-full`,
  bottom: tw`left-0 bottom-0 w-full`,
  left: tw`left-0 top-0 h-full`,
  right: tw`right-0 top-0 h-full`,
}
const closeIconStyle: { [key: string]: TwStyle } = {
  top: tw`top-2 right-3`,
  bottom: tw`top-2 right-3`,
  left: tw`top-2 right-3`,
  right: tw`top-6 right-8`,
}

const getStyle = (type: string, placement: string): any => {
  switch (type) {
    case 'container':
      return containerStyle[placement]
    case 'icon':
      return closeIconStyle[placement]
    default:
      return undefined
  }
}

export type Placement = 'top' | 'bottom' | 'left' | 'right'

export const getPlacementStyle = (placement: Placement, type: 'container' | 'icon') => {
  switch (placement) {
    case 'top':
      return getStyle(type, 'top')
    case 'bottom':
      return getStyle(type, 'bottom')
    case 'left':
      return getStyle(type, 'left')
    case 'right':
      return getStyle(type, 'right')
    default:
      return getStyle(type, 'left')
  }
}

export const getClosedPlacementStyle = (placement: Placement) => {
  switch (placement) {
    case 'top':
      return tw`-top-full`
    case 'bottom':
      return tw`-bottom-full`
    case 'left':
      return tw`-left-full`
    case 'right':
      return tw`-right-full`
    default:
      return tw`-left-full`
  }
}
