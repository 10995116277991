import { Header, Navigation } from '@components/ui'
import {
  Account,
  Client,
  Clients,
  Company,
  Dashboard,
  Departments,
  Employee,
  Employees,
  FAQ,
  Media,
  Medias,
  WorkingHours,
} from '@pages'
import { useMe } from '@stores/me.store'
import { ColorContext } from '@utils/colorContext'
import { useColors } from '@utils/randomColor'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'twin.macro'
import { EditDepartments } from '../pages/EditDepartments/EditDepartments'
import { IncomeAndCosts } from '../pages/IncomeAndCosts/IncomeAndCosts'
import { PL } from '../pages/PL/PL'
import { WorkingHoursMonth } from '../pages/WorkingHours/Month/WorkingHoursMonth'
import { WorkingHoursManagement } from '../pages/WorkingHoursManagement/WorkingHoursManagement'
import { privateRoutes } from './routes'

export const AdminRouter = () => {
  const colors = useColors()
  const { me } = useMe()
  return (
    <ColorContext.Provider value={colors as any}>
      <div tw='flex overflow-hidden'>
        <Navigation />
        <div tw='flex h-screen w-full flex-col sm:ml-20'>
          <Header />
          <div tw='h-full overflow-scroll bg-background-light bg-slate-50'>
            <Routes>
              {me?.companySettings?.hasPL && <Route path={privateRoutes.DASHBOARD_PATH} element={<Dashboard />} />}
              <Route path={privateRoutes.CLIENTS_PATH} element={<Clients />} />
              <Route path={privateRoutes.CLIENT_PATH} element={<Client />} />
              <Route path={privateRoutes.DEPARTMENTS_PATH} element={<Departments />} />
              <Route path={privateRoutes.EDIT_DEPARTMENTS_PATH} element={<EditDepartments />} />
              <Route path={privateRoutes.EMPLOYEES_PATH} element={<Employees />} />
              <Route path={privateRoutes.EMPLOYEE_PATH} element={<Employee />} />
              <Route path={privateRoutes.WORKING_HOURS} element={<WorkingHours />} />
              <Route path={privateRoutes.WORKING_HOURS_MONTH} element={<WorkingHoursMonth />} />
              <Route path={privateRoutes.WORKING_HOURS_MANAGEMENT} element={<WorkingHoursManagement />} />
              <Route path={privateRoutes.MEDIAS_PATH} element={<Medias />} />
              <Route path={privateRoutes.MEDIA_PATH} element={<Media />} />
              <Route path={privateRoutes.COMPANY_PATH} element={<Company />} />
              <Route path={privateRoutes.INCOME_AND_COSTS_PATH} element={<IncomeAndCosts />} />
              <Route path={privateRoutes.P_AND_L_PATH} element={<PL />} />
              <Route path={privateRoutes.FAQ_PATH} element={<FAQ />} />
              <Route path={privateRoutes.ACCOUNT_PATH} element={<Account />} />
              <Route path={privateRoutes.NOT_FOUND_PATH} element={<Navigate to={privateRoutes.CLIENTS_PATH} />} />
              <Route path={privateRoutes.EMPTY} element={<Navigate to={privateRoutes.CLIENTS_PATH} />} />
            </Routes>
          </div>
        </div>
      </div>
    </ColorContext.Provider>
  )
}
