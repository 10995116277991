import { PageContent } from '@components/ui'
import { DepartmentsHeader } from './Components/DepartmentsHeader'
import { DepartmentsList } from './Components/DepartmentsList'

export const EditDepartments = () => {
  return (
    <>
      <DepartmentsHeader />
      <PageContent>
        <DepartmentsList />
      </PageContent>
    </>
  )
}
