import assets from '@assets/assets'
import { useOutsideClick } from '@hooks/useOutsideClick'
import dayjs from 'dayjs'
import { FC, InputHTMLAttributes, useCallback, useRef, useState } from 'react'
import Calendar, { CalendarProps } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

interface IDatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label?: string
  selectDays?: boolean
  calendarProps?: CalendarProps
}

export const DatePicker: FC<IDatePickerProps> = ({ id, label, calendarProps, selectDays, ...inputProps }) => {
  const formContext = useFormContext()
  const errorString = get(formContext?.formState?.errors, id)
  const inputRef = useRef(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const value = formContext.watch(id)
  const { t } = useTranslation()
  useOutsideClick(inputRef, () => {
    setIsCalendarOpen(false)
  })

  const handleRemoveValue = useCallback(() => {
    formContext?.setValue(id, undefined)
  }, [formContext, id])

  return (
    <div tw='flex flex-col'>
      <div tw='relative' ref={inputRef}>
        {label && (
          <label
            htmlFor={id}
            className='text-secondary inline-block py-3 text-sm font-semibold'
            css={[errorString && tw`text-error`]}
          >
            {label}
          </label>
        )}
        <div
          className='focus:(text-gray-700 border-primary) focus-visible:border-primary m-0 flex w-full justify-between rounded border border-solid border-zinc-200 bg-white bg-clip-padding px-3 py-2 transition-colors focus:outline-0 focus-visible:outline-0'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setIsCalendarOpen(open => !open)
          }}
          css={[errorString && tw`border-error`]}
        >
          <div className={'flex items-center gap-2'}>
            <div css={[!value && tw`text-[#7E7E7E]`]}>
              {value ? dayjs(value).format('MMMM, YYYY') : t('shared.placeholders.choose')}
            </div>
            {!!value && (
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleRemoveValue()
                }}
              >
                <img src={assets.AddIcon} alt='add' className={'w-4 rotate-45'} />
              </div>
            )}
          </div>
          <div>
            <img src={assets.CalendarIcon} alt='calendar' />
          </div>
        </div>
        <Calendar
          tw='absolute z-20 transition-all'
          className={[isCalendarOpen ? 'opacity-100' : 'hidden opacity-0']}
          value={value ? dayjs(value).toDate() : dayjs().toDate()}
          maxDetail={selectDays ? 'month' : 'year'}
          formatMonth={(locale, date) => dayjs(date).format('MMMM')}
          locale={'hr-HR'}
          onClickMonth={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClickDay={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClickWeekNumber={(date, date2, e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClickYear={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClickDecade={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          defaultView={'month'}
          onChange={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
            formContext.setValue(
              id,
              dayjs(date as Date)
                .set('day', 15)
                .format('YYYY-MM-DD'),
            )
            setIsCalendarOpen(false)
          }}
          {...calendarProps}
        />
      </div>
    </div>
  )
}
