import { Segmented } from '@components/inputs'
import { ITableData, Table } from '@components/ui'
import { useCompanyDetails } from '@hooks/api/company'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

export const CompanyTable = () => {
  const [filter, setFilter] = useState('year')
  const { array, push, removeItem } = useArray<string | number>([])
  const {
    filter: { year },
  } = useGlobalFilter()
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  const { data, isLoading } = useCompanyDetails()

  const {
    income,
    expense,
    editba,
    editba_margin,
    amortization,
    financial_revenue,
    financial_expenses,
    profit_tax,
    neto,
    neto_margin,
  } = data?.data ?? {}

  const tableData = [
    {
      id: '1',
      key: '1Up',
      income_and_costs: 'Ukupni prihodi',
      decoration: tw`bg-table-accent-grey font-semibold`,
      ...income?.total_income,
      subItems: [
        { id: '2', key: '2Up', income_and_costs: 'Prihod od prodaje usluga', ...income?.service_income },
        { id: '3', key: '3Up', income_and_costs: 'AVB', ...income?.avb },
        { id: '4', key: '4Up', income_and_costs: 'Posebni projekti', ...income?.special_projects },
        { id: '5', key: '5Up', income_and_costs: 'Prihod od prodaje roba', ...income?.sale_goods },
        { id: '6', key: '6Up', income_and_costs: 'Izvanredni projekti', ...income?.extraordinary_income },
        { id: '7', key: '7Up', income_and_costs: 'Ostali prihodi', ...income?.other_income },
      ],
    },
    {
      id: '2',
      key: '2Ut',
      income_and_costs: 'Ukupni troškovi',
      decoration: tw`bg-table-accent-grey font-semibold`,
      ...expense?.total_expense,
      subItems: [
        { id: '3', key: '3Ut', income_and_costs: 'Izravni troškovi klijenata', ...expense?.service_expense },
        { id: '4', key: '4Ut', income_and_costs: 'AVB', ...expense?.avb },
        { id: '5', key: '5Ut', income_and_costs: 'Posebni projekti', ...expense?.special_projects_costs },
        { id: '6', key: '6Ut', income_and_costs: 'Trošak plaće', ...expense?.salary },
        { id: '7', key: '7Ut', income_and_costs: 'Reprezentacija', ...expense?.representation },
        { id: '8', key: '8Ut', income_and_costs: 'Uredski materijal', ...expense?.office_materials },
        { id: '9', key: '9Ut', income_and_costs: 'Troškovi savjetovanja', ...expense?.consulting_costs },
        { id: '10', key: '10Ut', income_and_costs: 'Zakup', ...expense?.lease },
        { id: '11', key: '11Ut', income_and_costs: 'Članarine', ...expense?.memberships },
        { id: '12', key: '12Ut', income_and_costs: 'Nabava prodane robe', ...expense?.goods_procurement },
        { id: '13', key: '13Ut', income_and_costs: 'Putovanja', ...expense?.travel },
        { id: '14', key: '14Ut', income_and_costs: 'Otpisi', ...expense?.write_offs },
        { id: '15', key: '15Ut', income_and_costs: 'Edukacija', ...expense?.education },
        { id: '16', key: '16Ut', income_and_costs: 'Troškovi banaka i osiguranja', ...expense?.bank_insurance_costs },
        { id: '17', key: '17Ut', income_and_costs: 'Ostali troškovi', ...expense?.other_costs },
      ],
    },
    {
      id: '4',
      key: 'EDITBA',
      decoration: tw`bg-table-accent-blue font-semibold`,
      income_and_costs: 'EDITBA',
      ...editba,
    },
    {
      id: '5',
      key: 'EDITBA_margin',
      decoration: tw`bg-table-accent-blue font-semibold`,
      income_and_costs: 'EDITBA marža %',
      ...editba_margin,
    },
    {
      id: '6',
      key: 'Amortizacija',
      income_and_costs: 'Amortizacija',
      ...amortization,
    },
    {
      id: '7',
      key: 'Financijski prihodi',
      income_and_costs: 'Financijski prihodi',
      ...financial_revenue,
    },
    {
      id: '8',
      key: 'Financijski rashodi',
      income_and_costs: 'Financijski rashodi',
      ...financial_expenses,
    },
    {
      id: '9',
      key: 'Porez na dobit',
      income_and_costs: 'Porez na dobit',
      ...profit_tax,
    },
    {
      id: '10',
      key: 'Neto dobit',
      decoration: tw`bg-table-accent-green font-semibold`,
      income_and_costs: 'Neto dobit',
      ...neto,
    },
    {
      id: '11',
      key: 'Neto dobit marža %',
      decoration: tw`bg-table-accent-green font-semibold`,
      income_and_costs: 'Neto dobit marža %',
      ...neto_margin,
    },
  ] as ITableData[]

  const yearColumns = (year: number) => [
    {
      key: (year - 1).toString(),
      dataIndex: (year - 1).toString(),
      title: (year - 1).toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`${year - 1}`])}</>,
    },
    {
      key: year.toString(),
      dataIndex: year.toString(),
      title: year.toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`${year}`])}</>,
    },
    {
      key: `${year}-${year - 1}`,
      dataIndex: `${year}-${year - 1}`,
      title: `${year}-${year - 1}`,
      render: (data: ITableData) => <>{formatCurrency(data[`${year}-${year - 1}`])}</>,
    },
    {
      key: `${year}-${year - 1}%`,
      dataIndex: `${year}-${year - 1}%`,
      title: `${year}-${year - 1}%`,
      render: (data: ITableData) => <>{formatPercentage(data[`${year}-${year - 1}%`])}</>,
    },
  ]

  const quartalColumns = [
    {
      key: 'Q1',
      dataIndex: 'Q1',
      title: 'Q1 ' + year.toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`q1`])}</>,
    },
    {
      key: 'Q2',
      dataIndex: 'Q2',
      title: 'Q2 ' + year.toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`q2`])}</>,
    },
    {
      key: 'Q3',
      dataIndex: 'Q3',
      title: 'Q3 ' + year.toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`q3`])}</>,
    },
    {
      key: 'Q4',
      dataIndex: 'Q4',
      title: 'Q4 ' + year.toString(),
      render: (data: ITableData) => <>{formatCurrency(data[`q4`])}</>,
    },
  ]

  return (
    <>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'>{t('company.yearly_results')}</span>
        <Segmented
          options={[
            { label: t('global_filter.year'), value: 'year' },
            { label: t('global_filter.quartal'), value: 'quartal' },
          ]}
          value={filter}
          onChange={val => setFilter(val)}
        />
      </div>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          columns={[
            {
              key: 'income_and_costs',
              dataIndex: 'income_and_costs',
              title: t('company.income_and_costs'),
            },
            ...(filter == 'year' ? yearColumns(year) : quartalColumns),
          ]}
          data={tableData}
        />
      </div>
    </>
  )
}
