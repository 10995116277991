import { Card } from '@components/ui'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'
import tw from 'twin.macro'

export const MediasHeader = () => {
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  return (
    <PageHeader title={t('media.all_media')}>
      <section tw='mb-5 grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('media.plan')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          <span tw='font-medium text-icons-dark text-2xl'>{formatCurrency(Math.round(Math.random() * 400000))}</span>
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.costs')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          <span tw='font-medium text-icons-dark text-2xl'>{formatCurrency(Math.round(Math.random() * 400000))}</span>
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('media.client_profit')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          <span tw='font-medium text-icons-dark text-2xl'>{formatCurrency(Math.round(Math.random() * 400000))}</span>
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.profit')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          <span tw='font-medium text-icons-dark text-2xl'>{formatCurrency(Math.round(Math.random() * 400000))}</span>
        </Card>
        <Card
          title={<span tw='font-semibold text-secondary'>{t('shared.margin')}</span>}
          subtitle={
            <span css={[Math.random() > 0.5 ? tw`text-error` : tw`text-positive`]}>
              {Math.round(Math.random() * 10) + '%'}
            </span>
          }
        >
          <span tw='font-medium text-icons-dark text-2xl'>{formatCurrency(Math.round(Math.random() * 400000))}</span>
        </Card>
      </section>
      <span tw='mb-5 inline-block text-icons-dark text-xl'>{t('media.realization')}</span>
      <section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card title={<span>{t('media.tv')}</span>} subtitle={<span>{formatCurrency(Math.random() * 100000)}</span>} />
        <Card
          title={<span>{t('media.digital')}</span>}
          subtitle={<span>{formatCurrency(Math.round(Math.random() * 100000))}</span>}
        />
        <Card title={<span>{t('media.print')}</span>} subtitle={<span>{Math.round(Math.random() * 100000)}</span>} />
        <Card title={<span>{t('media.outdoor')}</span>} subtitle={<span>{Math.round(Math.random() * 100000)}</span>} />
        <Card title={<span>{t('media.radio')}</span>} subtitle={<span>{Math.round(Math.random() * 100000)}%</span>} />
      </section>
    </PageHeader>
  )
}
