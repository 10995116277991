import Assets from '@assets/assets'
import { navigateRoutes } from '@routes/navigate'
import dayjs from 'dayjs'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'

interface ICompanyCard {
  company: {
    createdAt: Date
    updatedAt: Date
    id: number
    image: string
    name: string
    deletedAt: Date
  }
}

export const CompanyCard: FC<ICompanyCard> = ({ company }) => {
  const { company: navigateCompany } = navigateRoutes
  return (
    <Link to={navigateCompany(company.id)}>
      <div className='hover:border-primary-light flex w-72 cursor-pointer flex-col justify-start rounded-md border border-zinc-200 bg-white'>
        <div className='flex h-36 items-center justify-center'>
          <img
            alt={`${company.name}-logo`}
            className='h-full w-auto'
            css={[!company.image && tw`w-[48px] h-[48px]`]}
            src={company.image ? company.image : Assets.CompanyNoImage}
          />
        </div>
        <div className='flex flex-col items-start justify-start'>
          <div className='flex w-full items-start justify-between border-t border-zinc-200 py-4 pl-4 pr-5'>
            <div className='text-base font-normal leading-normal text-slate-500'>Naziv</div>
            <div className='text-right  text-base font-normal leading-normal text-zinc-700'>{company.name}</div>
          </div>
          <div className='flex w-full items-start justify-between border-t border-zinc-200 py-4 pl-4 pr-5'>
            <div className='text-base font-normal leading-normal text-slate-500'>Kreiran</div>
            <div className='text-right text-base font-normal leading-normal text-zinc-700'>
              {dayjs(company.createdAt).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className='flex w-full items-start justify-between border-t border-zinc-200 py-4 pl-4 pr-5'>
            <div className='text-base font-normal leading-normal text-slate-500'>Aktivnost</div>
            <div className='text-right text-base font-normal leading-normal text-zinc-700'>
              {company.deletedAt ? 'Neaktivan' : 'Aktivan'}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
