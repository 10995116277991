import { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import 'twin.macro'
import tw from 'twin.macro'

interface IModalProps {
  isOpen: boolean
  createOnInit?: boolean
  close: () => void
  closeOnOverlayClick?: boolean
}

export const Modal: FC<PropsWithChildren<IModalProps>> = ({
  children,
  isOpen,
  close: onClose,
  createOnInit,
  closeOnOverlayClick = true,
}) => {
  const handleClose = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose()
    },
    [onClose],
  )

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('keydown', handleClose)
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.removeEventListener('keydown', handleClose)
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.removeEventListener('keydown', handleClose)
      document.body.classList.remove('overflow-hidden')
    }
  }, [handleClose, isOpen])

  if (!isOpen && !createOnInit) return null

  return createPortal(
    <div
      css={[!isOpen ? tw`hidden` : tw`fixed top-0 left-0 h-screen w-screen`]}
      tw='flex items-center justify-center transition-all z-[10000]'
    >
      <div
        css={[!isOpen && tw`hidden`]}
        tw='fixed top-0 left-0 z-0 h-screen w-screen cursor-default bg-black bg-opacity-[0.45]'
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (closeOnOverlayClick) {
            onClose()
          }
        }}
      />
      <div tw='relative z-10'>{children}</div>
    </div>,
    document.body,
  )
}
