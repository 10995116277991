import { LegendDot, Table } from '@components/ui'
import { useEmployeesClientDepartments } from '@hooks/api/employee'
import { useArray } from '@hooks/useArray'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useNavigation } from '@hooks/useNavigation'
import { useOrder } from '@hooks/useOrder'
import { useMe } from '@stores/me.store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

export const EmployeeClientsTable = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { array, push, removeItem } = useArray<string | number>([])
  const [order, setOrder, orderData] = useOrder({ key: 'salaries', direction: 'DESC' })
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { client, employee } = useNavigation()
  const { me } = useMe()

  const { data } = useEmployeesClientDepartments()

  const tableData = (data?.data ?? [])
    .map(l => ({
      ...l,
      key: l.client_id,
      children: (l?.departments ?? []).map(c => ({
        ...c,
        key: c.department_id,
        children: (c?.employees ?? []).map(e => ({ ...e, key: e.employee_id })).sort((a, b) => b.hours - a.hours),
      })),
    }))
    .filter(z => z.client?.toLowerCase().includes(search.toLowerCase()))

  return (
    <>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={(id: string | number) => (array.includes(id) ? removeItem(id) : push(id))}
          visibleRows={array}
          title={t('employees.employee_per_client')!}
          search={(search?: string) => setSearch(search ?? '')}
          order={order}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            perPage: 10,
            total: tableData.length,
          }}
          columns={[
            {
              key: 'client',
              dataIndex: 'client',
              title: t('employees.client_department_employee'),
              order: setOrder,
              decoration: tw`w-1/5`,
              render: data => (
                <LegendDot
                  title={data['client'] ?? data['department'] ?? data['employee']}
                  showDot={!!data['department']}
                  onClick={() =>
                    data['client'] && me?.isAdmin
                      ? client(data['client_id'])
                      : data['employee']
                      ? employee(data['employee_id'])
                      : undefined
                  }
                />
              ),
            },
            {
              key: 'hours',
              dataIndex: 'hours',
              decoration: tw`w-[150px]`,
              title: t('shared.worked_hours'),
              order: setOrder,
              render: data => <>{data['hours'] ? Math.round(data['hours']) : '-'}</>,
            },
            {
              key: 'overtime',
              dataIndex: 'overtime',
              decoration: tw`w-[150px]`,
              title: t('shared.overtime'),
              order: setOrder,
              hidden: !me?.companySettings?.hasOvertime,
              render: data => <>{data['overtime'] ?? '-'}</>,
            },
            {
              key: 'salaries',
              dataIndex: 'salaries',
              decoration: tw`w-[150px]`,
              title: 'Trošak plaće',
              order: setOrder,
              render: data => <>{formatCurrency(data['salary'])}</>,
            },
            {
              key: 'fte',
              dataIndex: 'fte',
              title: t('shared.fte'),
              decoration: tw`w-[150px]`,
              order: setOrder,
              render: data => <>{data['fte'] ?? '-'}</>,
            },
            {
              key: 'income',
              dataIndex: 'income',
              title: t('shared.income_sing'),
              order: setOrder,
              hidden: !me?.companySettings?.hasPL,
              render: data => <>{formatCurrency(data['income'])}</>,
            },
            {
              key: 'profit_no_pay',
              dataIndex: 'profit_no_pay',
              hidden: !me?.companySettings?.hasPL,
              title: t('clients.profit_no_pay'),
              order: setOrder,
              render: data => <>{formatCurrency(data['profit_no_pay'])}</>,
            },
            {
              key: 'profit',
              dataIndex: 'profit',
              title: t('shared.profit'),
              hidden: !me?.companySettings?.hasPL,
              order: setOrder,
              render: data => <>{formatCurrency(data['profit'])}</>,
            },
          ]}
          data={tableData.sort(orderData)}
        />
      </div>
    </>
  )
}
