import { CreateSalaryDTO, UpdateSalaryDTO } from '@api/generated-api'
import { createSalary, updateSalary } from '@api/salary'
import { Button, Skeleton, Table } from '@components/ui'
import { useEmployeeSummary } from '@hooks/api/employee'
import { useEmployeeSalaries } from '@hooks/api/salary'
import { formatCurrency } from '@utils/formatCurrency'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { EditDeleteRender } from '../../../components/Renders/EditDeleteRender'
import { CreateExpenseModal } from '../Components/CreateExpenseModal'

export const ExpensesTable: FC = () => {
  const { id } = useParams()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const { data, isLoading, refetch } = useEmployeeSalaries(Number(id))
  const { data: employeeSummary } = useEmployeeSummary(id)
  const employeeArrival = employeeSummary?.data?.arrival || (data?.data || [])[(data?.data || []).length - 1]?.date
  const { t } = useTranslation()
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const create = async (salary: CreateSalaryDTO) => {
    const { status } = await createSalary({
      ...salary,
      date: dayjs(salary.date).set('date', 15).format('YYYY-MM-DD'),
      employeeId: Number(id),
    })
    if (status >= 200 || status < 300) {
      setIsCreateOpen(false)
      setIsConfirmationModalOpen(true)
      refetch()
    }
  }

  if (isLoading || !data?.data) {
    return (
      <Skeleton showSkeleton>
        <div tw='h-96 w-full' />
      </Skeleton>
    )
  }

  const salaries = (data?.data || []).map(val => ({ ...val }))
  const newestSalary = salaries?.[0]

  const edit = async (salary: UpdateSalaryDTO) => {
    const { status } = await updateSalary(newestSalary.id, {
      ...salary,
      date: dayjs(salary.date).set('date', 15).format('YYYY-MM-DD'),
      employeeId: Number(id),
    })
    if (status >= 200 || status < 300) {
      setIsEditOpen(false)
      refetch()
    }
  }

  return (
    <>
      <div tw='mb-5 flex w-full items-center justify-between'>
        <div className='text-2xl font-normal leading-[33.60px] text-gray-600'>{t('employees.expenses')}</div>
        <Button buttonType={'secondary'} onClick={() => setIsCreateOpen(true)}>
          {t('employees.add_expense')}
        </Button>
      </div>
      <div tw='mb-10 overflow-scroll'>
        <Table
          columns={[
            {
              key: 'date',
              dataIndex: 'date',
              title: t('shared.date'),
              render: data => <>{dayjs(data['date']).locale('hr').format('MMMM, YYYY')}</>,
            },
            {
              key: 'bruto2',
              dataIndex: 'bruto2',
              title: t('employees.bruto2'),
              render: data => <>{formatCurrency(data['bruto2'])}</>,
            },
            {
              key: 'neto',
              dataIndex: 'neto',
              title: t('employees.neto'),
              render: data => <>{formatCurrency(data['neto'])}</>,
            },
            {
              key: 'pay_in_kind',
              dataIndex: 'pay_in_kind',
              title: t('employees.pay_in_kind'),
              render: data => <>{formatCurrency(data['kind'])}</>,
            },
            {
              key: 'transport',
              dataIndex: 'transport',
              title: t('employees.transport'),
              render: data => <>{formatCurrency(data['transport'])}</>,
            },
            {
              key: 'fuel_and_toll',
              dataIndex: 'fuel_and_toll',
              title: t('employees.fuel_and_toll'),
              render: data => <>{formatCurrency(data['fuel'])}</>,
            },
            // {
            //   key: 'parking',
            //   dataIndex: 'parking',
            //   title: t('employees.parking'),
            //   render: data => <>{formatCurrency(data['parking'])}</>,
            // },
            // {
            //   key: 'other_expenses',
            //   dataIndex: 'other_expenses',
            //   title: t('employees.other_expenses'),
            //   render: data => <>{formatCurrency(data['other'])}</>,
            // },
            // {
            //   key: 'total',
            //   dataIndex: 'total',
            //   title: t('employees.total_cost'),
            //   render: data => <>{formatCurrency(data['total'])}</>,
            // },
            //
            {
              key: 'description',
              dataIndex: 'description',
              title: t('employees.description'),
              render: data => <>{data['description']}</>,
            },
            {
              key: 'trash',
              dataIndex: 'trash',
              title: '',
              decoration: tw`w-[100px]`,
              render: data =>
                data?.id === newestSalary.id ? <EditDeleteRender onEdit={() => setIsEditOpen(data)} /> : undefined,
            },
          ]}
          data={salaries.map(s => ({ ...s, key: s.id }))}
        />
      </div>
      <CreateExpenseModal
        employeeArrival={employeeArrival}
        isOpen={isCreateOpen}
        close={() => setIsCreateOpen(false)}
        createExpense={create}
      />
      <CreateExpenseModal
        isOpen={isEditOpen}
        employeeArrival={employeeArrival}
        close={() => setIsEditOpen(false)}
        createExpense={edit}
        initialValues={newestSalary}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={`Nova povišica je uspješno dodana!`}
      />
    </>
  )
}
