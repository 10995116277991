import { DepartmentDto } from '@api/generated-api'
import { Dropdown } from '@components/ui'
import { useMe } from '@stores/me.store'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import tw from 'twin.macro'

export const WorkingHoursManagementHeader = ({
  selectedDepartment,
  setSelectedDepartment,
}: {
  selectedDepartment?: DepartmentDto
  setSelectedDepartment: Dispatch<SetStateAction<DepartmentDto | undefined>>
}) => {
  const { me } = useMe()
  const adminDepartments = (me?.departments || []).filter(d => (me?.managerDepartmentIds || []).includes(d?.id))
  const hasMultiDepartment = (adminDepartments?.length || 0) > 1
  const { t } = useTranslation()

  const items = useMemo(
    () =>
      hasMultiDepartment
        ? (adminDepartments || []).map(val => ({
            key: val.id,
            title: val.name,
            decoration: [
              tw`cursor-pointer`,
              selectedDepartment?.id == val.id ? tw`bg-dropdown-selected hover:bg-blue-200` : undefined,
            ],
            onClick: () => setSelectedDepartment(val),
            render: <span tw='cursor-pointer'>{val.name}</span>,
          }))
        : [],
    [adminDepartments, hasMultiDepartment, selectedDepartment?.id, setSelectedDepartment],
  )

  return (
    <PageHeader
      title={t('navigation.working_hours_management')}
      hidePeriod={!hasMultiDepartment}
      rightContent={
        <div>
          <div className='text-right text-base font-normal leading-snug text-gray-600'>Odaberi odjel</div>
          <Dropdown containerStyle={tw`border-0 bg-transparent`} trigger='click' items={items}>
            <div className='text-2xl font-normal leading-7 text-gray-600'>{selectedDepartment?.name}</div>
          </Dropdown>
        </div>
      }
    />
  )
}
