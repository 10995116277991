import { FC, PropsWithChildren } from 'react'
import 'twin.macro'
import tw from 'twin.macro'
import { IPaginationProps } from './Pagination'

interface IPaginationItemProps extends IPaginationProps {
  page?: number | string
}

const itemStyle = tw`border-2 border-sky-300`
const notAllowedStyle = tw`cursor-not-allowed`

export const PaginationItem: FC<PropsWithChildren<IPaginationItemProps>> = ({
  children,
  currentPage,
  page,
  onPageChange,
  total,
  perPage,
}) => {
  const pages = Math.ceil(total / perPage)

  const handleClick = () => {
    if (page == null) {
      return
    }
    if (typeof page === 'number' && isFinite(page)) {
      onPageChange(page)
    }
    switch (page) {
      case 'previous':
        onPageChange(Math.max(currentPage - 1, 1))
        break
      case 'next':
        onPageChange(Math.min(currentPage + 1, pages))
        break
      case 'jump-back':
        onPageChange(Math.max(currentPage - 3, 1))
        break
      case 'jump-forward':
        onPageChange(Math.min(currentPage + 3, pages))
        break
    }
    return
  }

  return (
    <li
      tw='flex h-8 cursor-pointer items-center justify-center rounded-md mx-[2px] min-w-[32px] hover:bg-gray-300'
      css={[
        currentPage != null && currentPage == page && itemStyle,
        ((page == 'previous' && currentPage == 1) || (page == 'next' && currentPage == pages)) && notAllowedStyle,
      ]}
      onClick={handleClick}
    >
      {children}
    </li>
  )
}
