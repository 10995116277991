import { Card } from '@components/ui'
import { useEmployeesSummary } from '@hooks/api/employee'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { formatCurrency as fc } from '@utils/formatCurrency'
import { formatPercentage } from '@utils/formatPercentage'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'
import tw from 'twin.macro'

export const EmployeesHeader = () => {
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  const { data, isLoading } = useEmployeesSummary()

  const {
    salary,
    employees,
    avg_salary,
    hours,
    hourly_wage,
    overtime,
    salary_diff,
    employees_diff,
    avg_salary_diff,
    hours_diff,
    hourly_wage_diff,
    overtime_diff,
    maternity_fte_diff,
    maternityFTE,
    sick_fte_diff,
    sickFTE,
    vacation_fte_diff,
    vacationFTE,
  } = data?.data ?? {}

  return (
    <PageHeader title={t('shared.employees')}>
      <section tw='mb-6 grid grid-cols-6 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.salary')}</span>}
          subtitle={
            <div css={[!!salary_diff && salary_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(salary_diff)}
            </div>
          }
        >
          <>{formatCurrency(salary)}</>
        </Card>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.num_of_employees')}</span>}
          subtitle={
            <div css={[!!employees_diff && employees_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(employees_diff)}
            </div>
          }
        >
          <>{fc(employees)}</>
        </Card>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.average_salary')}</span>}
          subtitle={
            <div css={[!!avg_salary_diff && avg_salary_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(avg_salary_diff)}
            </div>
          }
        >
          <>{formatCurrency(avg_salary)}</>
        </Card>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.worked_hours')}</span>}
          subtitle={
            <div css={[!!hours_diff && hours_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(hours_diff)}
            </div>
          }
        >
          <>{fc(Math.round(hours ?? 0))}</>
        </Card>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.overtime')}</span>}
          subtitle={
            <div css={[!!overtime_diff && overtime_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(overtime_diff)}
            </div>
          }
        >
          <>{fc(overtime)}</>
        </Card>
        <Card
          loading={isLoading && true && true}
          title={<span>{t('shared.hourly_wage')}</span>}
          subtitle={
            <div css={[!!hourly_wage_diff && hourly_wage_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(hourly_wage_diff)}
            </div>
          }
        >
          <>{formatCurrency(hourly_wage)}</>
        </Card>
      </section>
      <section tw='grid grid-cols-6 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        <Card
          titleStyle={tw`font-semibold`}
          title={<span>{t('employees.fte_vacation')}</span>}
          subtitle={
            <div css={[!!vacation_fte_diff && vacation_fte_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(vacation_fte_diff)}
            </div>
          }
        >
          <div>{vacationFTE}</div>
        </Card>
        <Card
          titleStyle={tw`font-semibold`}
          title={<span>{t('employees.fte_sick')}</span>}
          subtitle={
            <div css={[!!sick_fte_diff && sick_fte_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(sick_fte_diff)}
            </div>
          }
        >
          <div>{sickFTE}</div>
        </Card>
        <Card
          titleStyle={tw`font-semibold`}
          title={<span>{t('employees.fte_maternity')}</span>}
          subtitle={
            <div css={[!!maternity_fte_diff && maternity_fte_diff >= 0 ? tw`text-positive` : tw`text-error`]}>
              {formatPercentage(maternity_fte_diff)}
            </div>
          }
        >
          <div>{maternityFTE}</div>
        </Card>
      </section>
    </PageHeader>
  )
}
