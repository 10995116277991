import { ComponentWrapper } from '@components/ui'
import { useMe } from '@stores/me.store'
import { CompanyCompare } from './CompanyCompare'
import { CompanyTable } from './CompanyTable'

export const CompanyDetails = () => {
  const { me } = useMe()
  return (
    <ComponentWrapper>
      {me?.companySettings?.hasPL && <CompanyTable />}
      <CompanyCompare />
    </ComponentWrapper>
  )
}
