import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IWorkingHoursArchiveTableData } from '../../../types'

export const useGetArchivedWorkingHoursManagement = ({
  departmentId,
  year,
}: {
  departmentId: number
  year: number
}) => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<IWorkingHoursArchiveTableData[]>, null, IWorkingHoursArchiveTableData[]>({
    queryKey: ['working-hours', 'management', year, 'archived', departmentId],
    queryFn: () => workingHours.workingHoursControllerGetArchiveMonthsManager({ departmentId, year }),
    select: data => data.data,
    enabled: !!departmentId && !!year,
  })
}
