import { FC } from 'react'
import tw from 'twin.macro'
import { Button } from './Button'

interface IPercentageButton {
  showPercentage: boolean
  setShowPercentage: () => void
}

export const PercentageButton: FC<IPercentageButton> = ({ showPercentage, setShowPercentage }) => {
  return (
    <Button
      css={[
        tw`flex h-7 w-7 items-center justify-center rounded-full border-2 border-background p-0 text-2xs text-icons hover:text-icons-dark`,
        showPercentage && tw`bg-background-light text-icons-dark`,
      ]}
      buttonType='text'
      onClick={setShowPercentage}
    >
      %
    </Button>
  )
}
