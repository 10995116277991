import { updateEmployee } from '@api/employee'
import { UpdateEmployeeDTO, UpdateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Drawer } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { EmployeeSummary } from '@interfaces/employee'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { EditEmployeeForm } from '../Forms/EditEmployeeForm'

interface EditEmployeeDrawerProps {
  isEditEmployeeDrawerOpen: boolean
  setIsEditEmployeeDrawerOpen: Dispatch<SetStateAction<boolean>>
  employee: EmployeeSummary
}

const EditEmployeeSchema: z.ZodType<UpdateEmployeeDTO> = z.object({
  name: z.string().min(1),
  email: z.string().min(1),
  departments: z.object({ id: z.number(), isManager: z.boolean() }).array().min(1),
  hoursType: z.nativeEnum(UpdateEmployeeDtoHoursTypeEnum),
  departure: z.string().optional().nullable(),
  arrival: z.string(),
})

export const EditEmployeeDrawer = ({
  isEditEmployeeDrawerOpen,
  setIsEditEmployeeDrawerOpen,
  employee,
}: EditEmployeeDrawerProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const methods = useForm<UpdateEmployeeDTO>({
    resolver: zodResolver(EditEmployeeSchema),
    defaultValues: {
      hoursType: employee.hoursType as UpdateEmployeeDtoHoursTypeEnum,
      name: employee.name,
      email: employee.user.email,
      arrival: employee.arrival,
      code: employee.code,
      departure: employee.departure,
      departments: (employee.departments || [])?.map(d => ({
        id: d.id,
        isManager: (employee.managingDepartments || []).map(md => md.id).includes(d.id),
      })),
    },
  })

  const { mutateAsync: updateEmployeeAsync } = useMutation({
    mutationFn: (values: UpdateEmployeeDTO) => updateEmployee(employee.id, values),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: q => q.queryKey.join('-').includes(['employee-summary', employee.id!].join('-')),
        type: 'all',
        exact: false,
      })
    },
  })

  const handleClose = () => {
    setIsEditEmployeeDrawerOpen(false)
    methods.reset()
  }

  const updateEmployeeFn = async (employee: UpdateEmployeeDTO) => {
    const data = await updateEmployeeAsync(employee)
    if (data.status < 300) {
      handleClose()
      setIsConfirmationModalOpen(true)
    }
  }

  return (
    <>
      <Drawer
        isOpen={isEditEmployeeDrawerOpen}
        close={handleClose}
        placement={'right'}
        title={t('employees.edit_employee') as string}
      >
        <div className='relative mx-8 flex flex-1 flex-col overflow-scroll bg-white pt-8'>
          <EditEmployeeForm
            onUpdate={updateEmployeeFn}
            methods={methods}
            employee={employee}
            handleClose={handleClose}
          />
        </div>
      </Drawer>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={`Promjene su uspješno spremljene!`}
      />
    </>
  )
}
