import { updateMe } from '@api/auth'
import { Skeleton } from '@components/ui'
import { useMe } from '@hooks/api/user'
import { useMe as meStore } from '@stores/me.store'
import { notificationStore } from '@stores/notification.store'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditAccountForm } from 'src/components/Forms/Account/EditAccountForm'
import 'twin.macro'
import { pruneUserValues } from '../utils'

export const AccountContent = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError, refetch } = useMe()
  const { setMe } = meStore.getState()
  const { addNotification } = notificationStore.getState()

  const onSubmit = async (val: FieldValues) => {
    const updateValues = pruneUserValues(data!.data, val)
    if (updateValues == undefined) {
      return
    }
    const { status } = await updateMe(updateValues)
    addNotification(
      status < 300
        ? { status: 'success', content: t('account.succesfull_update') }
        : { status: 'error', content: t('account.failed_update') },
    )
    const { data: updated } = await refetch()

    if (updated?.data) {
      setMe(updated.data as any)
    }
  }

  if (isLoading) return <Skeleton showSkeleton />

  if (isError || !data?.data)
    return (
      <div tw='flex h-96 w-full flex-col items-center justify-center'>
        <span tw='text-2xl text-icons'>{t('shared.no_data')}</span>
        <i tw='text-2xl text-icons' className='fa fa-box-open' aria-hidden='true'></i>
      </div>
    )

  return <EditAccountForm onSubmit={onSubmit} initialValue={data.data} />
}
