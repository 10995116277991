import 'twin.macro'
import { WorkingHoursContent } from './Components/WorkingHoursContent'
import { WorkingHoursHeader } from './Components/WorkingHoursHeader'

export const WorkingHours = () => {
  return (
    <div className={'bg-table-accent-grey h-full'}>
      <WorkingHoursHeader />
      <WorkingHoursContent />
    </div>
  )
}
