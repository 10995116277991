import Assets from '@assets/assets'
import { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import tw, { TwStyle } from 'twin.macro'
import { getClosedPlacementStyle, getPlacementStyle, Placement } from './utils'

interface IDrawerProps {
  isOpen: boolean
  close: () => void
  placement: Placement
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  headerTwStyle?: string
  drawerTwStyle?: TwStyle
  isAnotherDrawerOpen?: boolean
}

export const Drawer: FC<PropsWithChildren<IDrawerProps>> = ({
  isOpen,
  close,
  placement,
  children,
  title,
  subtitle,
  headerTwStyle,
  isAnotherDrawerOpen,
  drawerTwStyle,
}) => {
  return createPortal(
    <div
      id={'drawer'}
      key={'drawer'}
      tw='fixed top-0 left-full flex h-screen w-screen items-center justify-center transition-all z-[9990]'
    >
      <div
        tw='fixed top-0 left-0 z-50 h-screen w-screen cursor-default transition-all'
        style={{ backgroundColor: 'rgba(154, 154, 154, 0.47)' }}
        css={[isOpen && tw`backdrop-blur-sm`, !isOpen && tw`pointer-events-none opacity-0`]}
        onClick={close}
      />
      <div tw='relative z-50 rounded bg-white'>
        <section
          className='fixed flex max-h-full max-w-3xl flex-col overflow-y-auto bg-white pb-4 transition-all duration-500 max-md:max-w-xl'
          css={[
            getPlacementStyle(placement, 'container'),
            !isOpen && getClosedPlacementStyle(placement),
            isAnotherDrawerOpen && tw`translate-x-[-500px]`,
            drawerTwStyle,
          ]}
        >
          <section className={twMerge(['flex items-center justify-between px-7 pt-9 gap-6', headerTwStyle])}>
            {typeof title === 'string' ? (
              <article tw='flex flex-col gap-2'>
                <span tw='font-light text-text-gray text-2xl'>{title}</span>
                {!!subtitle && <span tw='text-text-unfilled'>{subtitle}</span>}
              </article>
            ) : (
              title
            )}
            <button
              onClick={close}
              tw='z-50 h-8 w-8 cursor-pointer text-gray-400'
              css={getPlacementStyle(placement, 'icon')}
              type='button'
            >
              <img src={Assets.XIcon} alt='close' />
            </button>
          </section>
          {children}
        </section>
      </div>
    </div>,
    document.body,
  )
}
