import { FC, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null
}

export const SearchInput: FC<ISearchInputProps> = ({ label, ...inputProps }) => {
  const { t } = useTranslation()
  return (
    <div className='relative mb-10 w-[200px]'>
      <i className='fa fa-search' tw='absolute right-4 top-3 z-10 text-icons' />
      <input
        className='border-accent focus:(text-gray-700 border-primary) m-0 w-full rounded border border-solid bg-white bg-clip-padding px-3 py-2 transition-colors'
        tw='absolute right-0 pl-3 outline-none w-[200px]'
        placeholder={label ?? (t('shared.search') as string)}
        {...inputProps}
      />
    </div>
  )
}
