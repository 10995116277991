import assets from '@assets/assets'
import { Button, Modal } from '@components/ui'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

interface INotificationModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  subtitle: string
  primaryButtonText?: string
  onPrimaryClick?: (e: any) => void
  secondaryButtonText?: string
  onSecondaryPress?: (e: any) => void
  isLoading?: boolean
}

export const NotificationModal = ({
  title,
  subtitle,
  primaryButtonText,
  onPrimaryClick,
  secondaryButtonText,
  onSecondaryPress,
  isOpen,
  onClose,
  isLoading,
}: INotificationModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} close={onClose} closeOnOverlayClick={false}>
      <div
        className={'text-modal-title relative w-[513px] rounded-md bg-white px-7 py-6'}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <div className={'absolute right-7 top-3 cursor-pointer'} onClick={onClose}>
          <img src={assets.XIcon} alt={'close'} />
        </div>
        <div className={'text-modal-title mb-6'}>{title}</div>
        <div className={"font-['IBM Plex Sans'] mb-10 text-base font-normal leading-snug text-zinc-500"}>
          {subtitle}
        </div>
        <div
          className={twMerge(
            'flex items-center justify-between',
            secondaryButtonText ? 'justify-between' : 'justify-end',
          )}
        >
          {!!secondaryButtonText && (
            <Button buttonType={'secondary'} onClick={onSecondaryPress || onClose}>
              {secondaryButtonText}
            </Button>
          )}

          <Button isLoading={isLoading} disabled={isLoading} onClick={onPrimaryClick || onClose}>
            {primaryButtonText || t('shared.okay')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
