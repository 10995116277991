import { Modal } from '@components/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { ICreateClient } from '../../../../types/client'
import { CreateClientForm } from '../Forms/CreateClientForm'

interface ICreateClientModalProps {
  isOpen: boolean
  close: () => void
  createClient: (department: ICreateClient) => void
}

export const CreateClientModal: FC<ICreateClientModalProps> = ({ isOpen, close, createClient }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} close={close}>
      <div tw='relative flex flex-col rounded-md border bg-white px-10 py-8 shadow-sm min-w-[350px] max-w-[500px]'>
        <i tw='absolute top-3 right-5 cursor-pointer text-lg text-icons-dark' className='fa fa-times' onClick={close} />
        <h2 tw='mb-4 block text-icons-dark text-2.5xl'>{t('clients.add_client')}</h2>
        <CreateClientForm onCreate={createClient} />
      </div>
    </Modal>
  )
}
