import { DashDepartmentReview, DashEmployeeAndClient, DashTopClient, IncomeAndCosts } from '@interfaces/dash'
import { get } from './axiosInstance'

export const dashIncomeAndCosts = async () => get<IncomeAndCosts>('/stats/dash/income-and-costs')

export const dashEmployeeAndClient = async (currency?: string) =>
  get<DashEmployeeAndClient>('/stats/dash/employee-and-client', { params: { currency } })

export const dashDepartmentReview = async () => get<Array<DashDepartmentReview>>('/stats/dash/department-review')

export const dashTopClients = async (order?: string) =>
  get<Array<DashTopClient>>(`/stats/dash/top-clients?order=${order}`)
