import {
  employeeClientDepartments,
  employeeDetails,
  employeesClients,
  employeesMaternitySummary,
  employeesSickSummary,
  employeesSummary,
  employeeSummary,
  employeesVacationSummary,
  getEmployee,
  getEmployees,
} from '@api/employee'
import { useRefreshQuery } from './useRefreshQuery'

export const useEmployeesSummary = () => useRefreshQuery(['employees-summary'], employeesSummary)

export const useEmployeesList = () => useRefreshQuery(['employees-list'], employeesClients)
export const useEmployeesVacationSummary = () =>
  useRefreshQuery(['employees-vacation-summary'], employeesVacationSummary)
export const useEmployeesSickSummary = () => useRefreshQuery(['employees-sick-summary'], employeesSickSummary)
export const useEmployeesMaternitySummary = () =>
  useRefreshQuery(['employees-maternity-summary'], employeesMaternitySummary)

export const useEmployeesClientDepartments = () => useRefreshQuery(['employee-summary'], employeeClientDepartments)

export const useEmployeeSummary = (id?: string) =>
  useRefreshQuery(['employee-summary', id!], () => employeeSummary(id!), { enabled: !!id })

export const useEmployeeDetails = (id?: string) =>
  useRefreshQuery(['employee-details', id!], () => employeeDetails(id!), { enabled: !!id })

export const useEmployees = () =>
  useRefreshQuery(['employee'], getEmployees, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  })

export const useEmployee = (id: number, disabled?: boolean) =>
  useRefreshQuery(['employee', id], () => getEmployee(id), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
    enabled: !disabled,
  })
