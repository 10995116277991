import { CreateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Button } from '@components/ui'
import { useEmployeeSummary } from '@hooks/api/employee'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EditEmployeeDrawer } from './EditEmployeeDrawer'

const InfoComponent = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className={'flex flex-col items-start justify-center'}>
      <div className='mb-[10px] text-sm font-medium leading-tight text-neutral-400'>{title}</div>
      <div className='text-sm font-medium leading-tight text-neutral-800'>{value}</div>
    </div>
  )
}

const hoursTypeTitle = {
  [CreateEmployeeDtoHoursTypeEnum.HALFTIME]: 'Pola radnog vremena',
  [CreateEmployeeDtoHoursTypeEnum.FULLTIME]: 'Puno radno vrijeme',
  [CreateEmployeeDtoHoursTypeEnum.STUDENT]: 'Student',
}

export const EmployeeInfo = () => {
  const [isEditEmployeeDrawerOpen, setIsEditEmployeeDrawerOpen] = useState(false)
  const { id } = useParams()

  const { data } = useEmployeeSummary(id)
  if (!data) {
    return null
  }

  const { name, departments, arrival, departure, user, managingDepartments, hoursType } = data?.data
  return (
    <div className={'mb-6 space-y-6'}>
      <div className={'border-b border-gray-100 pb-6'}>
        <div className={'flex items-center justify-between'}>
          <div className='mb-6 text-2xl font-normal leading-[33.60px] text-gray-600'>Osnovne informacije</div>
          <Button
            onClick={e => {
              e.preventDefault()
              setIsEditEmployeeDrawerOpen(true)
            }}
          >
            Uredi zaposlenika
          </Button>
        </div>
        <div className={'grid grid-cols-5'}>
          <InfoComponent title={'Ime i Prezime'} value={name} />
          <InfoComponent title={'Email'} value={user?.email} />
        </div>
      </div>
      <div className={'border-b border-gray-100 pb-6'}>
        <div className='mb-6 text-2xl font-normal leading-[33.60px] text-gray-600'>Informacije o zaposlenju</div>
        <div className={'grid grid-cols-5 justify-between pr-6'}>
          <InfoComponent title={'Odjel/i'} value={(departments || []).map(d => d.name).join('; ')} />
          <InfoComponent
            title={'Uloga'}
            value={
              (managingDepartments || []).length > 0
                ? `Voditelj/ica - ${(managingDepartments || []).map(md => md.name).join('; ')}`
                : 'Zaposlenik'
            }
          />
          <InfoComponent title={'Radno vrijeme'} value={hoursTypeTitle[hoursType]} />
          <InfoComponent title={'Datum početka rada'} value={arrival ? dayjs(arrival).format('DD/MM/YYYY') : '-'} />
          <InfoComponent
            title={'Status zaposlenja'}
            value={
              <div className={'text-primary-light flex items-start rounded bg-zinc-100 px-3 py-2 text-center'}>
                {user.isActive && !departure ? 'Aktivan' : 'Neaktivan'}
              </div>
            }
          />
        </div>
      </div>
      <EditEmployeeDrawer
        employee={data?.data}
        setIsEditEmployeeDrawerOpen={setIsEditEmployeeDrawerOpen}
        isEditEmployeeDrawerOpen={isEditEmployeeDrawerOpen}
      />
    </div>
  )
}
