import Assets from '@assets/assets'
import { Modal } from '@components/ui'
import { useEffect } from 'react'

export const ConfirmationModal = ({
  title,
  isOpen,
  onClose,
}: {
  title: string
  isOpen: boolean
  onClose: () => void
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onClose()
      }, 2000)
    }
  }, [isOpen, onClose])

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <div className={'flex flex-col items-center gap-y-[21px] rounded-[6px] bg-white p-[60px]'}>
        <img src={Assets.CheckCircleIcon} alt={'check-circle'} />
        <div className={'text-modal-title'}>{title}</div>
      </div>
    </Modal>
  )
}
