export const generateSeed = (val: string) => {
  let hash = 0
  if (!val || val.length === 0) return hash
  const cleaned = val.toLowerCase().replace(/[^a-zA-Z ]/g, '')
  for (let i = 0; i < cleaned.length; i++) {
    const chr = cleaned.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return Math.abs(hash)
}
