import { IApiError } from '@interfaces/apiError'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect } from 'react'

interface IUseRefreshQueryOptions {
  enabled?: boolean
}

export const useRefreshQuery = <T = unknown>(
  queryKey: string[],
  query: () => Promise<AxiosResponse<T, IApiError>>,
  options?: IUseRefreshQueryOptions,
) => {
  const { filter } = useGlobalFilter()
  const queryData = useQuery({
    queryKey: [...queryKey, filter.year, ...filter.month, ...filter.quartal],
    queryFn: query,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    refetchInterval: 0,
    ...options,
  })
  useEffect(() => {
    queryData.refetch()
  }, [filter])

  return queryData
}
