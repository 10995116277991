import { IForgotPassword, ILogin, ILoginResp, IResetPassword } from '@interfaces/auth'
import { IUpdateUser, IUser } from '@interfaces/user'
import { AxiosRequestConfig } from 'axios'
import { get, patch, post } from './axiosInstance'

export const login = (loginData: ILogin) => post<ILogin, ILoginResp>('/auth/signin', loginData)

export const logout = () =>
  get('/auth/signout', { headers: { 'Cache-control': 'no-cache', Pragma: 'no-cache', Expires: 0 } })

export const getMe = async (config?: AxiosRequestConfig<any> | undefined) => get<IUser>('/auth/me', config)

export const updateMe = async (updateUser: IUpdateUser) => patch<IUser>('/auth/me', updateUser)

export const resetPassword = async (resetPasswordData: IResetPassword) =>
  post('/auth/reset-password', resetPasswordData)

export const forgotPassword = async (forgotPasswordData: IForgotPassword) =>
  post('/auth/forgot-password', forgotPasswordData)
