import { useGetCompanyById } from '@hooks/api/company/useGetCompanyById'
import { useParams } from 'react-router-dom'
import { CompanySettingsContent } from './Components/CompanySettingsContent'
import { CompanySettingsHeader } from './Components/CompanySettingsHeader'

export const CompanySettingsPage = () => {
  const { id } = useParams()
  const { data } = useGetCompanyById({ id })

  if (!data) {
    return null
  }

  return (
    <>
      <CompanySettingsHeader />
      <CompanySettingsContent />
    </>
  )
}
