import { ComponentWrapper, PageContent } from '@components/ui'
import 'twin.macro'
import { AccountContent } from './Components/AccountContent'

export const Account = () => {
  return (
    <PageContent className={'flex h-full items-center justify-center'}>
      <ComponentWrapper className={'max-w-[900px] px-8 pb-8'}>
        <AccountContent />
      </ComponentWrapper>
    </PageContent>
  )
}
