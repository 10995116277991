import { ClientList, ClientOverview, ClientsSummary, ClientSummary, ClientYearCompare } from '@interfaces/client'
import { ICreateClient } from '../../types/client'
import { get, post } from './axiosInstance'

export const clientsSummary = () => get<ClientsSummary>('/stats/client/summary')

export const clientsList = () => get<ClientList>('/stats/client/list')

export const clientSummary = (id: string) => get<ClientSummary>(`/stats/client/${id}/summary`)

export const clientOverview = (id: string) => get<ClientOverview>(`/stats/client/${id}/overview`)

export const clientYearCompare = (id: string) => get<ClientYearCompare>(`/stats/client/${id}/year-compare`)

export const createClient = (client: ICreateClient) => post('/client', client)
