import { resetPassword } from '@api/auth'
import { ResetPasswordForm } from '@components/forms'
import { IResetPassword } from '@interfaces/auth'
import { notificationStore } from '@stores/notification.store'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import 'twin.macro'

export const ResetPassword = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigation = useNavigate()
  const { addNotification } = notificationStore.getState()

  const onSubmit = async (values: FieldValues) => {
    const data = await resetPassword({ ...values, token } as IResetPassword)
    if (data.status < 400) {
      addNotification({ title: 'Resetiraj lozinku', content: 'Uspješno', status: 'success' })
      navigation('/login')
    }
  }

  return (
    <div tw='flex h-screen items-center justify-center before:(absolute z-0 h-screen w-screen -translate-y-2 bg-login-background bg-cover)'>
      <div tw='relative z-10 flex flex-col rounded bg-white p-10 shadow-neutral-200 shadow-basic min-w-[430px]'>
        <span tw='mb-6 border-b-accent pb-6 text-xl border-b-[0.5px]'>
          <span tw='text-primary'>{'Business '}</span>Monitor
        </span>
        <span tw='mb-2 font-normal text-title'>{t('login.reset_password')}</span>
        <ResetPasswordForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}
