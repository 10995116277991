import { axiosApiInstance } from '@api/axiosInstance'
import { UpdateUserDTO } from '@api/generated-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUserFunctions = () => {
  const { user } = axiosApiInstance
  const queryClient = useQueryClient()
  const createUser = useMutation({
    mutationFn: user.userControllerCreateUser,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['company-list', variables.companyId] })
    },
  })

  const updateUser = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateUserDTO }) => user.userControllerUpdateUser(id, data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['company-list', variables.data.companyId] })
    },
  })

  return {
    createUser,
    updateUser,
  }
}
