import assets from '@assets/assets'
import { FC, PropsWithChildren } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

interface ICollapseProps {
  isOpen?: boolean
  Icon?: FC
  Title: FC
  onClick?: () => void
  hasBorder?: boolean
  isOpenExternal?: boolean
}

export const Collapse: FC<PropsWithChildren<ICollapseProps>> = ({ Title, onClick, isOpen, children, hasBorder }) => {
  return (
    <>
      <div
        className={'flex cursor-pointer items-center gap-4'}
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
        css={[hasBorder && tw`border-gray-200 border-b`]}
      >
        <img
          alt={'caret-down'}
          src={assets.CaretDownFull}
          css={[isOpen && tw`rotate-180`]}
          tw='transition-all duration-200'
        />
        <Title />
      </div>
      <div tw='origin-top transition-all duration-200' css={[!isOpen && tw`max-h-0 scale-y-0 opacity-0`]}>
        {children}
      </div>
    </>
  )
}
