import { IUpdateUser, IUser } from '@interfaces/user'
import { FieldValues } from 'react-hook-form'

export const pruneUserValues = (initialValues: IUser, updatedValues: FieldValues) => {
  Object.keys(updatedValues).forEach((k: string) => {
    if (Object.keys(initialValues).includes(k) && initialValues[k as keyof IUser] === updatedValues[k]) {
      delete updatedValues[k]
    }
    if (typeof updatedValues[k] !== 'boolean' && !updatedValues[k]) {
      delete updatedValues[k]
    }
  })

  if (!Object.keys(updatedValues).length) {
    return undefined
  }

  return updatedValues as IUpdateUser
}
