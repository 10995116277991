import { useClientsSummary } from '@hooks/api/client'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'

export const ClientsHeader = () => {
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  const { data, isLoading } = useClientsSummary()

  const { expense, income, margin, profit, profit_no_pay } = data?.data ?? {}

  return (
    <PageHeader title={t('shared.clients') ?? undefined}>
      {/*<section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>*/}
      {/*  <Card*/}
      {/*    title={<span>{t('clients.income_clients')}</span>}*/}
      {/*    subtitle={<span>{formatCurrency(income)}</span>}*/}
      {/*    loading={isLoading}*/}
      {/*  />*/}
      {/*  <Card*/}
      {/*    title={<span>{t('clients.direct_costs')}</span>}*/}
      {/*    subtitle={<span>{formatCurrency(expense)}</span>}*/}
      {/*    loading={isLoading}*/}
      {/*  />*/}
      {/*  <Card*/}
      {/*    title={<span>{t('clients.profit_no_pay')}</span>}*/}
      {/*    subtitle={<span>{formatCurrency(profit_no_pay)}</span>}*/}
      {/*    loading={isLoading}*/}
      {/*  />*/}
      {/*  <Card*/}
      {/*    title={<span>{t('clients.profit')}</span>}*/}
      {/*    subtitle={<span>{formatCurrency(profit)}</span>}*/}
      {/*    loading={isLoading}*/}
      {/*  />*/}
      {/*  <Card*/}
      {/*    title={<span>{t('clients.margin')}</span>}*/}
      {/*    subtitle={<>{margin ? margin + '%' : '-'}</>}*/}
      {/*    loading={isLoading}*/}
      {/*  />*/}
      {/*</section>*/}
    </PageHeader>
  )
}
