import Assets from '@assets/assets'
import { Button, Modal } from '@components/ui'
import { useWorkingHoursManagementFunctions } from '@hooks/api/working-hours-management/useWorkingHoursManagementFunctions'
import { useGetWorkingHoursStatusByDate } from '@hooks/api/working-hours/useGetWorkingHoursStatusByDate'
import { useMonthAvailableWorkingHours } from '@hooks/api/working-hours/useMonthAvailableWorkingHours'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationModal } from '../../WorkingHours/Components/NotificationModal'
import { NonWorkingHoursTable } from '../../WorkingHours/Month/Components/NonWorkingHoursTable'
import { WorkingHoursLine } from '../../WorkingHours/Month/Components/WorkingHoursLine'
import { WorkingHoursTable } from '../../WorkingHours/Month/Components/WorkingHoursTable'
import { ResendHoursModal } from './ResendHoursModal'

interface IHourManagementModalProps {
  date: string
  employeeId: number
  isArchive?: boolean
  departmentId: number
  isOpen: boolean
  onClose: () => void
}

export const HoursManagementContentModal = ({
  isOpen,
  onClose,
  departmentId,
  isArchive,
  employeeId,
  date,
}: IHourManagementModalProps) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)

  const { approveWorkingHours } = useWorkingHoursManagementFunctions()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: statusData, refetch } = useGetWorkingHoursStatusByDate({ date, employeeId })
  const [year, month] = (date || '').split('-')
  const { data: availableHours = 0 } = useMonthAvailableWorkingHours({ month: Number(month), year: Number(year) })
  const tempWorkingHoursForDepartment = useMemo(
    () => (statusData?.workingHours || []).find(wh => wh?.department?.id === departmentId),
    [departmentId, statusData?.workingHours],
  )

  const tempWorkingHoursForOtherDepartment = useMemo(
    () => (statusData?.workingHours || []).find(wh => wh?.department?.id !== departmentId),
    [departmentId, statusData?.workingHours],
  )

  const allData = useMemo(
    () => [...(tempWorkingHoursForDepartment?.workingHours || []), ...(statusData?.nonWorkingHours || [])],
    [statusData?.nonWorkingHours, tempWorkingHoursForDepartment],
  )

  const note = tempWorkingHoursForDepartment?.note
    ? { note: tempWorkingHoursForDepartment.note, department: tempWorkingHoursForDepartment.department }
    : undefined

  const totalHours = allData.reduce(
    (previousValue, currentValue) => previousValue + Math.max(Number(currentValue.hours), 0),
    0,
  )

  const handleOpenApproveModal = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
    setIsApproveModalOpen(true)
  }, [])

  const handleOpenResendModal = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
    setIsResendModalOpen(true)
  }, [])

  const handleApprove = useCallback(
    async e => {
      e.stopPropagation()
      e.preventDefault()
      if (tempWorkingHoursForDepartment?.id) {
        await approveWorkingHours.mutateAsync(tempWorkingHoursForDepartment?.id, {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] }),
        })
        setIsApproveModalOpen(false)
        await refetch()
        onClose()
      }
    },
    [approveWorkingHours, onClose, queryClient, refetch, tempWorkingHoursForDepartment?.id],
  )

  return (
    <>
      <Modal isOpen={isOpen && !(isApproveModalOpen || isResendModalOpen)} close={onClose}>
        <div className={'relative max-w-4xl rounded-md bg-white px-7 py-6'}>
          <div className={'mb-4'}>
            <div className={'mb-8 flex justify-between'}>
              <div className={'text-[24px] text-gray-600'}>{tempWorkingHoursForDepartment?.employee?.name}</div>
              <div className={'absolute right-6 top-4 cursor-pointer'} onClick={onClose}>
                <img src={Assets.XIcon} alt={'close'} />
              </div>
            </div>
            {!!note?.note && (
              <div className={'mt-4 mb-6 space-y-2'}>
                <div className={'flex space-x-2'}>
                  <img src={Assets.NoteIcon} alt={'note-icon'} />
                  <div>{t('working_hours.note')}</div>
                </div>
                <div className={'max-w-5xl'}>{note?.note}</div>
              </div>
            )}
            <WorkingHoursLine
              allData={allData}
              notEnteredHours={0}
              availableHours={availableHours}
              totalHours={totalHours}
              hoursByDepartment={
                tempWorkingHoursForDepartment
                  ? [
                      {
                        department: tempWorkingHoursForDepartment?.department,
                        hours: (tempWorkingHoursForDepartment?.workingHours || []).reduce(
                          (previousValue, currentValue) => previousValue + currentValue.hours,
                          0,
                        ),
                      },
                    ]
                  : []
              }
            />
            {tempWorkingHoursForOtherDepartment?.department?.name && (
              <div className={'mt-2'}>
                Preostali radni sati evidentirani su u odjelu {tempWorkingHoursForOtherDepartment.department.name}
              </div>
            )}
          </div>
          {tempWorkingHoursForDepartment && (
            <div className={'flex items-center gap-4'}>
              <span className='text-lg font-medium leading-relaxed text-neutral-700'>
                Odjel {tempWorkingHoursForDepartment?.department.name}
              </span>
            </div>
          )}
          {(tempWorkingHoursForDepartment?.workingHours || []).length > 0 && (
            <WorkingHoursTable
              notEnteredHours={0}
              data={tempWorkingHoursForDepartment?.workingHours || []}
              availableHours={availableHours}
              onDeleteRow={() => {}}
              isEditable={false}
              onEditColumn={() => {}}
            />
          )}
          {(statusData?.nonWorkingHours || []).length > 0 && (
            <NonWorkingHoursTable
              notEnteredHours={0}
              data={statusData?.nonWorkingHours || []}
              availableHours={availableHours}
              onDeleteRow={() => {}}
              isEditable={false}
              onEditColumn={() => {}}
            />
          )}
          {!isArchive && (
            <div className={'mt-4 flex w-full justify-end'}>
              <div className={'flex items-center gap-4'}>
                <Button onClick={handleOpenApproveModal}>{t('working_hours_management.approve')}</Button>
                <Button buttonType={'secondary'} onClick={handleOpenResendModal}>
                  {t('working_hours_management.send_to_change')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {!isArchive && (
        <NotificationModal
          isOpen={isApproveModalOpen}
          onClose={() => {
            setIsApproveModalOpen(false)
            onClose()
          }}
          title={'Odobri unesene radne sate'}
          subtitle={`Potvrđivanjem ove radnje radni sati za osobu ${tempWorkingHoursForDepartment?.employee?.name} bit će odobreni. Pregledati ih možete u arhivi.`}
          secondaryButtonText={'Odustani'}
          primaryButtonText={'Odobri'}
          isLoading={approveWorkingHours.isPending}
          onPrimaryClick={handleApprove}
        />
      )}
      {!isArchive && tempWorkingHoursForDepartment?.id && (
        <ResendHoursModal
          isOpen={isResendModalOpen}
          hoursId={tempWorkingHoursForDepartment?.id}
          onClose={() => {
            setIsResendModalOpen(false)
            onClose()
          }}
          refetch={refetch}
        />
      )}
    </>
  )
}
