import { Button } from '@components/ui'
import { parseCurrency } from '@utils/formatCurrency'
import { FC, useState } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

interface IIncomeAndCostItem {
  selectedField: string
  setSelectedField: any
  data: any
  updateRows: any
  val: string
}

export const IncomeAndCostItem: FC<IIncomeAndCostItem> = ({
  selectedField,
  setSelectedField,
  data,
  updateRows,
  val,
}) => {
  const [value, setValue] = useState<number | null>(null)

  return (
    <div
      tw='h-full w-full'
      onClick={
        !['totals', 'others'].includes(data.key)
          ? () => setSelectedField(data['key'] + val)
          : () => setSelectedField('')
      }
      css={[!['totals', 'others'].includes(data.key) && tw`cursor-pointer`]}
    >
      <span css={[selectedField == data['key'] + val && tw`hidden`]}>{data[val] ? data[val] : '-'}</span>
      {!['totals', 'others'].includes(data.key) && selectedField == data['key'] + val && (
        <div tw='relative'>
          <input
            tw='m-0 w-full rounded border-accent border border-solid bg-white bg-clip-padding px-3 py-2 transition-colors min-w-[140px] focus:(border-primary text-gray-700)'
            className='hide-input-arrows'
            type={'number'}
            defaultValue={parseCurrency(data[val])}
            min={0}
            max={100}
            onChange={e => {
              const nvalue = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber
              setValue(nvalue)
            }}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.stopPropagation()
                updateRows(val, data.key, value)
              }
            }}
          />
          <Button
            tw='absolute right-0 top-0 flex h-full w-1 min-w-0 items-center justify-center'
            buttonType='secondary'
            onClick={e => {
              e.stopPropagation()
              updateRows(val, data.key, value)
            }}
          >
            <i className='fa fa-check' />
          </Button>
        </div>
      )}
    </div>
  )
}
