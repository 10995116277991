import { axiosApiInstance } from '@api/axiosInstance'
import { WorkingHoursControllerGetAvailableHoursInMonthParams } from '@api/generated-api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export const useMonthAvailableWorkingHours = (query: WorkingHoursControllerGetAvailableHoursInMonthParams) => {
  const { workingHours } = axiosApiInstance
  return useQuery<AxiosResponse<number>, null, number>({
    queryKey: ['available-working-hours', query.month, query.year],
    queryFn: () => workingHours.workingHoursControllerGetAvailableHoursInMonth(query),
    enabled: !!query.month && !!query.year,
    select: data => data.data,
  })
}
