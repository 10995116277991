import { FC, PropsWithChildren } from 'react'
import 'twin.macro'

interface ISkeletonProps {
  showSkeleton?: boolean
}

export const Skeleton: FC<PropsWithChildren<ISkeletonProps>> = ({ children, showSkeleton, ...props }) => {
  if (!showSkeleton && children) return <>{children}</>

  return (
    <div {...props} tw='h-full w-full animate-pulse rounded-lg bg-gray-200 duration-300'>
      {children}
    </div>
  )
}
