import { Card, ComponentWrapper, DoughnutChart } from '@components/ui'
import { useEmployeeDetails, useEmployeeSummary } from '@hooks/api/employee'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { ClientDetails } from '@interfaces/employee'
import { useMe } from '@stores/me.store'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import 'twin.macro'

const colors = ['#B7DEDA', '#C9CCE2', '#DCEDC8', '#FFCDD2', '#FFE0B2']

const getAppropriateHours = ({ item, type }: { item: ClientDetails; type: 'normal' | 'overtime' | 'total' }) => {
  switch (type) {
    case 'normal':
      return item.hours
    case 'overtime':
      return item.overtime_hours
    case 'total':
      return item.hours + item.overtime_hours
  }
}

function processItems(items: ClientDetails[], type: 'normal' | 'overtime' | 'total') {
  // Step 1: Sort the array based on hours in descending order
  items.sort((a, b) => getAppropriateHours({ item: b, type }) - getAppropriateHours({ item: a, type }))
  if (items.length <= 5) {
    return items.map((client, index) => ({
      name: client.client,
      value: Number(getAppropriateHours({ item: client, type }).toFixed(0)),
      fill: colors[index],
    }))
  } else {
    const topFour = items.slice(0, 4)

    // Step 3: Sum the hours of the remaining items
    const otherItems = items.slice(4)
    const otherHours = otherItems.reduce((sum, item) => sum + getAppropriateHours({ item, type }), 0)

    // Step 4: Create the "Other" item and combine with topFour
    if (otherItems.length > 0) {
      topFour.push({
        client: 'Ostalo',
        hours: Number(otherHours.toFixed(2)),
        salary: 0,
        client_id: 0,
        overtime_hours: 0,
      })
    }

    return topFour.map((client, index) => ({
      name: client.client,
      value: Number(getAppropriateHours({ item: client, type }).toFixed(0)),
      fill: colors[index],
    }))
  }
}

export const EmployeeOverview = () => {
  const { id } = useParams()
  const formatCurrency = useFormatCurrency()
  const { t } = useTranslation()
  const { me } = useMe()
  const { data } = useEmployeeDetails(id)

  const { data: empSummaryData, isLoading } = useEmployeeSummary(id)

  const { salaries } = empSummaryData?.data ?? {}
  const { hours, clients, hourly, overtime_hours } = data?.data ?? {}

  const tempClients = processItems(clients || [], 'normal')
  const tempOvertimeClients = processItems(clients || [], 'overtime')
  const tempTotalClients = processItems(clients || [], 'total')
  const latestSalary = (salaries || []).sort((a, b) => (a.id > b.id ? -1 : 1))?.[0]

  const parsedClients = tempClients.filter(tc => tc.value > 0)

  return (
    <>
      {!!latestSalary && (
        <section tw='mb-10 grid grid-cols-6 gap-4 max-xl:grid-cols-3 max-md:grid-cols-2'>
          <Card loading={isLoading} title={<span>{t('shared.neto')}</span>}>
            {formatCurrency(latestSalary.neto)}
          </Card>
          <Card loading={isLoading} title={<span>{t('shared.bruto')}</span>}>
            {formatCurrency(latestSalary.bruto2)}
          </Card>
          <Card loading={isLoading} title={<span>{t('shared.pay_in_kind')}</span>}>
            {formatCurrency(latestSalary.kind)}
          </Card>
          <Card loading={isLoading} title={<span>{t('shared.other_expenses')}</span>}>
            {formatCurrency(latestSalary.other)}
          </Card>
          <Card loading={isLoading} title={<span>{t('shared.overall_expenses')}</span>}>
            {formatCurrency(latestSalary.total)}
          </Card>
          <Card loading={isLoading} title={<span>{t('shared.hourly_wage')}</span>}>
            {formatCurrency(hourly)}
          </Card>
        </section>
      )}
      {(tempClients || []).length > 0 && (tempOvertimeClients || []).length > 0 && me?.companySettings?.hasOvertime ? (
        <section tw='mb-10 grid grid-cols-3 gap-7'>
          <div tw='flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 text-sm shadow-sm'>
            <div className='mb-4 text-lg font-normal leading-snug text-black'>{t('shared.worked_hours')}</div>
            <DoughnutChart data={tempClients} text={Math.round(Number(hours) ?? 0)} />
          </div>
          {(tempOvertimeClients || []).length > 0 && (
            <div tw='flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 text-sm shadow-sm'>
              <div className='mb-4 text-lg font-normal leading-snug text-black'>{t('shared.overtime')}</div>
              <DoughnutChart
                labels={[t('shared.overtime') ?? 'Prekovremeni']}
                data={tempOvertimeClients}
                text={Math.round(Number(overtime_hours) ?? 0)}
              />
            </div>
          )}
          <div tw='flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 text-sm shadow-sm'>
            <div className='mb-4 text-lg font-normal leading-snug text-black'>{t('shared.total_worked_hours')}</div>
            <DoughnutChart
              data={tempTotalClients}
              text={Math.round((Number(hours) ?? 0) + (Number(overtime_hours) ?? 0))}
            />
          </div>
        </section>
      ) : (
        <ComponentWrapper>
          <div className={'mb-6 flex items-center justify-between'}>
            <div className={'text-[20px]'}>Radni sati</div>
            <div>
              <span className='text-sm font-normal leading-tight text-slate-500'>Ukupni radni sati: </span>
              {hours}
            </div>
          </div>
          {parsedClients.length > 0 ? (
            <div className={'mb-6 flex items-center gap-11'}>
              {parsedClients.map(tc => (
                <div key={tc.name} className={'flex items-center gap-1'}>
                  <div style={{ backgroundColor: tc.fill, width: 18, height: 18, borderRadius: 12 }} />
                  <div className='text-sm font-normal leading-tight text-slate-500'>{tc.name}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={'flex w-full justify-center'}>Nema unesenih radnih sati za odabrano razdoblje</div>
          )}
          <Tooltip id={'state-tooltip'} />
          <div className={'flex w-full gap-1'}>
            {tempClients
              .filter(tc => tc.value > 0)
              .map(p => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const color = p.fill
                return (
                  <div
                    data-tooltip-id='state-tooltip'
                    data-tooltip-content={`${p.name} - ${p.value.toString()}`}
                    key={p.value}
                    className={'flex items-center truncate px-2'}
                    style={{
                      backgroundColor: color,
                      height: 33,
                      flex: p.value / (hours || 0),
                      borderRadius: 4,
                    }}
                  >
                    <p className={'truncate text-[14px] leading-loose'}>{p.name}</p>
                  </div>
                )
              })}
          </div>
        </ComponentWrapper>
      )}
    </>
  )
}
