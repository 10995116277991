import { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { Notifications } from './Notifications'

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      {createPortal(<Notifications />, document.body)}
    </>
  )
}
