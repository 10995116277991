import { Navigation } from '@components/ui'
import { Account } from '@pages'
import { ColorContext } from '@utils/colorContext'
import { useColors } from '@utils/randomColor'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'twin.macro'
import { CompanyListPage } from '../pages/CompanyList/CompanyList'
import { CompanySettingsPage } from '../pages/CompanySettings/CompanySettings'
import { privateRoutes } from './routes'

export const SuperAdminRouter = () => {
  const colors = useColors()
  return (
    <ColorContext.Provider value={colors as any}>
      <div tw='flex overflow-hidden'>
        <Navigation />
        <div tw='flex h-screen w-full flex-col sm:ml-20'>
          <div tw='h-full overflow-scroll bg-background-light bg-slate-50'>
            <Routes>
              <Route path={privateRoutes.COMPANY_LIST_PATH} element={<CompanyListPage />} />
              <Route path={privateRoutes.COMPANY_SETTINGS_PATH} element={<CompanySettingsPage />} />
              <Route path={privateRoutes.ACCOUNT_PATH} element={<Account />} />
              <Route path={privateRoutes.NOT_FOUND_PATH} element={<Navigate to={privateRoutes.COMPANY_LIST_PATH} />} />
              <Route path={privateRoutes.EMPTY} element={<Navigate to={privateRoutes.COMPANY_LIST_PATH} />} />
            </Routes>
          </div>
        </div>
      </div>
    </ColorContext.Provider>
  )
}
