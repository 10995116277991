import Assets from '@assets/assets'
import { PageHeader } from '@components/ui'
import { useGetCompanyById } from '@hooks/api/company/useGetCompanyById'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

export const CompanySettingsHeader: FC = () => {
  const { id } = useParams()
  const { data } = useGetCompanyById({ id })

  return (
    <PageHeader
      title={data?.name || ''}
      hidePeriod
      extra={
        <div className='mt-4 text-base font-normal leading-snug text-gray-600'>
          Kreiran: {dayjs(data.createdAt).format('MMMM, YYYY')}
        </div>
      }
      topContent={
        <div
          className='-mt-4 mb-4 cursor-pointer'
          onClick={() => {
            history.go(-1)
          }}
        >
          <img src={Assets.ArrowLeft} className='transition-transform' alt={'arrow-back'} />
        </div>
      }
    />
  )
}
