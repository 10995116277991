import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type GlobalFilter = {
  location: string
  year: number
  quartal: number[]
  month: number[]
}

interface IGlobalFilterStore {
  filter: GlobalFilter
  updateFilter: (filter: Partial<GlobalFilter>) => void
  setFilter: (filter: GlobalFilter) => void
  clearFilter: () => void
}

const emptyGlobalFilter: GlobalFilter = {
  location: 'Sve lokacije',
  year: dayjs().year(),
  quartal: [],
  month: [],
}

export const useGlobalFilter = create(
  persist<IGlobalFilterStore>(
    (set, get) => ({
      filter: emptyGlobalFilter,
      setFilter: (filter: GlobalFilter) => set(() => ({ filter })),
      updateFilter: (filter: Partial<GlobalFilter>) => set(state => ({ filter: { ...state.filter, ...filter } })),
      clearFilter: () => set(() => ({ filter: emptyGlobalFilter })),
    }),
    {
      name: 'global-filter-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)
