import { DepartmentDto } from '@api/generated-api'
import { ComponentWrapper, PageContent, SpinningLoader, Tabs } from '@components/ui'
import { useGetActiveWorkingHoursManagement } from '@hooks/api/working-hours-management/useGetActiveWorkingHoursManagement'
import { useGetNotSentWorkingHoursManagement } from '@hooks/api/working-hours-management/useGetNotSentWorkingHoursManagement'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { ActiveWorkingHoursManagementTable } from './Tables/ActiveWorkingHoursManagementTable'
import { ArchiveWorkingHoursManagementTable } from './Tables/ArchiveWorkingHoursManagementTable'
import { ClientsWorkingHoursManagementTable } from './Tables/ClientsWorkingHoursManagementTable'
import { NotSentWorkingHoursManagementTable } from './Tables/NotSentWorkingHoursManagementTable'

export const WorkingHoursManagementContent = ({ selectedDepartment }: { selectedDepartment: DepartmentDto }) => {
  const { data: activeData = [], isLoading: isActiveLoading } = useGetActiveWorkingHoursManagement({
    departmentId: selectedDepartment?.id,
  })
  const { data: notSentData, isLoading: isNotSentLoading } = useGetNotSentWorkingHoursManagement({
    departmentId: selectedDepartment?.id,
  })

  const { t } = useTranslation()

  const isLoading = useMemo(() => isActiveLoading || isNotSentLoading, [isActiveLoading, isNotSentLoading])

  return (
    <>
      {isLoading ? (
        <ComponentWrapper tw={'flex h-1/2 items-center'}>
          <SpinningLoader size={'large'} />
        </ComponentWrapper>
      ) : (
        <Tabs
          $tabTwStyle={tw`text-center min-w-[150px]`}
          tabHeaderContainerTwStyle={tw`-mt-4 bg-background px-7`}
          tabs={[
            {
              content: (
                <PageContent tw={'pt-0'}>
                  <ActiveWorkingHoursManagementTable
                    departmentId={selectedDepartment?.id}
                    data={activeData}
                    isLoading={isActiveLoading}
                  />
                </PageContent>
              ),
              key: t('working_hours_management.current'),
              title: t('working_hours_management.current'),
            },
            {
              content: (
                <PageContent tw={'pt-0'}>
                  <NotSentWorkingHoursManagementTable data={notSentData || []} />
                </PageContent>
              ),
              key: t('working_hours_management.not_sent'),
              title: t('working_hours_management.not_sent'),
            },
            {
              content: (
                <PageContent tw={'pt-0'}>
                  <ArchiveWorkingHoursManagementTable departmentId={selectedDepartment?.id} />
                </PageContent>
              ),
              key: t('working_hours_management.archive'),
              title: t('working_hours_management.archive'),
            },
            {
              content: (
                <PageContent tw={'pt-0'}>
                  <ClientsWorkingHoursManagementTable departmentId={selectedDepartment?.id} />
                </PageContent>
              ),
              key: t('working_hours_management.clients'),
              title: t('working_hours_management.clients'),
            },
          ]}
        />
      )}
    </>
  )
}
