import { UpdateEmployeeDTO, UpdateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { DatePicker, TagSelect, TextInput } from '@components/inputs'
import { Button, Dropdown } from '@components/ui'
import { useDepartmentsList } from '@hooks/api/department'
import { EmployeeSummary } from '@interfaces/employee'
import { useMe } from '@stores/me.store'
import dayjs from 'dayjs'
import { FC, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { DropdownItem } from '../../../components/UI/Dropdown/Dropdown'

interface EditEmployeeFormProps {
  onUpdate: (employee: UpdateEmployeeDTO) => void
  methods: UseFormReturn<UpdateEmployeeDTO, any>
  employee: EmployeeSummary
  handleClose?: () => void
}

export const EditEmployeeForm: FC<EditEmployeeFormProps> = ({ onUpdate, methods, employee, handleClose }) => {
  const { me } = useMe()
  const { t } = useTranslation()
  const { data, isLoading } = useDepartmentsList()
  const workTimeItems = useMemo(
    () =>
      [
        {
          key: UpdateEmployeeDtoHoursTypeEnum.FULLTIME,
          title: 'Puno radno vrijeme',
          onClick: e => methods.setValue('hoursType', e.key),
        },
        {
          key: UpdateEmployeeDtoHoursTypeEnum.HALFTIME,
          title: 'Pola radnog vremena',
          onClick: e => methods.setValue('hoursType', e.key),
        },
        {
          key: UpdateEmployeeDtoHoursTypeEnum.STUDENT,
          title: 'Student',
          onClick: e => methods.setValue('hoursType', e.key),
        },
      ] as DropdownItem[],
    [methods],
  )

  const workTime = methods.watch('hoursType')
  const arrival = methods.watch('arrival')

  const restDepartments = (employee?.departments || []).filter(
    d => !(data?.data || []).find(dep => Number(dep?.id) === Number(d?.id)),
  )

  return (
    <Form methods={methods} onSubmit={onUpdate} tw={'flex flex-1 flex-col justify-between'}>
      <section className={'flex flex-1 flex-col'}>
        <div className='text-2xl font-normal leading-[28.80px] text-neutral-500'>Osnovni podaci</div>
        <section tw='mb-7 grid grid-cols-2 gap-x-6 border-b pb-4'>
          <TextInput id='name' label={t('employees.name')} placeholder={t('shared.placeholders.enter')} />
          <TextInput id='email' label={t('employees.email')} placeholder={t('shared.placeholders.email')} />
        </section>
        <div className='text-2xl font-normal leading-[28.80px] text-neutral-500'>Podaci o zaposlenju</div>
        <section tw='mb-8 grid grid-cols-3 gap-x-6'>
          <DatePicker id='arrival' label={t('shared.dateOfArrival') as string} />
          <div>
            <div className='text-secondary inline-block py-3 text-sm font-semibold'>Radno vrijeme</div>
            <Dropdown trigger={'click'} items={workTimeItems}>
              {workTimeItems.find(wti => wti.key === workTime)?.title || '-'}
            </Dropdown>
          </div>
          <DatePicker
            id='departure'
            placeholder={'Odaberi'}
            label={t('shared.dateOfDeparture') as string}
            calendarProps={{ minDate: dayjs(arrival).toDate() }}
          />
        </section>
        <TagSelect
          id='departments'
          name='departments'
          sublabel={'Osobu možete staviti samo u odjel u kojem ste definirati kao voditelj. '}
          label={t('employees.select_departments')}
          options={
            isLoading
              ? []
              : (data?.data || []).map(dep => ({
                  title: dep.name,
                  value: dep.id,
                  employees: dep.employees,
                })) ?? []
          }
        />
        {(restDepartments || []).length > 0 && (
          <div>
            <span className="font-['IBM Plex Sans'] text-sm font-normal leading-tight text-zinc-700">
              Osoba se nalazi i u {(restDepartments || []).length > 1 ? 'odjelima' : 'odjelu'}:{' '}
            </span>
            <span className="font-['IBM Plex Sans'] text-sm font-medium leading-tight text-zinc-700">
              {restDepartments.map(rd => rd.name).join(', ')}
            </span>
          </div>
        )}
      </section>

      <section className='mt-4 flex justify-end gap-4'>
        <Button tw='font-medium uppercase' type='button' buttonType={'secondary'} onClick={handleClose}>
          {t('shared.give_up')}
        </Button>
        <Button tw='font-medium text-white uppercase' type='submit'>
          {t('shared.save')}
        </Button>
      </section>
    </Form>
  )
}
