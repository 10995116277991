import { GlobalFilterForm } from '@components/forms'
import { GlobalFilter, useGlobalFilter } from '@stores/globalFilter.store'
import { FC } from 'react'
import 'twin.macro'

interface IFilterProps {
  closeDrawer: () => void
}

export const Filter: FC<IFilterProps> = ({ closeDrawer }) => {
  const { filter, updateFilter } = useGlobalFilter()

  const onUpdate = (filterUpdate: Partial<GlobalFilter>) => {
    updateFilter(filterUpdate)
    closeDrawer()
  }

  return (
    <div tw='px-7 min-w-[315px]'>
      <GlobalFilterForm onUpdate={onUpdate} initialValue={filter} />
    </div>
  )
}
