import { axiosApiInstance } from '@api/axiosInstance'
import { ChangeStatusDTO, UpdateCompanyDTO } from '@api/generated-api'
import { notificationStore } from '@stores/notification.store'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCompanyFunctions = () => {
  const { company } = axiosApiInstance
  const queryClient = useQueryClient()
  const { addNotification } = notificationStore.getState()

  const createCompany = useMutation({
    mutationFn: company.companyControllerCreateCompany,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['company-list'] })
      addNotification({
        title: `Klijent ${variables.name} uspješno dodan.`,
        autoClose: true,
        content: '',
        status: 'success',
      })
    },
  })

  const updateCompany = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateCompanyDTO }) =>
      company.companyControllerUpdateCompany(id, data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['company-list', variables.id] })
    },
  })

  const deleteCompany = useMutation({
    mutationFn: company.companyControllerDeleteCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company-list'] })
    },
  })

  const changeCompanyStatus = useMutation({
    mutationFn: ({ id, data }: { id: number; data: ChangeStatusDTO }) =>
      company.companyControllerChangeCompanyStatus(id, data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['company-list', variables.id] })
    },
  })
  return {
    createCompany,
    updateCompany,
    deleteCompany,
    changeCompanyStatus,
  }
}
