import { UpdateUserDtoRoleEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { Select, SwitchComponent, TextInput } from '@components/inputs'
import { Button, ComponentWrapper, PageContent, Table } from '@components/ui'
import { useCompanyFunctions } from '@hooks/api/company/useCompanyFunctions'
import { useGetCompanyById } from '@hooks/api/company/useGetCompanyById'
import { useUserFunctions } from '@hooks/api/user/useUserFunctions'
import { useOrder } from '@hooks/useOrder'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { EditDeleteRender } from '../../../components/Renders/EditDeleteRender'
import { NotificationModal } from '../../WorkingHours/Components/NotificationModal'
import { AddAdminDrawer } from './AddAdminDrawer'

export const CompanySettingsContent: FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetCompanyById({ id })
  const { t } = useTranslation()
  const {
    deleteCompany: { mutateAsync: deleteCompany, isPending },
    updateCompany: { mutateAsync: updateCompany },
    changeCompanyStatus: { mutateAsync: changeCompanyStatus },
  } = useCompanyFunctions()
  const {
    updateUser: { mutateAsync: updateUser },
  } = useUserFunctions()
  const [order, setOrder, orderData] = useOrder({ key: 'name', direction: 'ASC' })
  const [isDeleteCompanyOpen, setIsDeleteCompanyOpen] = useState<undefined | any>(undefined)
  const [isDeleteAdminOpen, setIsDeleteAdminOpen] = useState<undefined | any>(undefined)
  const [isAddAdminOpen, setIsAddAdminOpen] = useState<boolean>(false)
  const [selectedAdmin, setSelectedAdmin] = useState<any | { id: number; email: string; name: string }>(undefined)
  const methods = useForm({
    defaultValues: { ...data, isActive: !data.deletedAt ? 'active' : 'notActive' },
  })

  const activityTypes = useMemo(
    () => [
      { title: t('company.activity.active'), value: 'active' },
      { title: t('company.activity.not_active'), value: 'notActive' },
    ],
    [t],
  )

  const onSubmit = useCallback(
    async (val: any) => {
      if (val.isActive !== methods?.formState?.defaultValues?.isActive) {
        await changeCompanyStatus({ id, data: { isActive: val.isActive === 'active' } })
      }
      await updateCompany({ id, data: val })
    },
    [changeCompanyStatus, id, methods?.formState?.defaultValues?.isActive, updateCompany],
  )

  const onDeleteCompany = async () => {
    await deleteCompany(id, {
      onSuccess: () => {
        history.go(-1)
      },
    })
  }

  const onDeleteAdmin = async () => {
    await updateUser({ id: selectedAdmin.id, data: { role: UpdateUserDtoRoleEnum.USER, companyId: id } })
    setIsDeleteAdminOpen(false)
    setSelectedAdmin(undefined)
  }

  const multiplierSettings = [
    { title: 'x1', value: 1 },
    { title: 'x1.5', value: 1.5 },
    { title: 'x2', value: 2 },
  ]

  const hasOvertime = methods.watch('companySettings.hasOvertime')

  useEffect(() => {
    const subscription = methods.watch(methods.handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
  }, [methods, onSubmit])

  return (
    <PageContent>
      <ComponentWrapper loading={isLoading}>
        <Form methods={methods} onSubmit={onSubmit}>
          <div className={'mb-6 border-b'}>
            <span tw='text-icons-dark text-2xl'>{t('company.basic_information')}</span>
            <div className={'my-4 flex items-center justify-between gap-10'}>
              <div className={'flex gap-10'}>
                <TextInput label={t('company.company_name')} id='name' name='name' />
                <Select
                  id='isActive'
                  placeholder={t('working_hours.select') as string}
                  name='isActive'
                  label={t('working_hours.entry_type')}
                  options={activityTypes}
                />
              </div>
              <Button buttonType={'secondary'} type={'button'} onClick={() => setIsDeleteCompanyOpen(true)}>
                {t('company.delete_company')}
              </Button>
            </div>
          </div>
          <div className={'mb-6 border-b'}>
            <div tw='mb-4 text-icons-dark text-2xl'>{t('company.settings')}</div>
            <SwitchComponent
              label={'Prikaz prihoda i direktnih troškova'}
              sublabel={
                'Isključivanjem ove opcije onemogućuje se prikaz prihoda i direktnih troškova klijenta na svim relevantnim ekranima. Isključivanjem ove opcije, Dashboard i Tvrtka (P&L) neće biti prikazani.'
              }
              id={'companySettings.hasPL'}
            />
            <SwitchComponent
              label={'Omogući unos prekovremenih sati'}
              sublabel={
                <div className={'mt-1 flex items-center gap-4'}>
                  <Select
                    disabled={!hasOvertime}
                    containerTwStyle={tw`min-w-0 max-w-[100px]`}
                    id='companySettings.overtimeMultiplier'
                    placeholder={'1'}
                    name='companySettings.overtimeMultiplier'
                    label={''}
                    options={multiplierSettings}
                  />
                  težinski faktor
                </div>
              }
              id={'companySettings.hasOvertime'}
            />
            <SwitchComponent
              label={'Omogući “Rad od kuće” kao vrstu unosa radnih sati'}
              sublabel={'Odabir se odnosi na unos radnih sati.'}
              id={'companySettings.hasWFH'}
            />
          </div>
          <div tw='overflow-scroll'>
            <Table
              title={t('company.administrators.title') as string}
              order={order}
              headerActions={[
                <Button type={'button'} onClick={() => setIsAddAdminOpen(true)} key={'add-admin'}>
                  Dodaj admina
                </Button>,
              ]}
              columns={[
                {
                  key: 'name',
                  dataIndex: 'name',
                  title: t('company.administrators.name'),
                  order: setOrder,
                },
                {
                  key: 'email',
                  dataIndex: 'email',
                  title: t('company.administrators.email'),
                  order: setOrder,
                },
                {
                  key: 'trash',
                  dataIndex: 'trash',
                  title: '',
                  decoration: tw`w-[100px]`,
                  render: data => (
                    <EditDeleteRender
                      onEdit={() => {
                        setSelectedAdmin(data)
                        setIsAddAdminOpen(true)
                      }}
                      onDelete={() => {
                        setSelectedAdmin(data)
                        setIsDeleteAdminOpen(true)
                      }}
                    />
                  ),
                },
              ]}
              data={data?.admins || []}
              onRowClick={undefined}
            />
          </div>
        </Form>
        <NotificationModal
          isOpen={!!isDeleteCompanyOpen}
          onClose={() => setIsDeleteCompanyOpen(undefined)}
          title={'Obriši klijenta'}
          isLoading={isPending}
          subtitle={'Potvrđivanjem radnje svi podaci vezani za ovog klijenta bit će trajno obrisani.'}
          onPrimaryClick={onDeleteCompany}
          secondaryButtonText={t('shared.give_up') as string}
          primaryButtonText={t('shared.confirm') as string}
        />
        <NotificationModal
          isOpen={!!isDeleteAdminOpen}
          onClose={() => setIsDeleteAdminOpen(undefined)}
          title={'Ukloni administratora'}
          subtitle={'Potvrđivanjem radnje korisnik više neće biti administrator ovog klijenta.'}
          onPrimaryClick={onDeleteAdmin}
          secondaryButtonText={t('shared.give_up') as string}
          primaryButtonText={t('shared.confirm') as string}
        />
      </ComponentWrapper>
      <AddAdminDrawer
        isOpen={isAddAdminOpen}
        onClose={() => {
          setIsAddAdminOpen(false)
          setSelectedAdmin(undefined)
        }}
        admin={selectedAdmin}
      />
    </PageContent>
  )
}
