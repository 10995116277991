import { Chart as ChartJS } from 'chart.js'
import { FC, useEffect, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import 'twin.macro'
import { theme } from 'twin.macro'

interface ILineChartProps {
  labels?: (string | number)[]
  data?: number[]
  title?: string
  loading?: boolean
}

export const LineChart: FC<ILineChartProps> = ({ labels, data, title, loading }) => {
  const chartRef = useRef<ChartJS>(null)
  const [color, setColor] = useState<any>(undefined)

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)

    gradient.addColorStop(0.7, '#93AAFF33')
    gradient.addColorStop(0.06, '#93AAFF0F')

    setColor(gradient)
  }, [])

  return (
    <Chart
      ref={chartRef}
      type='line'
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          x: {
            offset: !!data && data.length == 1,
            grid: {
              display: false,
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            tension: 0.2,
            fill: 'origin',
            label: title,
            data: data,
            borderWidth: 2,
            pointRadius: !!data && data.length == 1 ? 1 : 0,
            borderColor: theme`colors.primary` as string,
            backgroundColor: color,
          },
        ],
      }}
    />
  )
}
