import { CreateUserDtoRoleEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { TextInput } from '@components/inputs'
import { Button, Drawer } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useUserFunctions } from '@hooks/api/user/useUserFunctions'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import z from 'zod'

interface IAddAdminDrawer {
  isOpen: boolean
  onClose: () => void
  admin?: IAddAdminForm & { id: number }
}

interface IAddAdminForm {
  name: string
  email: string
}

const CreateAdminSchema: z.ZodType<{ email: string; name: string }> = z.object({
  name: z.string().min(1),
  email: z.string().min(1).email(),
})

export const AddAdminDrawer: FC<IAddAdminDrawer> = ({ isOpen, onClose, admin }) => {
  const { id } = useParams()
  const {
    updateUser: { mutateAsync: updateUser },
    createUser: { mutateAsync: createUser },
  } = useUserFunctions()
  const [hasSetAdmin, setHasSetAdmin] = useState(false)
  const methods = useForm<IAddAdminForm>({ resolver: zodResolver(CreateAdminSchema) })
  const isEdit = !!admin
  const handleClose = () => {
    setHasSetAdmin(false)
    methods.reset()
    onClose()
  }

  useEffect(() => {
    if (!hasSetAdmin) {
      if (admin) {
        methods.setValue('name', admin.name)
        methods.setValue('email', admin.email)
        setHasSetAdmin(true)
      } else {
        methods.reset()
      }
    }
  }, [admin, hasSetAdmin, methods])

  const onSubmit = async (val: IAddAdminForm) => {
    if (id) {
      if (isEdit) {
        await updateUser({ id: admin?.id, data: { name: val.name, email: val.email, companyId: id } })
      } else {
        await createUser({
          companyId: id,
          role: CreateUserDtoRoleEnum.ADMIN,
          name: val.name,
          email: val.email,
          isActive: true,
          username: val.email,
        })
      }
      handleClose()
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      close={handleClose}
      drawerTwStyle={tw`flex flex-col pl-4`}
      headerTwStyle={`pb-10`}
      placement={'right'}
      title={isEdit ? 'Uredi administratora' : 'Dodaj administratora'}
    >
      <Form methods={methods} onSubmit={onSubmit} className={'flex-1'}>
        <div className={'flex h-full flex-col justify-between px-7'}>
          <div>
            <TextInput label={'Ime i Prezime'} id={'name'} placeholder={'Upiši'} />
            <TextInput label={'Email'} placeholder={'imeprezime@domena.hr'} id={'email'} />
          </div>
          <div className={'flex items-end justify-end gap-4'}>
            <Button buttonType={'secondary'} onClick={handleClose}>
              Odustani
            </Button>
            <Button type={'submit'}>Spremi</Button>
          </div>
        </div>
      </Form>
    </Drawer>
  )
}
