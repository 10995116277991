import { FC, InputHTMLAttributes, ReactNode } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import 'twin.macro'
import tw from 'twin.macro'

export interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null
  containerStyle?: string
  rightComponent?: ReactNode
  preview?: boolean
}

export const TextInput: FC<ITextInputProps> = ({ label, id = '', rightComponent, containerStyle, ...inputProps }) => {
  const formContext = useFormContext()
  const errorString = get(formContext?.formState?.errors, id)

  return (
    <div className={twMerge('relative mb-5', containerStyle)} onClick={inputProps.onClick}>
      {label && (
        <label htmlFor={id} className='text-secondary inline-block py-3 text-sm font-semibold'>
          {label}
        </label>
      )}
      <input
        id={id}
        className='focus:(text-gray-700 border-primary) focus-visible:border-primary m-0 w-full rounded border border-solid border-zinc-200 bg-white bg-clip-padding px-3 py-2 transition-colors focus:outline-0 focus-visible:outline-0'
        css={[errorString && tw`border-error`, ...(inputProps?.css || [])]}
        step={'any'}
        placeholder={label ?? undefined}
        {...inputProps}
        {...(formContext?.register ? formContext?.register(id) : {})}
        onChange={e => {
          if (inputProps.contentEditable === false) {
            return
          }
          if (formContext?.setValue) {
            formContext?.setValue(
              id,
              inputProps.type === 'number' ? Number(e?.target?.value ? e?.target?.value : 0) : e.target.value,
            )
          }
          if (inputProps?.onChange) {
            inputProps.onChange(e)
          }
        }}
      />
      {rightComponent}
      {errorString && <div className='text-error absolute mt-1 text-sm'>{errorString?.message || ''}</div>}
    </div>
  )
}
