import { ComponentWrapper } from '@components/ui'
import { MediaCompare } from './MediaCompare'
import { TopClients } from './TopClients'

export const MediaOverview = () => {
  return (
    <ComponentWrapper>
      <TopClients />
      <MediaCompare />
    </ComponentWrapper>
  )
}
