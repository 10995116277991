import { Form } from '@components/forms'
import { SwitchComponent, TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { IUser } from '@interfaces/user'
import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'

interface IEditAccountFormProps {
  initialValue: IUser
  onSubmit: (values: FieldValues) => void
}

const EditAccountFormSchema = z
  .object({
    username: z.optional(z.string()),
    email: z.optional(z.string().email()),
    name: z.optional(z.string()),
    password: z.optional(z.string()),
    confirmPassword: z.optional(z.string()),
    enableMailNotifications: z.boolean(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Lozinka se ne podudara',
    path: ['confirmPassword'],
  })

export const EditAccountForm: FC<IEditAccountFormProps> = ({ onSubmit, initialValue }) => {
  const { t } = useTranslation()
  const methods = useForm<IUser>({
    defaultValues: initialValue,
    resolver: zodResolver(EditAccountFormSchema),
  })

  const {
    formState: { touchedFields },
  } = methods
  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <div className=' mb-2 text-3xl font-normal leading-10 text-gray-600'>{t('account.settings')}</div>
      <div className='mb-6 text-base font-normal leading-snug text-gray-600'>{t('account.settings_subtitle')}</div>
      <div className='grid max-w-4xl grid-cols-2 gap-8'>
        <section className={'border-r pr-8'}>
          <TextInput id='name' label={t('account.name')} />
          <TextInput id='email' label={t('account.email')} />
          <TextInput id='username' label={t('account.username')} />
          <SwitchComponent
            label={'Primaj obavijesti na email'}
            sublabel={'Odnosi se na podsjetnik za upis radnih sati'}
            id={'enableMailNotifications'}
          />
        </section>
        <section>
          <TextInput id='password' label={t('login.change_password')} type='password' autoComplete={'new-password'} />
          <TextInput id='confirmPassword' label={t('login.confirm_password')} type='password' />
        </section>
      </div>
      {(Object.keys(touchedFields || {}).length > 0 || methods.formState.isDirty) && (
        <div className={'mt-12 flex items-center justify-end gap-4'}>
          <Button
            tw='px-12 uppercase'
            type='button'
            buttonType={'secondary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              methods.reset()
            }}
          >
            {t('shared.give_up')}
          </Button>
          <Button tw='px-14 uppercase' type='submit'>
            {t('shared.save')}
          </Button>
        </div>
      )}
    </Form>
  )
}
