import { forgotPassword } from '@api/auth'
import { ForgotPasswordForm } from '@components/forms'
import { IForgotPassword } from '@interfaces/auth'
import { notificationStore } from '@stores/notification.store'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const { addNotification } = notificationStore.getState()

  const onSubmit = async (values: FieldValues) => {
    await forgotPassword(values as IForgotPassword)
    addNotification({ title: 'Zaboravljena lozinka', content: 'Uspješno', status: 'success' })
  }

  return (
    <div tw='flex h-screen items-center justify-center before:(absolute z-0 h-screen w-screen -translate-y-2 bg-login-background bg-cover)'>
      <div tw='relative z-10 flex flex-col rounded bg-white p-10 shadow-neutral-200 shadow-basic min-w-[430px]'>
        <span tw='mb-6 border-b-accent pb-6 text-xl border-b-[0.5px]'>
          <span tw='text-primary'>{'Business '}</span>Monitor
        </span>
        <span tw='mb-2 font-normal text-title'>{t('login.forgot_password')}</span>
        <ForgotPasswordForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}
