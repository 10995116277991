import { getIncomesAndExpenses } from '@api/incomeExpense'
import { useQuery } from '@tanstack/react-query'

export const useIncomeAndExpense = (date: string) =>
  useQuery({
    queryKey: ['expenses', date],
    queryFn: () => getIncomesAndExpenses(date),
    enabled: !!date,
    gcTime: 0,
    staleTime: 0,
  })
