import { PageContent, Tabs } from '@components/ui'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { ActiveWorkingHoursTable } from './ActiveWorkingHoursTable'
import { ArchiveWorkingHoursTable } from './ArchiveWorkingHoursTable'

export const WorkingHoursContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Tabs
        $tabTwStyle={tw`text-center min-w-[150px]`}
        tabHeaderContainerTwStyle={tw`-mt-4 bg-background px-7`}
        tabs={[
          {
            content: (
              <PageContent tw={'pt-0'}>
                <ActiveWorkingHoursTable />
              </PageContent>
            ),
            key: t('working_hours.current'),
            title: t('working_hours.current'),
          },
          {
            content: (
              <PageContent tw={'pt-0'}>
                <ArchiveWorkingHoursTable />
              </PageContent>
            ),
            key: t('working_hours.archive'),
            title: t('working_hours.archive'),
          },
        ]}
      />
    </>
  )
}
