import Assets from '@assets/assets'
import { ComponentWrapper, Table } from '@components/ui'
import { useGetArchivedWorkingHours } from '@hooks/api/working-hours/useGetArchivedWorkingHours'
import { useNavigation } from '@hooks/useNavigation'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { SegmentedTabs } from '../../../components/Inputs/SegmentedTabs/SegmentedTabs'

export const ArchiveWorkingHoursTable = () => {
  const [filter, setFilter] = useState<string | null>(null)
  const [page, setPage] = useState(1)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'ASC' })
  const { t } = useTranslation()
  const { workingHours } = useNavigation()
  const { data } = useGetArchivedWorkingHours()
  const availableYears = [...new Set((data || [])?.map(d => d.date).map(date => dayjs(date).year()))]

  const segmentOptions = availableYears
    .sort((a, b) => b - a)
    .map(y => ({
      label: y.toString(),
      value: y.toString(),
    }))

  const hasNotes = (data || [])?.some(td => !!td.note)

  return (
    <ComponentWrapper>
      {(segmentOptions || []).length > 1 && (
        <SegmentedTabs
          containerProps={{ className: 'mb-6' }}
          options={segmentOptions}
          value={filter}
          onChange={(val: any) => setFilter(val === filter ? null : val)}
        />
      )}

      <Table
        onRowClick={(id: string | number) => workingHours(id)}
        order={order}
        pagination={{
          currentPage: page,
          onPageChange: setPage,
          perPage: 10,
          total: (data || []).length,
        }}
        columns={[
          {
            key: 'date',
            dataIndex: 'date',
            title: t('working_hours.month'),
            order: setOrder,
            render: data => <>{dayjs(data['date']).format('MMMM')}</>,
          },
          {
            key: 'hours',
            dataIndex: 'hours',
            decoration: tw`w-[150px]`,
            title: t('working_hours.hours'),
            order: setOrder,
            render: data => <>{data['hours'] || 0}</>,
          },
          {
            key: 'note',
            dataIndex: 'note',
            decoration: tw`w-[150px]`,
            title: t('working_hours.note'),
            order: setOrder,
            render: data => {
              const hasNote = !!data['note']
              return (
                <>
                  {hasNote ? (
                    <div data-tooltip-id='notes-tooltip' data-tooltip-content={data['note']}>
                      <img src={Assets.NoteIcon} alt={'note-icon'} />
                    </div>
                  ) : (
                    '-'
                  )}
                </>
              )
            },
            hidden: !hasNotes,
          },
          {
            key: 'date_sent',
            dataIndex: 'date_sent',
            decoration: tw`w-[150px]`,
            title: t('working_hours.sent'),
            order: setOrder,
            render: data => <>{data['date_sent'] ? dayjs(data['date_sent']).format('DD.MM.YYYY') : '-'}</>,
          },
          {
            key: 'date_approved',
            dataIndex: 'date_approved',
            decoration: tw`w-[150px]`,
            title: t('working_hours.approved_at'),
            order: setOrder,
            render: data => <>{data['date_approved'] ? dayjs(data['date_approved']).format('DD.MM.YYYY') : '-'}</>,
          },
        ]}
        data={(data || [])
          .filter(d => (filter ? d.date.includes(filter) : true))
          .map(d => ({ ...d, key: dayjs(d.date).format('YYYY-MM-DD') }))
          .sort(orderData)}
      />
    </ComponentWrapper>
  )
}
