import { ComponentWrapper, PageContent, Tabs } from '@components/ui'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { ExpensesTable } from '../Tables/ExpensesTable'
import { EmployeeInfo } from './EmployeeInfo'
import { EmployeeOverview } from './EmployeeOverview'
import { EmployeeWorkOverview } from './EmployeeWorkOverview'

export const EmployeeContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Tabs
        $tabTwStyle={tw`text-center min-w-[200px]`}
        tabHeaderContainerTwStyle={tw`-mt-4 bg-background px-7`}
        tabs={[
          {
            content: (
              <PageContent tw={'pt-0'}>
                <EmployeeOverview />
                <EmployeeWorkOverview />
              </PageContent>
            ),
            key: t('employees.work_overview'),
            title: t('employees.work_overview'),
          },
          {
            content: (
              <PageContent tw={'pt-0'}>
                <ComponentWrapper>
                  <EmployeeInfo />
                  <ExpensesTable />
                </ComponentWrapper>
              </PageContent>
            ),
            key: t('employees.information'),
            title: t('employees.information'),
          },
        ]}
      />
    </>
  )
}
