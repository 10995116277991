import assets from '@assets/assets'
import { PageHeader } from '@components/ui'
import { useTranslation } from 'react-i18next'

export const DepartmentsHeader = () => {
  const { t } = useTranslation()

  return (
    <PageHeader
      title={t('navigation.edit_departments')}
      hidePeriod
      topContent={
        <div
          className='-mt-4 mb-4 cursor-pointer'
          onClick={() => {
            history.go(-1)
          }}
        >
          <img src={assets.ArrowLeft} className='transition-transform' alt={'arrow-back'} />
        </div>
      }
    />
  )
}
