import Assets from '@assets/assets'
import { useMonthAvailableWorkingHours } from '@hooks/api/working-hours/useMonthAvailableWorkingHours'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'

export const WorkingHoursMonthHeader = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [year, month] = (id || '').split('-')
  const { data: availableHours } = useMonthAvailableWorkingHours({ month: Number(month), year: Number(year) })

  return (
    <PageHeader
      subtitle={'Upis podataka za'}
      rightContent={
        <div tw='flex flex-col text-end'>
          <div tw='text-sm'>{t('working_hours.number_of_working_hours')}</div>
          <div>{availableHours}</div>
        </div>
      }
      topContent={
        <div
          className='-mt-4 mb-4 cursor-pointer'
          onClick={() => {
            history.go(-1)
          }}
        >
          <img src={Assets.ArrowLeft} className='transition-transform' alt={'arrow-back'} />
        </div>
      }
      title={dayjs()
        .set('month', Number(month) - 1)
        .set('year', Number(year))
        .format('MMMM, YYYY')}
    />
  )
}
