import { ComponentWrapper, Table } from '@components/ui'
import { useEmployeeDetails } from '@hooks/api/employee'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useNavigation } from '@hooks/useNavigation'
import { useMe } from '@stores/me.store'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'twin.macro'

export const EmployeeWorkOverview = () => {
  const { id } = useParams()
  const { client } = useNavigation()
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()
  const { data } = useEmployeeDetails(id)

  const tableData = (data?.data?.clients ?? []).map(e => ({ ...e, key: e.client_id })).sort((a, b) => b.hours - a.hours)

  const totalHours = tableData.reduce((acc: number, d) => acc + d.hours + Number(d.overtime_hours || 0), 0)

  if (!tableData || tableData.length === 0) {
    return null
  }

  return (
    <ComponentWrapper>
      <div tw='flex items-center justify-between'>
        <span tw='mb-7 text-icons-dark text-2.5xl'>{t('employees.overview_per_client')}</span>
      </div>
      <div tw='overflow-scroll'>
        <Table
          columns={[
            {
              key: 'client',
              dataIndex: 'client',
              title: t('shared.client'),
            },
            {
              key: 'hours',
              dataIndex: 'hours',
              title: t('shared.worked_hours'),
              render: data => <>{data['hours']}</>,
            },
            {
              key: 'overtime',
              dataIndex: 'overtime',
              title: t('shared.overtime'),
              hidden: !me?.companySettings?.hasOvertime,
              render: data => data['overtime_hours'] ?? '-',
            },
            {
              key: 'total',
              dataIndex: 'total',
              title: t('shared.total'),
              hidden: !me?.companySettings?.hasOvertime,
              render: data => (
                <>{`${Number(data['hours'] || 0) + Number(data['overtime_hours'] || 0)} (${Math.round(
                  ((data['hours'] + Number(data['overtime_hours'] || 0)) * 100) / totalHours,
                )}%)`}</>
              ),
            },
            {
              key: 'hourly_wage',
              dataIndex: 'hourly_wage',
              title: t('shared.hourly_wage'),
              render: data => (
                <>
                  {data['hours'] > 0
                    ? formatCurrency((data['salary'] || 0) / (data['hours'] + Number(data['overtime_hours'] || 0)))
                    : 0}
                </>
              ),
            },
          ]}
          data={tableData}
          onRowClick={client}
        />
      </div>
    </ComponentWrapper>
  )
}
