import { Card } from '@components/ui'
import { useDepartmentsSummary } from '@hooks/api/department'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useMe } from '@stores/me.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useTranslation } from 'react-i18next'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'

export const DepartmentsHeader = () => {
  const { me } = useMe()
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  const { data } = useDepartmentsSummary()

  const {
    income,
    expense,
    salary,
    profit,
    margin,
    margin_no_salary,
    profit_no_salary,
    avg_salary,
    employees,
    salary_income_share,
  } = data?.data ?? {}

  return (
    <PageHeader title={t('navigation.departments')}>
      <section tw='mb-3 grid grid-cols-3 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        {me?.companySettings?.hasPL && (
          <Card title={<span>{t('shared.income')}</span>} subtitle={<span>{formatCurrency(income)}</span>} />
        )}
        {me?.companySettings?.hasPL && (
          <Card title={<span>{t('shared.costs')}</span>} subtitle={<span>{formatCurrency(expense)}</span>} />
        )}
        <Card title={<span>{t('shared.salary')}</span>} subtitle={<span>{formatCurrency(salary)}</span>} />
        <Card title={<span>{t('shared.employees')}</span>} subtitle={<span>{employees}</span>} />
        <Card title={<span>{t('shared.average_salary')}</span>} subtitle={<span>{formatCurrency(avg_salary)}</span>} />
        {me?.companySettings?.hasPL && (
          <Card
            title={<span>{t('clients.profit_no_pay')}</span>}
            subtitle={<span>{formatCurrency(profit_no_salary)}</span>}
          />
        )}
        {me?.companySettings?.hasPL && (
          <Card
            title={<span>{t('clients.margin_no_pay')}</span>}
            subtitle={<span>{margin_no_salary ? Math.round(margin_no_salary) + '%' : '-'}</span>}
          />
        )}
      </section>
      {me?.companySettings?.hasPL && (
        <section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
          <Card title={<span>{t('shared.profit')}</span>} subtitle={<span>{formatCurrency(profit)}</span>} />
          <Card title={<span>{t('shared.margin')}</span>} subtitle={<span>{formatPercentage(margin)}</span>} />
          <Card title={<span>{t('shared.num_of_employees')}</span>} subtitle={<span>{employees}</span>} />
          <Card
            title={<span>{t('departments.salary_share')}</span>}
            subtitle={<span>{formatPercentage(salary_income_share)}</span>}
          />
        </section>
      )}
    </PageHeader>
  )
}
