import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

type SegmentOption = {
  label: string
  value: string
}

interface ISegmentedProps {
  options: SegmentOption[]
  value: string | null
  onChange: (value: string) => void
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

export const SegmentedTabs: FC<PropsWithChildren<ISegmentedProps>> = ({ options, value, onChange, containerProps }) => {
  return (
    <div {...containerProps} tw='relative w-fit'>
      <div tw='flex items-center justify-center transition-all'>
        {options.map(({ label, value: optionValue }, index, array) => {
          const isFirst = index === 0
          const isLast = index === array.length - 1
          return (
            <div
              key={index}
              onClick={() => {
                onChange(optionValue)
              }}
              tw='relative cursor-pointer border-2 py-2 px-6 uppercase transition-all'
              css={[
                isFirst && tw`rounded-l-md`,
                isLast && tw`rounded-r-md`,
                optionValue == value ? tw`border-primary bg-primary-transparent text-primary` : tw`text-text-gray`,
              ]}
            >
              {label}
            </div>
          )
        })}
      </div>
    </div>
  )
}
