import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const useGetCompanyList = () => {
  const { company } = axiosApiInstance
  return useQuery({
    queryKey: ['company-list'],
    queryFn: company.companyControllerGetAllCompanys,
    select: data => data.data,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
}
