import { Skeleton } from '@components/ui'
import { useFaqs } from '@hooks/api/faq'
import parse from 'html-react-parser'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

export const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0)
  const { t, i18n } = useTranslation()

  const { data, isLoading } = useFaqs()

  const faqs = data?.data ?? []

  return (
    <Skeleton showSkeleton={isLoading}>
      <div tw='grid h-full grid-cols-2 grid-rows-1'>
        <div tw='flex flex-col bg-background py-6 px-8'>
          <span tw='mb-8 text-icons-dark text-3xl'>{t('faq.title')}</span>
          {faqs.map((val, index) => (
            <div key={index} tw='mb-6 flex cursor-pointer items-center justify-between'>
              <span
                onClick={() => setSelectedQuestion(index)}
                tw='text-icons-dark text-xl transition-all duration-75'
                css={[index == selectedQuestion && tw`font-bold`]}
              >
                {val.question}
              </span>
              <i
                className='fa fa-arrow-right'
                tw='invisible text-icons-dark'
                css={[index == selectedQuestion && tw`visible`]}
              />
            </div>
          ))}
        </div>
        <div tw='bg-background-light py-6 px-8'>
          {faqs.map(({ question, answer }, index) => (
            <div
              key={index}
              tw='absolute flex flex-col transition-all duration-500'
              css={[selectedQuestion != index && tw`opacity-0`]}
            >
              <span tw='mb-8 text-icons-dark text-3xl'>{question}</span>
              <span tw='mb-8 font-normal text-icons-dark text-base'>{parse(answer)}</span>
            </div>
          ))}
        </div>
      </div>
    </Skeleton>
  )
}
