import { ComponentWrapper } from '@components/ui'
import { ClientDepartmentsTable } from './ClientDeparmentsTable'
import { ClientDepartmentsCompare } from './ClientDepartmentsCompare'

export const ClientDepartmentsOverview = () => {
  return (
    <ComponentWrapper>
      <ClientDepartmentsTable />
      <ClientDepartmentsCompare />
    </ComponentWrapper>
  )
}
