import { UpdateDepartmentDTO } from '@api/generated-api'
import { UpdateDepartmentForm } from '@components/forms'
import { Modal } from '@components/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface IUpdateDepartmentModalProps {
  isOpen: boolean
  close: () => void
  updateDepartment: (department: UpdateDepartmentDTO) => void
  initialValue?: UpdateDepartmentDTO
  isSub?: boolean
}

export const UpdateDepartmentModal: FC<IUpdateDepartmentModalProps> = ({
  isOpen,
  close,
  updateDepartment,
  initialValue,
  isSub = false,
}) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} close={close}>
      <div tw='relative flex flex-col rounded-md border bg-white px-10 py-8 shadow-sm min-w-[350px]'>
        <i tw='absolute top-3 right-5 cursor-pointer text-lg text-icons-dark' className='fa fa-times' onClick={close} />
        <h2 tw='mb-4 block text-icons-dark text-2.5xl'>
          {isSub ? t('departments.edit_subdepartment') : t('departments.edit_department')}
        </h2>
        <UpdateDepartmentForm onUpdate={updateDepartment} initialValue={initialValue} isSub={isSub} close={close} />
      </div>
    </Modal>
  )
}
