import { dashDepartmentReview, dashEmployeeAndClient, dashIncomeAndCosts, dashTopClients } from '@api/dash'
import { useRefreshQuery } from './useRefreshQuery'

export const useDashIncomeAndCosts = () => useRefreshQuery(['income-and-costs'], dashIncomeAndCosts)

export const useDashEmployeeAndClient = (currency?: string) =>
  useRefreshQuery(['employee-and-client' + `-${currency}`], () => dashEmployeeAndClient(currency))

export const useDashDepartmentReview = () => useRefreshQuery(['department-review'], dashDepartmentReview)

export const useDashTopClients = (order?: string) => useRefreshQuery(['top-clients'], () => dashTopClients(order))
