import { departments, departmentsList, departmentsSummary, departmentsYearCompare } from '@api/department'
import { useRefreshQuery } from './useRefreshQuery'

export const useDepartmentsSummary = () => useRefreshQuery(['department-summary'], departmentsSummary)

export const useDepartmentsList = () => useRefreshQuery(['department-list'], departmentsList)

export const useDepartmentsYearCompare = () => useRefreshQuery(['department-year-compare'], departmentsYearCompare)

export const useDepartment = (enabled = true) => useRefreshQuery(['company-departments'], departments, { enabled })
