import { Navigation } from '@components/ui'
import { Account, WorkingHours } from '@pages'
import { ColorContext } from '@utils/colorContext'
import { useColors } from '@utils/randomColor'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'twin.macro'
import { WorkingHoursMonth } from '../pages/WorkingHours/Month/WorkingHoursMonth'
import { privateRoutes } from './routes'

export const EmployeeRouter = () => {
  const colors = useColors()
  return (
    <ColorContext.Provider value={colors as any}>
      <div tw='flex overflow-hidden'>
        <Navigation />
        <div tw='flex h-screen w-full flex-col sm:ml-20'>
          <div tw='h-full overflow-scroll bg-background-light bg-slate-50'>
            <Routes>
              <Route path={privateRoutes.WORKING_HOURS} element={<WorkingHours />} />
              <Route path={privateRoutes.WORKING_HOURS_MONTH} element={<WorkingHoursMonth />} />
              <Route path={privateRoutes.ACCOUNT_PATH} element={<Account />} />
              <Route path={privateRoutes.NOT_FOUND_PATH} element={<Navigate to={privateRoutes.WORKING_HOURS} />} />
              <Route path={privateRoutes.EMPTY} element={<Navigate to={privateRoutes.WORKING_HOURS} />} />
            </Routes>
          </div>
        </div>
      </div>
    </ColorContext.Provider>
  )
}
