/// <reference types="vite-plugin-svgr/client" />

import AddIcon from './add-icon.svg'
import ApproveIcon from './appove-checkmark.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowsCounterClockwise from './ArrowsCounterClockwise.svg'
import BellIcon from './bell.svg'
import CalendarIcon from './calendar.svg'
import CaretDownFull from './caret-down-full.svg'
import CaretRight from './caret-right.svg'
import ChartPieIcon from './chart-pie.svg'
import CheckCircleIcon from './check-circle.svg'
import CompanyNoImage from './company-no-image.svg'
import Corner from './Corner.svg'
import EditIcon from './edit-icon.svg'
import Eye from './Eye.svg'
import EyeDark from './EyeDark.svg'
import EyeSlash from './EyeSlash.svg'
import HourglassIcon from './hourglass.svg'
import * as NavigationIcons from './navigation'
import * as NavigationActiveIcons from './navigation/active'
import NoteIcon from './note.svg'
import PowerOff from './power-off.svg'
import RectangleLandscape from './RectangleLandscape.svg'
import RectanglePortrait from './RectanglePortrait.svg'
import ResendIcon from './resend-icon.svg'
import TrashIcon from './trash.svg'
import XIcon from './X.svg'

const Assets = {
  NoteIcon,
  ArrowLeft,
  CaretRight,
  Corner,
  TrashIcon,
  XIcon,
  HourglassIcon,
  ArrowsCounterClockwise,
  ChartPieIcon,
  CaretDownFull,
  ApproveIcon,
  CheckCircleIcon,
  ResendIcon,
  AddIcon,
  EditIcon,
  NavigationIcons,
  NavigationActiveIcons,
  RectanglePortrait,
  RectangleLandscape,
  PowerOff,
  CompanyNoImage,
  BellIcon,
  CalendarIcon,
  Eye,
  EyeDark,
  EyeSlash,
}

export default Assets
