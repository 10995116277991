import { CreateUserDtoRoleEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { TextInput } from '@components/inputs'
import { Button, Drawer } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCompanyFunctions } from '@hooks/api/company/useCompanyFunctions'
import { useUserFunctions } from '@hooks/api/user/useUserFunctions'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import tw from 'twin.macro'
import z from 'zod'

interface IAddAdminDrawer {
  isOpen: boolean
  onClose: () => void
}

const CreateCompanySchema: z.ZodType<{ adminEmail: string; name: string; adminName: string; image?: string }> =
  z.object({
    name: z.string().min(1),
    adminEmail: z.string().min(1).email(),
    adminName: z.string().min(1),
    image: z.any().optional(),
  })

export const AddCompanyDrawer: FC<IAddAdminDrawer> = ({ isOpen, onClose }) => {
  const {
    createCompany: { mutateAsync: createCompany },
  } = useCompanyFunctions()
  const {
    createUser: { mutateAsync: createUser },
  } = useUserFunctions()
  const methods = useForm({
    resolver: zodResolver(CreateCompanySchema),
  })

  const handleClose = () => {
    methods.reset()
    onClose()
  }

  const onSubmit = async val => {
    const createdCompany = await createCompany({
      name: val.name,
      image: val.image,
      companySettings: { hasOvertime: false, hasPl: false, hasWFH: false, overtimeMultiplier: 1 },
    })
    if (createdCompany.data.id) {
      const user = await createUser({
        companyId: createdCompany.data.id,
        image: undefined,
        name: val.adminName,
        email: val.adminEmail,
        isActive: true,
        role: CreateUserDtoRoleEnum.ADMIN,
        username: val.adminEmail,
      })
    }
    handleClose()
  }

  return (
    <Drawer
      title={'Dodaj klijenta'}
      isOpen={isOpen}
      close={handleClose}
      placement={'right'}
      drawerTwStyle={tw`flex flex-col pl-4`}
      headerTwStyle={`pb-10`}
    >
      <Form methods={methods} onSubmit={onSubmit} className={'flex-1'}>
        <div className={'flex h-full flex-col justify-between px-7'}>
          <div>
            <TextInput label={'Naziv klijenta'} placeholder={'Upiši'} id={'name'} />
            {/*<ImageInput id={'image'} label={'Logo'} />*/}
            <TextInput label={'Ime i prezime administratora'} placeholder={'Upiši'} id={'adminName'} />
            <TextInput label={'Email administratora'} id={'adminEmail'} placeholder={'imeprezime@domena.hr'} />
            <div className='text-sm font-normal leading-tight text-zinc-500'>
              Postavke prikaza odaberite naknadno nakon što spremite klijenta.
            </div>
          </div>
          <div className={'flex items-end justify-end gap-4'}>
            <Button type={'button'} buttonType={'secondary'} onClick={handleClose}>
              Odustani
            </Button>
            <Button type={'submit'}>Spremi</Button>
          </div>
        </div>
      </Form>
    </Drawer>
  )
}
