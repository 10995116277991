import { ButtonHTMLAttributes, FC } from 'react'
import tw from 'twin.macro'
import { SpinningLoader } from '../Loaders/SpinningLoader'

export type ButtonType = 'primary' | 'secondary' | 'text'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType
  css?: any
  isLoading?: boolean
}

const defaultStyle = tw`items-center justify-center gap-2 rounded py-2.5 font-medium text-sm uppercase transition-all px-[42px] leading-[18.20px]`

const disabledStyle = tw`bg-button-disabled hover:bg-button-disabled/70`

const getTypeStyle = (type?: ButtonType) => {
  switch (type) {
    case 'primary':
      return tw`bg-blue-500 text-white hover:bg-button-hover`
    case 'secondary':
      return tw`rounded border-primary bg-transparent px-7 text-primary border-[1px] hover:bg-blue-500/10`
    case 'text':
      return tw`bg-transparent text-primary hover:text-primary-light`
    default:
      return tw`bg-primary px-4 py-2 text-white rounded-[4px] hover:bg-primary-light`
  }
}

export const Button: FC<IButtonProps> = ({
  children,
  buttonType: type = 'primary',
  isLoading,
  css = [],
  ...basicButtonProps
}) => {
  return (
    <button
      disabled={basicButtonProps.disabled}
      css={[defaultStyle, getTypeStyle(type), ...css, basicButtonProps.disabled && disabledStyle]}
      {...basicButtonProps}
    >
      {isLoading ? <SpinningLoader size={'small'} /> : children}
    </button>
  )
}
