export const calculateMenuPosition = ({
  menuDimensions,
  triggerDimensions,
}: {
  menuDimensions: DOMRect | undefined
  triggerDimensions: DOMRect | undefined
}) => {
  if (!menuDimensions || !triggerDimensions) {
    return { top: 0, left: 0 }
  }
  const center = triggerDimensions.left + triggerDimensions.width / 2 - Math.max(menuDimensions.width, 100) / 2

  return {
    top: triggerDimensions?.top + 5,
    left: center,
  }
}
