import { Segmented } from '@components/inputs'
import { ComponentWrapper, Table } from '@components/ui'
import { useDashTopClients } from '@hooks/api/dash'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useNavigation } from '@hooks/useNavigation'
import { useMe } from '@stores/me.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

export const DashboardTopClients = () => {
  const [filter, setFilter] = useState<'income' | 'expense' | 'profit'>('income')
  const { client } = useNavigation()
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()
  const { data, isLoading, refetch } = useDashTopClients(filter)

  useEffect(() => {
    refetch()
  }, [filter])

  let topClients = (data?.data ?? []).map(val => ({ ...val, key: val.id }))

  const totalSum = topClients.reduce((acc, val) => {
    return acc + val[filter]
  }, 0)

  topClients = topClients
    .map(tc => ({ ...tc, share: totalSum != 0 ? (tc[filter] * 100) / totalSum : 0 }))
    .sort((a, b) => b.share - a.share)

  const maxShare = topClients.reduce((acc: any, tc: any) => {
    return acc < tc.share ? tc.share : acc
  }, 0)

  const segmentOptions = [
    {
      label: t('shared.income'),
      value: 'income',
    },
    {
      label: t('shared.costs'),
      value: 'expense',
    },
    {
      label: t('shared.profit'),
      value: 'profit',
    },
  ]

  return (
    <ComponentWrapper loading={isLoading}>
      <div tw='flex items-center justify-between'>
        <span tw='mb-7 text-icons-dark text-2.5xl'>{t('dashboard.top_clients')}</span>
        <Segmented options={segmentOptions} value={filter} onChange={(val: any) => setFilter(val)} />
      </div>
      <div tw='overflow-scroll'>
        <Table
          order={{ key: filter, direction: 'DESC' }}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('shared.client'),
            },
            {
              key: 'income',
              dataIndex: 'income',
              title: t('shared.income'),
              decoration: tw`text-center`,
              render: data => <span tw='w-full text-center'>{formatCurrency(data['income'])}</span>,
              order: () => setFilter('income'),
            },
            {
              key: 'expense',
              dataIndex: 'expense',
              title: t('shared.costs'),
              decoration: tw`text-center`,
              render: data => <span tw='w-full text-center'>{formatCurrency(data['expense'])}</span>,
              order: () => setFilter('expense'),
            },
            {
              key: 'profit',
              dataIndex: 'profit',
              title: t('shared.profit'),
              decoration: tw`text-center`,
              render: data => <span tw='w-full text-center'>{formatCurrency(data['profit'])}</span>,
              order: () => setFilter('profit'),
            },
            {
              key: 'share',
              dataIndex: 'share',
              title: t('shared.share'),
              decoration: tw`w-2/6 pl-12 text-start`,
              render: data => (
                <div tw='grid h-full w-full grid-cols-4 items-center gap-2'>
                  <span
                    tw='mr-2 w-16 w-full pl-12 text-left text-positive'
                    css={[data[`${filter}_share`] < 0 && tw`text-error`]}
                  >
                    {formatPercentage(data[`${filter}_share`])}
                  </span>
                  <div tw='col-span-3 w-full'>
                    <div
                      tw='h-5 rounded-r-sm bg-primary-light shadow transition-all duration-500 min-w-[5%]'
                      css={[data[`${filter}_share`] < 0 && tw`bg-error`]}
                      style={{
                        width:
                          (maxShare != 0 && data[`${filter}_share`] >= 0
                            ? (data[`${filter}_share`] * 100) / maxShare
                            : 0) + '%',
                      }}
                    />
                  </div>
                </div>
              ),
            },
          ]}
          data={topClients}
          onRowClick={me?.isAdmin ? client : undefined}
        />
      </div>
    </ComponentWrapper>
  )
}
