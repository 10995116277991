import { ComponentWrapper, Table } from '@components/ui'

import Assets from '@assets/assets'
import { useGetActiveWorkingHours } from '@hooks/api/working-hours/useGetActiveWorkingHours'
import { useNavigation } from '@hooks/useNavigation'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import tw from 'twin.macro'
import { Chip } from '../../../components/UI/Chip/Chip'

export const ActiveWorkingHoursTable = () => {
  const [page, setPage] = useState(1)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'ASC' })
  const { data = [] } = useGetActiveWorkingHours()
  const { t } = useTranslation()
  const { workingHours } = useNavigation()
  const hasNotes = (data || [])?.some(td => !!td.note)
  return (
    <ComponentWrapper>
      <Table
        onRowClick={(id: string | number) => workingHours(id)}
        order={order}
        pagination={{
          currentPage: page,
          onPageChange: setPage,
          perPage: 10,
          total: data.length,
        }}
        columns={[
          {
            key: 'date',
            dataIndex: 'date',
            title: t('working_hours.month'),
            order: setOrder,
            render: data => <>{dayjs(data['date']).format('MMMM')}</>,
          },
          {
            key: 'hours',
            dataIndex: 'hours',
            decoration: tw`w-[150px]`,
            title: t('working_hours.hours'),
            order: setOrder,
            render: data => <>{Number(data['hours']) || '-'}</>,
          },
          {
            key: 'percentage',
            dataIndex: 'percentage',
            decoration: tw`w-[150px]`,
            title: t('working_hours.percentage'),
            order: setOrder,
            render: data => <>{Number(data['percentage']).toFixed(0)}%</>,
          },
          {
            key: 'date_sent',
            dataIndex: 'date_sent',
            decoration: tw`w-[150px]`,
            title: t('working_hours.sent'),
            order: setOrder,
            render: data => <>{data['date_sent'] ? dayjs(data['date_sent']).format('DD.MM.YYYY') : '-'}</>,
          },
          {
            key: 'note',
            dataIndex: 'note',
            title: t('working_hours.note'),
            decoration: tw`justify-center text-center w-[150px]`,
            rowDecoration: tw`justify-center`,
            order: setOrder,
            render: data => {
              const hasNote = !!data['note']
              return (
                <>
                  {hasNote ? (
                    <div data-tooltip-id='notes-tooltip' data-tooltip-content={data['note']}>
                      <img src={Assets.NoteIcon} alt={'note-icon'} />
                    </div>
                  ) : (
                    '-'
                  )}
                </>
              )
            },
            hidden: !hasNotes,
          },
          {
            key: 'status',
            dataIndex: 'status',
            decoration: tw`justify-center text-center w-[160px]`,
            rowDecoration: tw`justify-center`,
            title: t('working_hours.status_text'),
            order: setOrder,
            render: data => <Chip state={data['status']} />,
          },
        ]}
        data={data.map(d => ({ ...d, key: dayjs(d.date).format('YYYY-MM-15') })).sort(orderData)}
      />
      <Tooltip id='notes-tooltip' place={'top'} style={{ maxWidth: 200, lineBreak: 'normal' }} />
    </ComponentWrapper>
  )
}
