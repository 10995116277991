import { useMe } from '@stores/me.store'
import { FC, useCallback } from 'react'
import { get, useFormContext } from 'react-hook-form'
import 'twin.macro'
import tw from 'twin.macro'
import { CheckBox } from '../Checkbox/Checkbox'

export type TagSelectType = {
  value: string | number
  title: string | number
  disabled?: boolean
}

interface ITagSelectProps {
  options: TagSelectType[]
  label?: string | null
  sublabel?: string | null
  name: string
  disabled?: boolean
  id: string
}

export const TagSelect: FC<ITagSelectProps> = ({ id, name, options, label, sublabel, disabled = false }) => {
  const { me } = useMe()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const data = watch()
  const fieldValue = get(data, id)

  const fieldError = get(errors, id)
  const errorString = fieldError
    ? typeof fieldError == 'string'
      ? fieldError
      : fieldError?.message?.toString() ?? ''
    : undefined

  const updateFieldValue = useCallback(
    (field: { id: number; isManager: boolean }) => {
      const values: Array<{ id: number; isManager: boolean }> = fieldValue || []
      const valueIds = (values || []).map(v => v.id)
      if (valueIds.includes(field.id)) {
        const idx = values.findIndex(val => val.id == field.id)
        if (idx != -1) {
          setValue(name, [...values.slice(0, idx), ...values.slice(idx + 1)])
        }
        return
      }
      setValue(name, [...values, field])
    },
    [fieldValue, name, setValue],
  )

  const handleCheckIsManager = useCallback(
    (value: number) => {
      const hasSelectedDep = (fieldValue || []).find(fv => fv.id === value)
      if (!hasSelectedDep) {
        updateFieldValue({ id: value, isManager: true })
      } else {
        const tempFieldValue = (fieldValue || []).map(dep =>
          dep.id === value ? { ...dep, isManager: !dep.isManager } : dep,
        )
        setValue(name, tempFieldValue)
      }
    },
    [fieldValue, name, setValue, updateFieldValue],
  )

  return (
    <>
      <input id={id} tw='hidden' {...register(name)} defaultValue={[]} />
      <div>
        {label && (
          <div tw='mb-2 font-medium text-zinc-700 text-sm leading-tight' css={!!fieldError && tw`text-error`}>
            {label}
          </div>
        )}
        {sublabel && (
          <div tw='font-normal text-zinc-700 text-sm leading-tight' css={!!fieldError && tw`text-error`}>
            {sublabel}
          </div>
        )}
      </div>
      <div tw='my-3 flex flex-wrap gap-4'>
        {options.map(val => {
          const field = (fieldValue || []).find(value => value.id === val.value)
          const isSelected = !!field

          return (
            <div
              key={val.value}
              onClick={
                val.disabled || disabled
                  ? undefined
                  : () =>
                      updateFieldValue({
                        id: Number(val.value),
                        isManager: false,
                      })
              }
              className='group/tag border-accent hover:border-primary flex min-h-[100px] min-w-[130px] cursor-pointer flex-col rounded-md border p-2 transition-all'
              css={[
                (val.disabled || disabled) && tw`cursor-not-allowed text-accent hover:bg-white`,
                isSelected && tw`border-primary bg-blue-500/10`,
              ]}
            >
              <div
                className={
                  'group-hover/tag:text-primary mb-1 text-base font-medium leading-snug text-black transition-all'
                }
              >
                {val.title}
              </div>
              {val?.employees > 0 && (
                <div className={'mb-2 text-sm font-normal text-neutral-600'}>{val.employees} zaposlenika</div>
              )}
              {(me?.managerDepartmentIds.includes(Number(val.value)) || me?.isAdmin) && (
                <div
                  className={'flex items-center gap-2 opacity-0 transition-opacity group-hover/tag:opacity-100'}
                  css={[isSelected && tw`opacity-100`]}
                >
                  <CheckBox
                    onClick={e => {
                      e.stopPropagation()
                      handleCheckIsManager(Number(val.value))
                    }}
                    checked={field?.isManager}
                    label={'Voditelj'}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
      {errorString && <div className='text-error mt-1 text-sm'>{errorString}</div>}
    </>
  )
}
