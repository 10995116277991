import { Card } from '@components/ui'
import { useClientSummary } from '@hooks/api/client'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useMe } from '@stores/me.store'
import { formatPercentage } from '@utils/formatPercentage'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'
import tw from 'twin.macro'

export const ClientHeader = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()

  const { data, isLoading } = useClientSummary(id)

  const {
    name,
    income,
    expense,
    profit,
    margin,
    total_hours,
    fte_per_client,
    profit_per_hour,
    profit_per_fte,
    salary,
    expense_per_fte,
    income_diff = 0,
    expense_diff = 0,
    salary_diff = 0,
    profit_diff = 0,
    margin_diff = 0,
  } = data?.data ?? {}

  const diff = 0

  return (
    <PageHeader title={name} subtitle={t('clients.client_profile')}>
      <section tw='mb-5 grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<>{t('shared.income')}</>}
            subtitle={
              <span css={[income_diff < 0 ? tw`text-error` : tw`text-positive`]}>{formatPercentage(income_diff)}</span>
            }
          >
            {formatCurrency(income)}
          </Card>
        )}
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<>{t('shared.costs')}</>}
            subtitle={
              <span css={[expense_diff < 0 ? tw`text-error` : tw`text-positive`]}>
                {formatPercentage(expense_diff)}
              </span>
            }
          >
            {formatCurrency(expense)}
          </Card>
        )}
        <Card
          loading={isLoading}
          title={<>{t('shared.salary')}</>}
          subtitle={
            <span css={[salary_diff < 0 ? tw`text-error` : tw`text-positive`]}>{formatPercentage(salary_diff)}</span>
          }
        >
          {formatCurrency(salary)}
        </Card>
        {!me?.companySettings?.hasPL && (
          <Card loading={isLoading} title={<span>{t('clients.fte_per_client')}</span>}>
            <span>{fte_per_client}</span>
          </Card>
        )}
        {!me?.companySettings?.hasPL && (
          <Card loading={isLoading} title={<span>{t('clients.hours_worked')}</span>}>
            <span>{total_hours}</span>
          </Card>
        )}
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<>{t('shared.profit')}</>}
            subtitle={
              <span css={[profit_diff < 0 ? tw`text-error` : tw`text-positive`]}>{formatPercentage(profit_diff)}</span>
            }
          >
            {formatCurrency(profit)}
          </Card>
        )}
        {me?.companySettings?.hasPL && (
          <Card
            loading={isLoading}
            title={<>{t('shared.margin')}</>}
            subtitle={
              <span css={[margin_diff < 0 ? tw`text-error` : tw`text-positive`]}>{formatPercentage(margin_diff)}</span>
            }
          >
            {formatPercentage(margin)}
          </Card>
        )}
      </section>
      {me?.companySettings?.hasPL && (
        <section tw='grid grid-cols-5 gap-7 max-xl:grid-cols-3 max-md:grid-cols-2'>
          <Card
            loading={isLoading}
            title={<span>{t('clients.hourly_income')}</span>}
            subtitle={<span>{formatCurrency(profit_per_hour)}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('clients.fte_expense')}</span>}
            subtitle={<span>{formatCurrency(expense_per_fte)}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('clients.fte_per_client')}</span>}
            subtitle={<span>{fte_per_client}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('clients.hours_worked')}</span>}
            subtitle={<span>{total_hours}</span>}
          />
          <Card
            loading={isLoading}
            title={<span>{t('clients.profit_per_client')}</span>}
            subtitle={<span>{formatCurrency(profit_per_fte)}</span>}
          />
        </section>
      )}
    </PageHeader>
  )
}
