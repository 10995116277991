import { DepartmentDto } from '@api/generated-api'
import { useMe } from '@stores/me.store'
import { useState } from 'react'
import 'twin.macro'
import { WorkingHoursManagementContent } from './Components/WorkingHoursManagementContent'
import { WorkingHoursManagementHeader } from './Components/WorkingHoursManagementHeader'

export const WorkingHoursManagement = () => {
  const { me } = useMe()
  const adminDepartments = (me?.departments || []).filter(d => (me?.managerDepartmentIds || []).includes(d?.id))
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentDto | undefined>(adminDepartments?.[0])

  return (
    <div className={'bg-table-accent-grey h-full'}>
      <WorkingHoursManagementHeader
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
      {!!selectedDepartment && <WorkingHoursManagementContent selectedDepartment={selectedDepartment} />}
    </div>
  )
}
