import { axiosApiInstance } from '@api/axiosInstance'
import {
  DepartmentControllerCreateDepartmentData,
  DepartmentControllerUpdateDepartmentData,
  DepartmentDto,
} from '@api/generated-api'
import { Button, ComponentWrapper, ITableData, LegendDot, Table, Tag } from '@components/ui'
import { useDepartment } from '@hooks/api/department'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { EditDeleteRender } from '../../../components/Renders/EditDeleteRender'
import { NotificationModal } from '../../WorkingHours/Components/NotificationModal'
import { CreateDepartmentModal } from './CreateDepartmentModal'
import { UpdateDepartmentModal } from './UpdateDepartmentModal'

export const DepartmentsList = () => {
  const { data, isLoading, refetch } = useDepartment()
  const {
    department: {
      departmentControllerCreateDepartment,
      departmentControllerDeleteDepartment,
      departmentControllerUpdateDepartment,
    },
  } = axiosApiInstance

  const [isDeleteOpen, setIsDeleteOpen] = useState<undefined | ITableData>(undefined)
  const [isCreateOpen, setIsCreateOpen] = useState<undefined | string | number>(undefined)
  const [isEditOpen, setIsEditOpen] = useState<undefined | ITableData>(undefined)
  const { t } = useTranslation()
  const create = async (department: DepartmentControllerCreateDepartmentData) => {
    await departmentControllerCreateDepartment({
      ...department,
      parentId: isCreateOpen == 'new' ? undefined : (isCreateOpen as number),
    })
    setIsCreateOpen(undefined)
    refetch()
  }

  const update = async (department: DepartmentControllerUpdateDepartmentData) => {
    await departmentControllerUpdateDepartment(department.id, department)
    setIsEditOpen(undefined)
    refetch()
  }

  const deleteDep = async () => {
    await departmentControllerDeleteDepartment(isDeleteOpen!.id as number)
    setIsDeleteOpen(undefined)

    refetch()
  }

  const tableData = useMemo(
    () => data?.data?.map(val => ({ ...val, key: val.snowflake, subItems: (val.children as any) ?? [] })) ?? [],
    [data?.data],
  )

  return (
    <ComponentWrapper>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'></span>
        <Button onClick={() => setIsCreateOpen('new')}>
          <i className='fa fa-plus' /> {t('departments.add_department')}
        </Button>
      </div>
      <Table
        isLoading={isLoading}
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            title: t('shared.name'),
            render: data => <LegendDot title={data.name} showDot={!data.isSub} />,
            sortableData: data => data.name,
          },
          {
            key: 'managers',
            dataIndex: 'managers',
            title: t('departments.managers'),
            render: data =>
              (data?.managers || []).length > 0 ? (data?.managers || []).map(manager => manager?.name).join('; ') : '-',
            sortableData: data =>
              (data?.managers || []).length > 0 ? (data?.managers || []).map(manager => manager?.name).join('-') : '-',
          },
          {
            key: 'departments',
            dataIndex: 'departments',
            decoration: tw`w-[200px]`,
            title: t('departments.type'),
            render: data =>
              !data.isSub ? (
                <>
                  {[
                    ...(data?.operational ? [t('departments.operative')] : []),
                    ...(data?.support ? [t('departments.support')] : []),
                    ...(data?.incomeExpenseChannel ? [t('departments.channels')] : []),
                  ].map(v => (
                    <Tag key={v} title={v}>
                      {v}
                    </Tag>
                  ))}
                </>
              ) : (
                <>-</>
              ),
            sortableData: data =>
              !data.isSub
                ? [
                    ...(data?.operational ? [t('departments.operative')] : []),
                    ...(data?.support ? [t('departments.support')] : []),
                    ...(data?.incomeExpenseChannel ? [t('departments.channels')] : []),
                  ].join('-')
                : undefined,
          },
          {
            key: 'trash',
            dataIndex: 'trash',
            title: '',
            decoration: tw`w-[100px]`,
            render: data => (
              <EditDeleteRender onDelete={() => setIsDeleteOpen(data)} onEdit={() => setIsEditOpen(data)} />
            ),
          },
        ]}
        data={tableData}
      />
      <NotificationModal
        isOpen={!!isDeleteOpen}
        onClose={() => setIsDeleteOpen(undefined)}
        title={'Obriši odjel'}
        subtitle={'Potvrđivanjem radnje svi podaci vezani za ovaj odjel bit će trajno obrisani.'}
        onPrimaryClick={deleteDep}
        secondaryButtonText={t('shared.give_up') as string}
        primaryButtonText={t('shared.confirm') as string}
      />
      <CreateDepartmentModal
        isOpen={!!isCreateOpen}
        close={() => setIsCreateOpen(undefined)}
        createDepartment={create}
        isSub={isCreateOpen != 'new'}
      />
      <UpdateDepartmentModal
        isOpen={!!isEditOpen}
        close={() => setIsEditOpen(undefined)}
        updateDepartment={update}
        initialValue={isEditOpen && { ...(isEditOpen as DepartmentDto) }}
        isSub={isEditOpen?.isSub}
      />
    </ComponentWrapper>
  )
}
