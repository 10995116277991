import { PageContent } from '@components/ui'
import { DepartmentsHeader } from './Components/DepartmentsHeader'
import { DepartmentsOverview } from './Components/DepartmentsOverview'

export const Departments = () => {
  return (
    <>
      <DepartmentsHeader />
      <PageContent>
        <DepartmentsOverview />
      </PageContent>
    </>
  )
}
