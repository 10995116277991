import Assets from '@assets/assets'
import { TextInput } from '@components/inputs'
import { FC, useState } from 'react'
import tw from 'twin.macro'
import { ITextInputProps } from '../TextInput/TextInput'

export const PasswordInput: FC<ITextInputProps> = props => {
  const [visible, setVisible] = useState(false)

  return (
    <TextInput
      {...props}
      type={visible ? 'text' : 'password'}
      rightComponent={
        <button
          tw='absolute right-2 bottom-[9px]'
          css={[props.preview && tw`hidden`]}
          tabIndex={-1}
          onClick={e => {
            e.stopPropagation()
            setVisible(v => !v)
          }}
          type='button'
        >
          <img
            tw='opacity-0 transition-all ease-in'
            css={[visible && tw`opacity-100`]}
            src={Assets.Eye}
            alt='Password visible'
          />
          <img
            tw='absolute top-0 opacity-0 transition-all duration-500 ease-in'
            css={[!visible && tw`opacity-100`]}
            src={Assets.EyeSlash}
            alt='Password hidden'
          />
        </button>
      }
    />
  )
}
