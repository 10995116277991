import { BarChart, Skeleton } from '@components/ui'
import { formatPercentage } from '@utils/formatPercentage'
import { FC } from 'react'
import 'twin.macro'

interface IDashboardBarCardProps {
  title: string
  change?: number
  total: number
  singleColor?: boolean
  data?: number[]
  labels?: (string | number)[]
  loading?: boolean
}

export const DashboardBarCard: FC<IDashboardBarCardProps> = ({
  total,
  change,
  data,
  labels,
  title,
  singleColor,
  loading,
}) => {
  return (
    <Skeleton showSkeleton={loading}>
      <div tw='rounded-t-lg bg-white px-7 py-5 text-icons-dark text-2xl shadow-md'>
        <div tw='mb-8 flex justify-between'>
          <span>{title}</span>
          <span tw='font-medium'>
            <span tw='mr-2 font-normal text-success text-sm'>{formatPercentage(change)}</span>
            {total}
          </span>
        </div>
        <div tw='h-48'>
          <BarChart title={title} data={data} labels={labels} singleColor={singleColor} />
        </div>
      </div>
    </Skeleton>
  )
}
