import { Header, Navigation } from '@components/ui'
import { Account, Client, Clients, Employee, Employees, WorkingHours } from '@pages'
import { ColorContext } from '@utils/colorContext'
import { useColors } from '@utils/randomColor'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'twin.macro'
import { WorkingHoursMonth } from '../pages/WorkingHours/Month/WorkingHoursMonth'
import { WorkingHoursManagement } from '../pages/WorkingHoursManagement/WorkingHoursManagement'
import { privateRoutes } from './routes'

export const ManagerRouter = () => {
  const colors = useColors()
  return (
    <ColorContext.Provider value={colors as any}>
      <div tw='flex overflow-hidden'>
        <Navigation />
        <div tw='flex h-screen w-full flex-col sm:ml-20'>
          <Header />
          <div tw='flex h-full flex-col overflow-scroll bg-background-light bg-slate-50'>
            <Routes>
              <Route path={privateRoutes.CLIENTS_PATH} element={<Clients />} />
              <Route path={privateRoutes.CLIENT_PATH} element={<Client />} />
              <Route path={privateRoutes.EMPLOYEES_PATH} element={<Employees />} />
              <Route path={privateRoutes.EMPLOYEE_PATH} element={<Employee />} />
              <Route path={privateRoutes.WORKING_HOURS} element={<WorkingHours />} />
              <Route path={privateRoutes.WORKING_HOURS_MONTH} element={<WorkingHoursMonth />} />
              <Route path={privateRoutes.WORKING_HOURS_MANAGEMENT} element={<WorkingHoursManagement />} />
              <Route path={privateRoutes.ACCOUNT_PATH} element={<Account />} />
              <Route path={privateRoutes.NOT_FOUND_PATH} element={<Navigate to={privateRoutes.CLIENTS_PATH} />} />
              <Route path={privateRoutes.EMPTY} element={<Navigate to={privateRoutes.CLIENTS_PATH} />} />
            </Routes>
          </div>
        </div>
      </div>
    </ColorContext.Provider>
  )
}
