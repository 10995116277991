import { PageContent } from '@components/ui'
import 'twin.macro'
import { DashboardHeader } from './Components/DashboardHeader'
import { DashboardSectionOverview } from './Components/DashboardSection/DashboardSectionOverview'
import { DashboardTopClients } from './Components/DashboardTopClients'

export const Dashboard = () => {
  return (
    <>
      <DashboardHeader />
      <PageContent>
        <DashboardSectionOverview />
        <DashboardTopClients />
      </PageContent>
    </>
  )
}
