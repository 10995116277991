import { TextInput } from '@components/inputs'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { WorkingHoursTypeEnum } from '../../../../../types'
import { EditableWorkingHoursColumnsEnum, IEditColumnProps } from '../WorkingHoursMonthContent'

interface IWorkingHoursRowData {
  hours: string
  key: number
  name: string
  type: WorkingHoursTypeEnum
  working_hours_id: number
  id: number
}

export const HoursRenderer = ({
  currentValue,
  rowData,
  onEditColumn,
  isEditable,
  notEnteredHours,
}: {
  currentValue: string
  rowData: IWorkingHoursRowData
  isEditable: boolean
  notEnteredHours: number
  onEditColumn: ({ change, whId, column }: IEditColumnProps) => void
}) => {
  const [hovering, setHovering] = useState(false)
  const [editing, setEditing] = useState(false)
  const isWorkingHourSelected = Object.values(WorkingHoursTypeEnum).includes(rowData['type'])
  const isOvertime = rowData['type'] === WorkingHoursTypeEnum.OVERTIME
  const parsedValue = Number(currentValue) / (isWorkingHourSelected ? 1 : 8)
  const containerRef = useRef(null)
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const maxInputHours = notEnteredHours + Number(currentValue)
      if (Number(event.target.value) * (isWorkingHourSelected ? 1 : 8) > maxInputHours && !isOvertime) {
        onEditColumn({
          change: isWorkingHourSelected ? maxInputHours : maxInputHours - (maxInputHours % 8),
          whId: rowData.id,
          column: EditableWorkingHoursColumnsEnum.HOURS,
        })
      } else {
        onEditColumn({
          change: Number(event.target.value) * (isWorkingHourSelected ? 1 : 8),
          whId: rowData.id,
          column: EditableWorkingHoursColumnsEnum.HOURS,
        })
      }
    },
    [currentValue, isOvertime, isWorkingHourSelected, notEnteredHours, onEditColumn, rowData.id],
  )

  useOutsideClick(containerRef, () => setEditing(false))

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => {
        if (isEditable) {
          setHovering(true)
        }
      }}
      onMouseLeave={() => {
        if (isEditable) {
          setHovering(false)
        }
      }}
    >
      {editing || hovering ? (
        <TextInput
          type={'number'}
          id='hours'
          name='hours'
          value={parsedValue}
          onClick={() => setEditing(true)}
          containerStyle={'mb-0 max-w-[100px]'}
          onBlur={() => setEditing(false)}
          onChange={handleChange}
          max={isOvertime ? undefined : notEnteredHours + Number(currentValue)}
          min={0}
        />
      ) : (
        <div
          onClick={() => {
            if (isEditable && !editing) {
              setEditing(true)
            }
          }}
        >
          {parsedValue.toString() || ''}
        </div>
      )}
    </div>
  )
}
