import { axiosApiInstance } from '@api/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const useGetCompanyById = ({ id }: { id: number }) => {
  const { company } = axiosApiInstance
  return useQuery({
    queryKey: ['company-list', id],
    queryFn: () => company.companyControllerGetCompanyById(id),
    select: data => data.data,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
}
