import { ITableData } from '@components/ui'
import { formatPercentage } from '@utils/formatPercentage'
import { FC } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

interface IDepartmentTableItem {
  showPercentage: boolean
  data: ITableData
  dataKey: string
  formatValue: (val?: number | undefined) => string
}

export const DepartmentTableItem: FC<IDepartmentTableItem> = ({ showPercentage, data, dataKey, formatValue }) => {
  return (
    <div tw='flex w-full justify-end gap-6'>
      <span
        className='transition-all'
        css={[!showPercentage && tw`opacity-0`, data[dataKey + '_diff'] >= 0 ? tw`text-positive` : tw`text-error`]}
      >
        {formatPercentage(data[dataKey + '_diff'])}
      </span>
      <span>{formatValue(data[dataKey])}</span>
    </div>
  )
}
