import { createRef, FC, PropsWithChildren, useEffect, useState } from 'react'
import { ComponentSize } from 'src/types'
import 'twin.macro'
import tw from 'twin.macro'

type SegmentOption = {
  label: string
  value: string
}

interface ISegmentedProps {
  options: SegmentOption[]
  value: string
  onChange: (value: string) => void
  size?: ComponentSize
}

export const Segmented: FC<PropsWithChildren<ISegmentedProps>> = ({ options, value, onChange, size, ...props }) => {
  const [elRefs, setElRefs] = useState<any>(
    Array(options.length)
      .fill(null)
      .map((_, i) => createRef()),
  )

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs: any) =>
      Array(options.length)
        .fill(null)
        .map((_, i) => elRefs[i] || createRef()),
    )
  }, [options, value])

  const selected = elRefs[options.findIndex(v => v.value == value)]?.current

  return (
    <div {...props} tw='relative h-7 w-fit rounded-4xl border-2 border-background text-2xs px-[1px] py-[2px]'>
      <div tw='flex items-center justify-center transition-all'>
        {options.map(({ label, value: optionValue }, index) => (
          <div
            key={index}
            ref={elRefs[index]}
            onClick={() => {
              onChange(optionValue)
            }}
            tw='relative cursor-pointer whitespace-nowrap rounded-4xl px-3 text-icons uppercase z-[1] h-[26px] py-[3px]'
            css={[optionValue == value ? tw`text-icons-dark` : tw`hover:text-icons-dark`]}
          >
            {label}
          </div>
        ))}
        <div
          css={[
            selected != null && {
              width: selected.clientWidth + 'px',
              left: selected.offsetLeft,
              top: 1,
            },
          ]}
          tw='absolute rounded-4xl bg-background transition-all h-[22px]'
        />
      </div>
    </div>
  )
}
