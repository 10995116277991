import { Form } from '@components/forms'
import { TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { ICreateClient } from '../../../../types/client'

interface ICreateClientFormProps {
  onCreate: (client: ICreateClient) => void
}

const CreateClientSchema = z.object({
  name: z.string().min(1),
})

export const CreateClientForm: FC<ICreateClientFormProps> = ({ onCreate }) => {
  const methods = useForm<{ name: string }>({ resolver: zodResolver(CreateClientSchema) })
  const { t } = useTranslation()

  return (
    <Form methods={methods} onSubmit={onCreate}>
      <TextInput id='name' label={t('shared.name')} containerStyle={`mb-2`} />
      <section tw='flex justify-start'>
        <Button tw='mt-4 w-full font-medium text-white uppercase' type='submit'>
          {t('shared.add')}
        </Button>
      </section>
    </Form>
  )
}
