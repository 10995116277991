import { Button } from '@components/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { Modal } from './Modal'

interface IDeleteModal {
  close: () => void
  isOpen: boolean
  onDelete: () => void
}

export const DeleteModal: FC<IDeleteModal> = ({ close, isOpen, onDelete }) => {
  const { t } = useTranslation()

  return (
    <Modal close={close} isOpen={isOpen}>
      <div tw='rounded-md bg-white px-5 min-w-[350px]'>
        <h2 tw='block border-b border-b-accent py-3 text-xl'>{t('shared.delete')}</h2>
        <span tw='block py-3'>{t('shared.delete_sure')}</span>
        <div tw='flex items-center justify-end py-4'>
          <Button tw='mr-2' onClick={close} buttonType='secondary'>
            {t('shared.cancel')}
          </Button>
          <Button onClick={onDelete}>{t('shared.delete_action')}</Button>
        </div>
      </div>
    </Modal>
  )
}
