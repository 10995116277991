import { CreateSalaryDTO, UpdateSalaryDTO } from '@api/generated-api'
import { DatePicker, TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { zodNumber } from '@utils/validation'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface ICreateExpenseFormProps {
  onCreate: (expense: CreateSalaryDTO) => void
  close: () => void
  initialValues?: UpdateSalaryDTO
  employeeArrival: string
}

const CreateExpenseSchema = z.object({
  date: z.string(),
  bruto2: zodNumber(z.number().multipleOf(0.01).gt(0)),
  neto: zodNumber(z.number().multipleOf(0.01).gt(0)),
  kind: zodNumber(z.nan().or(z.number().multipleOf(0.01))),
  transport: zodNumber(z.nan().or(z.number().multipleOf(0.01))),
  fuel: zodNumber(z.nan().or(z.number().multipleOf(0.01))),
  parking: zodNumber(z.nan().or(z.number().multipleOf(0.01))),
  other: zodNumber(z.nan().or(z.number().multipleOf(0.01))),
  description: z.string().optional(),
})

export const CreateExpenseForm: FC<ICreateExpenseFormProps> = ({ onCreate, close, initialValues, employeeArrival }) => {
  const methods = useForm<CreateSalaryDTO>({
    resolver: zodResolver(CreateExpenseSchema),
    defaultValues: { ...initialValues, date: initialValues?.date || dayjs(new Date()).format('YYYY-MM-DD') },
  })
  const { t } = useTranslation()

  return (
    <Form methods={methods} onSubmit={onCreate}>
      <section tw='my-1 grid grid-cols-3 gap-4'>
        <section className={'col-span-1 grid'}>
          <DatePicker
            id='date'
            label={t('shared.date') as string}
            calendarProps={{ minDate: dayjs(employeeArrival).toDate() }}
          />
        </section>
        <section className={'col-span-2 grid'}>
          <TextInput
            id='description'
            label={t('employees.description')}
            placeholder={t('shared.placeholders.optional')}
          />
        </section>
      </section>
      <section tw='mb-4 grid grid-cols-3 gap-4 gap-y-4'>
        <TextInput id='bruto2' label={t('employees.bruto2')} placeholder={t('shared.placeholders.enter_amount')} />
        <TextInput id='neto' label={t('employees.neto')} placeholder={t('shared.placeholders.enter_amount')} />
        <TextInput id='kind' label={t('employees.pay_in_kind')} placeholder={t('shared.placeholders.optional')} />
        <TextInput id='transport' label={t('employees.transport')} placeholder={t('shared.placeholders.optional')} />
        <TextInput id='fuel' label={t('employees.fuel_and_toll')} placeholder={t('shared.placeholders.optional')} />
        <TextInput id='parking' label={t('employees.parking')} placeholder={t('shared.placeholders.optional')} />
        <TextInput id='other' label={t('employees.other_expenses')} placeholder={t('shared.placeholders.optional')} />
      </section>
      <section tw='mt-4 flex justify-end gap-4'>
        <Button buttonType={'secondary'} onClick={close}>
          {t('shared.give_up')}
        </Button>
        <Button type='submit'>{t('shared.save')}</Button>
      </section>
    </Form>
  )
}
