import { getSalariesByEmployeeId } from '@api/salary'
import { useQuery } from '@tanstack/react-query'

export const useEmployeeSalaries = (employeeId: number) =>
  useQuery({
    queryKey: ['employee_salaries', employeeId],
    queryFn: () => getSalariesByEmployeeId(employeeId),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    refetchInterval: 0,
  })
