import { LegendDot } from '@components/ui'
import { useEmployeeSummary } from '@hooks/api/employee'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/UI/PageHeader/PageHeader'
import 'twin.macro'

export const EmployeeHeader = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { data } = useEmployeeSummary(id)

  const { arrival, departure, departments, name } = data?.data ?? {}

  const hiredFormat = (h: Date) => dayjs(h).format('MMMM, YYYY')

  return (
    <PageHeader
      title={name}
      extra={
        <section tw='mt-2 flex gap-2 text-sm'>
          {(departments || []).map(department => (
            <LegendDot hasMargin={false} key={department.snowflake} title={department.name ?? ''} />
          ))}
          {arrival && <div>|</div>}
          {arrival && (
            <span>
              {t('employees.hired') +
                ' ' +
                hiredFormat(new Date(arrival)) +
                (departure ? ' - ' + hiredFormat(new Date(departure)) : '')}
            </span>
          )}
        </section>
      }
    ></PageHeader>
  )
}
