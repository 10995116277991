import { Segmented } from '@components/inputs'
import { CompareBarChart } from '@components/ui'
import { useDepartmentsYearCompare } from '@hooks/api/department'
import { ClientYearReviewItem } from '@interfaces/client'
import { useCurrency } from '@stores/currency.store'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useMe } from '@stores/me.store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

export const DepartmentsCompare = () => {
  const [filter, setFilter] = useState<keyof ClientYearReviewItem>('salary')
  const {
    filter: { year },
  } = useGlobalFilter()
  const { conversionRate } = useCurrency()
  const { t } = useTranslation()
  const { me } = useMe()

  const { data, isLoading } = useDepartmentsYearCompare()

  const yearData = data?.data ?? []

  const segmentOptions = [
    {
      label: t('shared.salary'),
      value: 'salary',
    },
    {
      label: t('shared.income'),
      value: 'income',
    },
    {
      label: t('shared.costs'),
      value: 'expense',
    },

    {
      label: t('shared.profit'),
      value: 'profit',
    },
    {
      label: t('shared.margin'),
      value: 'margin',
    },
  ]

  return (
    <>
      <div tw='mb-5 flex items-center justify-between'>
        <span tw='text-icons-dark text-2.5xl'>{t('shared.salary') + ` ${year}. vs ${year - 1}.`}</span>
        {me?.companySettings?.hasPL && (
          <Segmented
            options={segmentOptions}
            value={filter}
            onChange={val => setFilter(val as keyof ClientYearReviewItem)}
          />
        )}
      </div>
      <div tw='h-48'>
        <CompareBarChart
          labels={yearData[0]?.review?.map(l => l.month)}
          datasets={[
            { data: yearData[0]?.review?.map(r => r[filter] * conversionRate), title: year.toString() },
            { data: yearData[1]?.review?.map(r => r[filter] * conversionRate), title: (year - 1).toString() },
          ]}
          loading={isLoading}
        />
      </div>
    </>
  )
}
