import { Dropdown } from '@components/ui'
import { useWorkingHoursEmployeeClients } from '@hooks/api/working-hours/useWorkingHoursEmployeeClients'
import { useMemo, useState } from 'react'
import tw from 'twin.macro'
import { WorkingHoursTypeEnum } from '../../../../../types'
import { EditableWorkingHoursColumnsEnum, IEditColumnProps } from '../WorkingHoursMonthContent'

interface IWorkingHoursRowData {
  hours: string
  key: number
  name: string
  type: WorkingHoursTypeEnum
  id: number
  working_hours_id: number
}

export const ClientRenderer = ({
  currentValue,
  rowData,
  onEditColumn,
  isEditable,
}: {
  currentValue: string
  rowData: IWorkingHoursRowData
  isEditable: boolean
  onEditColumn: ({ change, whId, column }: IEditColumnProps) => void
}) => {
  const [editing, setEditing] = useState(false)
  const [hovering, setHovering] = useState(false)

  const { data = [] } = useWorkingHoursEmployeeClients()

  const clients = useMemo(() => (data || []).map(empClient => ({ title: empClient.name, value: empClient.id })), [data])

  const items = useMemo(
    () =>
      clients.map(val => ({
        key: val.value,
        title: val.title,
        decoration: [
          tw`cursor-pointer`,
          currentValue == val.title ? tw`bg-dropdown-selected hover:bg-blue-200` : undefined,
        ],
        onClick: () => {
          onEditColumn({ change: val.value, column: EditableWorkingHoursColumnsEnum.CLIENT_ID, whId: rowData.id })
          setEditing(false)
        },
        render: <span tw='cursor-pointer'>{val.title}</span>,
      })),
    [clients, currentValue, onEditColumn, rowData.id],
  )

  return (
    <div
      onMouseEnter={() => {
        if (isEditable) {
          setHovering(true)
        }
      }}
      onMouseLeave={() => {
        if (isEditable) {
          setHovering(false)
        }
      }}
    >
      {editing || hovering ? (
        <Dropdown
          containerStyle={tw`bg-white`}
          hasSearch={true}
          onClick={() => setEditing(true)}
          trigger='click'
          items={items}
          onOutsideClick={() => setEditing(false)}
        >
          <span tw='flex w-full overflow-hidden whitespace-nowrap'>{currentValue}</span>
        </Dropdown>
      ) : (
        <div
          onClick={() => {
            if (isEditable && !editing && currentValue) {
              setEditing(true)
            }
          }}
        >
          {currentValue || '-'}
        </div>
      )}
    </div>
  )
}
