import { CreateDepartmentDTO } from '@api/generated-api'
import { CreateDepartmentForm } from '@components/forms'
import { Modal } from '@components/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface ICreateDepartmentModalProps {
  isOpen: boolean
  close: () => void
  createDepartment: (department: CreateDepartmentDTO) => void
  isSub?: boolean
}

export const CreateDepartmentModal: FC<ICreateDepartmentModalProps> = ({ isOpen, close, createDepartment, isSub }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} close={close}>
      <div tw='relative flex flex-col rounded-md border bg-white px-10 py-8 shadow-sm min-w-[350px]'>
        <i tw='absolute top-3 right-5 cursor-pointer text-lg text-icons-dark' className='fa fa-times' onClick={close} />
        <h2 tw='mb-4 block text-icons-dark text-2.5xl'>
          {t(`departments.${isSub ? 'add_subdepartment' : 'add_department'}`)}
        </h2>
        <CreateDepartmentForm onCreate={createDepartment} isSub={isSub} />
      </div>
    </Modal>
  )
}
