import { PageContent } from '@components/ui'
import { CompanyDetails } from './Components/CompanyDetails'
import { CompanyHeader } from './Components/CompanyHeader'

export const Company = () => {
  return (
    <>
      <CompanyHeader />
      <PageContent>
        <CompanyDetails />
      </PageContent>
    </>
  )
}
