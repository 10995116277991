import { ComponentWrapper, Tabs } from '@components/ui'
import { useTranslation } from 'react-i18next'
import { ClientEmployeeTable } from './ClientEmployeeTable'
import { EmployeeClientsTable } from './EmployeeClientsTable'
import { EmployeeNonWorkingHoursTable } from './EmployeeNonWorkingHoursTable'
import { EmployeesList } from './EmployeesList'

export const EmployeesTabs = () => {
  const { t } = useTranslation()

  return (
    <ComponentWrapper>
      <Tabs
        tabs={[
          {
            content: <ClientEmployeeTable />,
            key: t('employees.client_per_employee'),
            title: t('employees.client_per_employee'),
          },
          {
            content: <EmployeeClientsTable />,
            key: t('employees.employee_per_client'),
            title: t('employees.employee_per_client'),
          },
          {
            content: <EmployeeNonWorkingHoursTable tab={'vacation'} />,
            key: t('employees.vacation'),
            title: t('employees.vacation'),
          },
          {
            content: <EmployeeNonWorkingHoursTable tab={'sick'} />,
            key: t('employees.sick'),
            title: t('employees.sick'),
          },
          {
            content: <EmployeeNonWorkingHoursTable tab={'maternity'} />,
            key: t('employees.maternity'),
            title: t('employees.maternity'),
          },
          {
            content: <EmployeesList />,
            key: t('employees.allEmployees'),
            title: t('employees.allEmployees'),
          },
        ]}
      />
    </ComponentWrapper>
  )
}
