import { ForgotPassword, Login } from '@pages'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'twin.macro'
import { ResetPassword } from '../pages/login/ResetPassword'
import { publicRoutes } from './routes'

export const PublicRouter = () => {
  return (
    <div tw='h-screen'>
      <Routes>
        <Route path={publicRoutes.LOGIN_PATH} element={<Login />} />
        <Route path={publicRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={publicRoutes.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={publicRoutes.NOT_FOUND_PATH} element={<Navigate to={publicRoutes.LOGIN_PATH} />} />
      </Routes>
    </div>
  )
}
