import { createEmployee } from '@api/employee'
import { CreateEmployeeDTO, CreateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Drawer } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { zodNumber } from '@utils/validation'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { CreateEmployeeForm } from '../Forms/CreateEmployeeForm'

interface CreateEmployeeDrawerProps {
  isCreateEmployeeOpen: boolean
  setIsCreateEmployeeOpen: Dispatch<SetStateAction<boolean>>
}

const CreateEmployeeSchema: z.ZodType<CreateEmployeeDTO> = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  departments: z.object({ id: z.number(), isManager: z.boolean() }).array().min(1),
  hoursType: z.nativeEnum(CreateEmployeeDtoHoursTypeEnum),
  salary: z.object({
    date: z.string().optional(),
    bruto2: zodNumber(z.number().gt(0).multipleOf(0.01)),
    neto: zodNumber(z.number().gt(0).multipleOf(0.01)),
    transport: zodNumber(z.nan().or(z.number().min(0).multipleOf(0.01))),
    fuel: zodNumber(z.nan().or(z.number().min(0).multipleOf(0.01))),
    parking: zodNumber(z.nan().or(z.number().min(0).multipleOf(0.01))),
    other: zodNumber(z.nan().or(z.number().min(0).multipleOf(0.01))),
    kind: zodNumber(z.nan().or(z.number().min(0).multipleOf(0.01))),
  }),
  departure: z.string().optional(),
  arrival: z.string(),
})

export const CreateEmployeeDrawer = ({ isCreateEmployeeOpen, setIsCreateEmployeeOpen }: CreateEmployeeDrawerProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const methods = useForm<CreateEmployeeDTO>({
    resolver: zodResolver(CreateEmployeeSchema),
    defaultValues: {
      hoursType: CreateEmployeeDtoHoursTypeEnum.FULLTIME,
      departments: [],
      arrival: dayjs().toString(),
      salary: { date: dayjs().toString() },
    },
  })

  const handleClose = useCallback(() => {
    setIsCreateEmployeeOpen(false)
    methods.reset()
  }, [methods, setIsCreateEmployeeOpen])

  const { mutateAsync: createEmployeeAsync, isPending: isCreatingEmployee } = useMutation({
    mutationFn: createEmployee,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['employees-list'] })
    },
  })

  const createEmployeeFn = async (employee: CreateEmployeeDTO) => {
    const data = await createEmployeeAsync({ ...employee, salary: { ...employee.salary, date: employee.arrival } })
    if (data.status < 300) {
      setIsConfirmationModalOpen(true)
      handleClose()
    }
  }

  return (
    <>
      <Drawer
        isOpen={isCreateEmployeeOpen}
        close={handleClose}
        placement={'right'}
        title={t('employees.add_employee') as string}
      >
        <div tw='relative mx-8 flex flex-1 flex-col bg-white pt-8'>
          <CreateEmployeeForm
            onCreate={createEmployeeFn}
            methods={methods}
            isLoading={isCreatingEmployee}
            handleClose={handleClose}
          />
        </div>
      </Drawer>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={`Zaposlenik je uspješno dodan!`}
      />
    </>
  )
}
