import { TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface IForgotPasswordFormProps {
  onSubmit: (values: FieldValues) => void
}

const ForgotPasswordFormSchema = z.object({
  username: z.string().min(1),
})

export const ForgotPasswordForm: FC<IForgotPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const methods = useForm({
    resolver: zodResolver(ForgotPasswordFormSchema),
    reValidateMode: 'onBlur',
  })
  const { handleSubmit } = methods

  return (
    <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <TextInput id='username' label={t('login.username')} />
      <Button tw='mt-2 w-full uppercase' type='submit'>
        {t('login.send_email')}
      </Button>
    </Form>
  )
}
