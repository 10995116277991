import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Dropdown } from 'src/components/UI/Dropdown/Dropdown'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'

type Option = {
  value: string | number
  title: string
  hidden?: boolean
  onClick?: (e: any) => void
}

interface ISelectProps {
  options: Option[]
  label: string
  id: string
  name: string
  placeholder?: string
  disabled?: boolean
  hasSearch?: boolean
  containerTwStyle?: TwStyle
}

export const Select: FC<ISelectProps> = ({
  options,
  id,
  label,
  name,
  placeholder,
  hasSearch,
  disabled,
  containerTwStyle,
}) => {
  const { register, setValue, watch } = useFormContext()
  const fieldValue = watch(name)
  const shownValue = (options.find(opt => opt.value == fieldValue)?.title ?? fieldValue) || placeholder || ''
  const isPlaceholder = shownValue === placeholder && !fieldValue

  const items = useMemo(
    () =>
      options
        .filter(o => !o.hidden)
        .map(o => ({
          key: o.value,
          title: o.title,
          decoration: [
            tw`cursor-pointer`,
            fieldValue == o.value ? tw`bg-dropdown-selected hover:bg-blue-200` : undefined,
          ],
          onClick: () => {
            if (o?.onClick) {
              o.onClick(o)
            }
            setValue(name, o.value)
          },
          render: <span tw='cursor-pointer'>{o.title}</span>,
        })),
    [fieldValue, name, options, setValue],
  )

  return (
    <div className={'min-w-[200px]'} css={containerTwStyle}>
      {label && <div tw='py-3 font-semibold text-secondary text-sm'>{label}</div>}
      <Dropdown hasSearch={hasSearch} trigger='click' items={items} disabled={disabled}>
        <input id={id} tw='hidden' {...register(name)} />
        <span tw='flex w-full overflow-hidden whitespace-nowrap' css={isPlaceholder ? [tw`text-text-gray`] : []}>
          {shownValue}
        </span>
      </Dropdown>
    </div>
  )
}
